import { DELIMETERS, ISubscribedAccessInfo, OwnerEmoji, SharedEmoji } from '../types/search';
import { ROLES } from '../types/subscribed-access';

export function mapSubscriptionInfoToString(
  items: ISubscribedAccessInfo[],
  mappingFunction: (item: ISubscribedAccessInfo) => string
): string {
  if (!items || !items.length) return DELIMETERS.NOT_AVAILABLE;

  const resultList: string[] = [];

  items.forEach((item) => {
    const mappedItem = mappingFunction(item);
    if (!mappedItem) {
      resultList.push(DELIMETERS.NOT_AVAILABLE);
      return;
    }

    if (item.isShared) {
      if (item.role === ROLES.OWNER) resultList.push(`${mappedItem} ${OwnerEmoji}`);
      if (item.role !== ROLES.OWNER) resultList.push(`${mappedItem} ${SharedEmoji}`);
    } else {
      resultList.push(mappedItem);
    }
  });
  return resultList.join(DELIMETERS.SEPERATOR);
}
