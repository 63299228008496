import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material';
import React from 'react';
import { common, grey } from '@mui/material/colors';

const { palette } = createTheme();
const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#D3140D',
    },
    secondary: {
      main: '#CACACA',
    },
    primaryBase: palette.augmentColor({
      color: {
        main: '#d3140d',
      },
    }),
    brandBlack: palette.augmentColor({
      color: {
        main: '#222222',
      },
    }),
    brandRed: palette.augmentColor({
      color: {
        main: '#D3140D',
      },
    }),
    brandGrey: palette.augmentColor({
      color: {
        main: '#E9E9ED',
      },
    }),
  },
  typography: {
    system: {
      fontFamily: [
        '-apple-system',
        'system-ui',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    brand: {
      fontFamily: ['Raleway', '"Raleway Fallback"', 'sans-serif'].join(','),
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'brandContained' &&
            ownerState.color === 'brandBlack' && {
              color: common.white,
              backgroundColor: baseTheme.palette.brandBlack.main,
              '&:hover': {
                backgroundColor: baseTheme.palette.brandBlack.light,
              },
            }),
          ...(ownerState.variant === 'brandContained' &&
            ownerState.color === 'brandGrey' && {
              color: common.black,
              backgroundColor: baseTheme.palette.brandGrey.main,

              '&:hover': {
                backgroundColor: theme.palette.brandGrey.dark,
              },
            }),
          ...(ownerState.variant === 'brandContained' &&
            ownerState.color === 'brandRed' && {
              color: common.white,
              backgroundColor: baseTheme.palette.brandRed.main,

              '&:hover': {
                backgroundColor: theme.palette.brandRed.light,
              },
            }),
          ...(ownerState.variant === 'brandOutlined' &&
            ownerState.color === 'brandBlack' && {
              color: baseTheme.palette.brandBlack.main,
              backgroundColor: common.white,

              '&:hover': {
                backgroundColor: '#efefef',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'brandRed' && {
              ...theme.typography.brand,

              fontWeight: '900',
              fontSize: '14px',
              lineHeight: '1em',

              color: common.white,
              backgroundColor: baseTheme.palette.brandRed.main,

              '&:hover': {
                backgroundColor: theme.palette.brandRed.light,
              },
            }),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          MHHeading: 'h4',
          MHSubHeading: 'h5',
          MHCaption: 'div',
          MHCaption2: 'div',
          MHParagraph: 'p',
        },
      },
    },
  },
});

export const theme = createTheme(baseTheme, {
  typography: {
    systemGrey: {
      ...baseTheme.typography.system,
      color: grey[400],
    },
    MHHeading: {
      ...baseTheme.typography.h6,
      fontWeight: 'bold',
      fontSize: '1.3rem',
      lineHeight: '1.3em',
    },
    MHSubHeading: {
      ...baseTheme.typography.h6,
      fontWeight: 'bold',
      fontSize: '1.1rem',
      lineHeight: '1.15em',
    },
    MHCaption: {
      ...baseTheme.typography.caption,
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    MHCaption2: {
      ...baseTheme.typography.caption,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    MHParagraph: {
      ...baseTheme.typography.body1,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'brandContained', size: 'small' },
          style: {
            ...baseTheme.typography.brand,
            fontWeight: '900',
            fontSize: '14px',
            lineHeight: '1em',

            height: 32,
            minWidth: 70,
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              boxShadow: 'none',

              color: grey[600],
              backgroundColor: '#e2e2e2',
            },
          },
        },
        {
          props: { variant: 'brandContained', size: 'medium' },
          style: {
            ...baseTheme.typography.brand,
            fontWeight: '900',
            fontSize: 'medium',
            lineHeight: '1em',

            height: 48,
            padding: '8px 24px',
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              boxShadow: 'none',

              color: grey[600],
              backgroundColor: '#e2e2e2',
            },
          },
        },
        {
          props: { variant: 'brandOutlined', size: 'small' },
          style: {
            ...baseTheme.typography.brand,
            fontWeight: '900',
            fontSize: '14px',
            lineHeight: '1em',

            borderWidth: '1px',
            borderStyle: 'solid',

            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              boxShadow: 'none',

              color: grey[600],
              backgroundColor: '#e2e2e2',
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // this is styles for the new variants
          '&.white_outlined': {
            '& .MuiInputBase-input': {
              backgroundColor: `white`,
              maxHeight: `40px`,
              fontSize: baseTheme.typography.pxToRem(14),
            },
            '& fieldset': {
              borderColor: '#9a9a9a',
            },

            '& .MuiInputBase-input.Mui-disabled': {
              '-webkit-text-fill-color': '#3a3a3a',
            },
          },
          '&.white_outlined.uneditable': {
            '& .MuiInputBase-input.Mui-disabled': {
              backgroundColor: `#EFEFEF`,
            },
          },
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    primaryBase: PaletteColor;
    brandBlack: PaletteColor;
    brandGrey: PaletteColor;
    brandRed: PaletteColor;
  }

  interface PaletteOptions {
    primaryBase?: PaletteColorOptions;
    brandBlack?: PaletteColorOptions;
    brandGrey?: PaletteColorOptions;
    brandRed?: PaletteColorOptions;
  }

  interface TypographyVariants {
    system: React.CSSProperties;
    brand: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    system?: React.CSSProperties;
    brand?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    brandBlack: true;
    brandGrey: true;
    brandRed: true;
  }

  interface ButtonPropsVariantOverrides {
    brandContained: true;
    brandOutlined: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    system: true;
    systemGrey: true;
    MHHeading: true;
    MHCaption: true;
    MHCaption2: true;
    MHSubHeading: true;
    MHParagraph: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    brandBlack: true;
  }
}

export default theme;
