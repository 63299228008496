import React, { CSSProperties, forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { TableCell as MuiTableCell, Typography } from '@mui/material';
import { HtmlTooltip } from '../Reusable/HtmlTooltip';

interface TableCellProps<T> {
  title: string;
  value: T;
  render?: (value: T, propertyKey: keyof T) => ReactNode;
  propertyKey: keyof T;
  style?: (value: unknown) => CSSProperties;
  width?: number;
  showTooltip?: boolean;
}

const TableCell = <T,>({
  title,
  value,
  render,
  propertyKey,
  style,
  width,
  showTooltip = true,
}: TableCellProps<T>) => {
  const renderedValue = useCallback(() => {
    if (render) {
      return render(value, propertyKey);
    } else if (typeof value[propertyKey] === 'string') {
      return value[propertyKey] as string;
    } else {
      return title || null;
    }
  }, [render, value, propertyKey, title]);

  const extraStyle: CSSProperties = useMemo(
    () => (style && value[propertyKey] !== undefined ? style(value[propertyKey]) : {}),
    [value, propertyKey, style]
  );

  const CellContent = useMemo(() => {
    const comp = forwardRef((props, ref) => (
      <MuiTableCell
        ref={ref}
        {...props}
        style={{
          maxWidth: '10em',
          maxHeight: '2em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: `${(width || 1) * 10}%`,
          paddingLeft: '0.5rem',
          paddingRight: 0,
          ...extraStyle,
        }}
        data-cy={'cell_' + title}>
        {renderedValue()}
      </MuiTableCell>
    ));
    comp.displayName = 'TableCell';
    return comp;
  }, [width, extraStyle, title, renderedValue]);

  if (title || value) {
    return (
      (showTooltip && (
        <HtmlTooltip
          placement="bottom-start"
          title={
            <>
              <Typography color="inherit">{title}</Typography>
              <em>{renderedValue()}</em>
            </>
          }>
          <CellContent />
        </HtmlTooltip>
      )) || <CellContent />
    );
  }
  return <CellContent />;
};

export default TableCell;
