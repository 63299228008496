import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import React, { CSSProperties } from 'react';
import { ITypeAhead } from '../../types/type-ahead';
import { Field, FieldInputProps } from 'formik';
import './CustomAutoComplete.css';

interface CustomAutoCompleteProps {
  name: string;
  label: string;
  options: ITypeAhead[];
  error?: boolean;
  helperText?: string;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  fieldValue: ITypeAhead | null;
  render?: (option: ITypeAhead) => React.ReactNode;
  style?: CSSProperties;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  name,
  label,
  options,
  error,
  helperText,
  setFieldValue,
  fieldValue,
  render,
  style,
}) => {
  const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: ITypeAhead) => {
    return (
      <li {...props} key={option.key}>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Box>{option.label}</Box>
          {render?.(option)}
        </Stack>
      </li>
    );
  };

  return (
    // Formik Field component to be used as a part of Formik Form component
    <Field name={name}>
      {({ field }: { field: FieldInputProps<unknown> }) => (
        <Autocomplete
          style={style}
          options={options}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          onChange={(event, value) => setFieldValue(name, value?.key)}
          value={fieldValue}
          renderOption={renderOptions}
          renderInput={(params) => (
            <TextField
              {...field}
              {...params}
              label={label}
              error={!!error}
              helperText={helperText}
              data-cy={'autocomplete_' + label}
            />
          )}
        />
      )}
    </Field>
  );
};

export default CustomAutoComplete;
