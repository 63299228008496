import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { configService } from './services/configService';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { AlertProvider } from './providers/AlertProvider';
import { AuthProvider } from './providers/AuthProvider';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n/config';

import './index.css';
import { apiService } from './services/apiService';

configService.getAppConfig().then(async (config) => {
  const msalInstance = new PublicClientApplication(msalConfig(config));
  const container = document.getElementById('root');
  const root = createRoot(container!);
  apiService.init(msalInstance, config);

  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <AuthProvider config={config}>
                <AlertProvider>
                  <App />
                </AlertProvider>
              </AuthProvider>
            </MsalProvider>
          </BrowserRouter>
        </ReduxProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
});
