import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';

export type DebouncedAutoCompleteProps = {
  label: string;
  value: string;
  dataCy: string;
  validationMessage?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  fetchSuggestions: (searchTerm: string) => Promise<string[]>;
  size?: 'small' | 'medium';
};

const DebouncedAutoComplete: FC<DebouncedAutoCompleteProps> = ({
  label,
  value,
  dataCy,
  onChange,
  onBlur,
  fetchSuggestions,
  validationMessage,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(value);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    async (search: string) => {
      try {
        setLoading(true);
        const newSuggestions = await fetchSuggestions(search);
        setSuggestions(newSuggestions);
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    },
    [fetchSuggestions]
  );

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      onSearch(debouncedSearchTerm);
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm, onSearch]);

  const error = useMemo(() => validationMessage !== undefined, [validationMessage]);

  const handleInputChange = useCallback((event: React.SyntheticEvent, value: string) => {
    setSearchTerm(value);
  }, []);

  return (
    <Autocomplete
      {...props}
      options={suggestions}
      onBlur={onBlur}
      freeSolo
      value={value}
      loading={loading}
      id={'autocomplete-' + dataCy}
      onChange={(event, value) => {
        onChange(value ?? '');
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          {...props}
          label={label}
          error={error}
          helperText={validationMessage}
          placeholder="Country"
          InputProps={{
            ...params.InputProps,
            size: props.size,
            style: { height: '40px', paddingTop: '0px', paddingBottom: '0px' }, // Adjust the height here
          }}
        />
      )}
      onInputChange={handleInputChange}
    />
  );
};

export default DebouncedAutoComplete;
