import { configureStore } from '@reduxjs/toolkit';
import appConfigReducer from './reducers/appConfigReducer';
import displayModeReducer from './reducers/displayModeReducer';
import brandClientIdsReducer from './reducers/clientsReducer';
import brandEntitlementsReducer from './reducers/brandEntitlementsReducer';
import brandGroupConfigReducer from './reducers/brandGroupConfigReducer';
import validationPropertiesReducer from './reducers/validationPropertiesReducer';

// Additional reducers can be added below
export const store = configureStore({
  reducer: {
    appConfig: appConfigReducer,
    displayMode: displayModeReducer,
    clients: brandClientIdsReducer,
    brandEntitlements: brandEntitlementsReducer,
    brandGroupConfig: brandGroupConfigReducer,
    validationProperties: validationPropertiesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
