import React, { FC, useEffect } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ACTIVITY_EVENT_TYPE, IActivity, USER_ACTIVITY_FIELDS } from '../types/activity';
import { GridTooltip } from './General/GridTooltip';
import { ErrorOverlayDataGrid } from './ErrorOverlayDataGrid';
import './UserActivityDataGrid.css';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { UsePagedCollectionHookResult } from '../hooks/usePagedCollection';

interface UserActivityDataGridProps {
  pagedCollectionHook: UsePagedCollectionHookResult<IActivity>;
}

const UserActivityDataGrid: FC<UserActivityDataGridProps> = ({ pagedCollectionHook }) => {
  const { collection, isLoading, hasErrors, pageNumber, pageSize, setPageNumber, setPageSize } =
    pagedCollectionHook;

  const rowCount = collection?.total;
  const [rowCountState, setRowCountState] = React.useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: USER_ACTIVITY_FIELDS.TIMESTAMP,
      headerName: 'Timestamp',
      minWidth: 160,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.timestamp
            ? new Date(params.row.timestamp).toLocaleString(undefined, { hour12: false })
            : ''
        }`,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.EVENT,
      headerName: 'Event',
      minWidth: 280,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        ACTIVITY_EVENT_TYPE[params.row.event as keyof typeof ACTIVITY_EVENT_TYPE] ??
        params.row.description,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.CLIENT_NAME,
      headerName: 'Client name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.HOST,
      headerName: 'Hostname',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.IP_ADDRESS,
      headerName: 'IP address',
      flex: 1,
      hide: true,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.USER_AGENT,
      headerName: 'User agent',
      flex: 1,
      hide: true,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_ACTIVITY_FIELDS.PERFORMED_BY,
      headerName: 'Performed by',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
  ];

  return (
    <DataGrid
      autoHeight
      error={hasErrors}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlayDataGrid,
      }}
      getRowId={() => Math.floor(Math.random() * 10000)}
      loading={isLoading}
      onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      page={pageNumber}
      pageSize={pageSize}
      paginationMode="server"
      rowCount={rowCountState}
      rowsPerPageOptions={[10, 25, 50, 100]}
      rows={collection?.data || []}
      sx={{
        backgroundColor: 'white',
      }}
    />
  );
};

export default UserActivityDataGrid;
