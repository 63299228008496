import React, { FC, useEffect, useState } from 'react';
import { Logo } from '@mediahuis/chameleon-react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { ERROR_CODES } from '../../types/error';
import { useTranslation } from 'react-i18next';

import './error.css';

interface ErrorFallBackProps {
  error: Error | AxiosError;
}

export const ErrorFallBack: FC<ErrorFallBackProps> = ({ error }) => {
  const { t } = useTranslation();
  const [errorCode, setErrorCode] = useState<string>(ERROR_CODES.REACT_ERROR);
  const [errorMessages, setErrorMessages] = useState<{ message: string; subText: string }>({
    message: t(`errorMessages.${ERROR_CODES.REACT_ERROR}.message`),
    subText: t(`errorMessages.${ERROR_CODES.REACT_ERROR}.subText`),
  });

  useEffect(() => {
    // Axios error
    if (error instanceof AxiosError) {
      if (error.response?.data.body) {
        const apiErrorCode = error.response?.data.body;
        setErrorCode(apiErrorCode);
        setErrorMessages({
          message: t(`errorMessages.${apiErrorCode}.message`),
          subText: t(`errorMessages.${apiErrorCode}.subText`),
        });
      } else {
        setErrorCode(ERROR_CODES.API_ERROR);
        setErrorMessages({
          message: t(`errorMessages.${ERROR_CODES.API_ERROR}.message`),
          subText: t(`errorMessages.${ERROR_CODES.API_ERROR}.subText`),
        });
      }
    }

    // ES5 error
    if (Object.values(ERROR_CODES).includes(error.message as ERROR_CODES)) {
      setErrorCode(error.message);
      setErrorMessages({
        message: t(`errorMessages.${error.message}.message`),
        subText: t(`errorMessages.${error.message}.subText`),
      });
    }
  }, [error]);

  return (
    errorMessages && (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box className="error__content">
          <Paper className="error__content__paper">
            <Stack alignItems="center" direction="column" m="auto">
              <Logo width="138px" alt="Logo Mediahuis" />
              <Typography
                className="error__content__paper__text--dark"
                fontFamily={'var(--font-family-system)'}
                fontWeight="bold"
                variant="h6"
                textAlign="center"
                component="div"
                mt={5}
                mb={1}>
                {errorMessages.message}
              </Typography>
              {errorCode === ERROR_CODES.REACT_ERROR && (
                <Typography
                  className="error__content__paper__text--light"
                  fontFamily={'var(--font-family-system)'}
                  textAlign="center"
                  component="div"
                  mb={3}>
                  Try again later
                </Typography>
              )}
              <Typography
                className="error__content__paper__text--light"
                fontFamily={'var(--font-family-system)'}
                textAlign="center"
                component="div">
                {errorMessages.subText}
              </Typography>
            </Stack>
          </Paper>
        </Box>
      </Box>
    )
  );
};
