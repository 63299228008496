import React from 'react';
import { useAuth } from '../providers/AuthProvider';
import { Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const SignInButton = () => {
  const { handleLogin, handleLogout, isAuthenticated } = useAuth();
  const handleClick = () => (isAuthenticated ? handleLogout() : handleLogin());
  const buttonText = isAuthenticated ? 'Sign Out' : 'Sign In';

  return (
    <Button
      startIcon={<PersonIcon />}
      variant="brandContained"
      color="brandBlack"
      onClick={async () => await handleClick()}
      data-cy={'btn_sign_in'}>
      {buttonText}
    </Button>
  );
};

export default SignInButton;
