import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Stack, Typography } from '@mui/material';
import { subscribedAccessService } from '../../services/subscribedAccessService';
import { HANDLE_RESULTS, ISearchTerms } from '../../types/search';
import { ISubscribedAccess } from '../../types/subscribed-access';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import MultiAccountSubscriptionSearchBar from '../../components/MultiAccount/MultiAccountSubscriptionSearchBar';
import MultiAccountSubscriptionDataGrid from '../../components/MultiAccount/MultiAccountSubscriptionDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setDisplayMode } from '../../redux/reducers/displayModeReducer';
import { useTypedSearchParams } from '../../hooks/useTypedSearchParams';

export const MultiAccountSearch: FC = () => {
  const { displayMode } = useSelector((state: RootState) => state.displayMode);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useTypedSearchParams<ISearchTerms>();
  const [searchResults, setSearchResults] = useState<ISubscribedAccess[] | null>(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation();

  const handleSearchChange = async function (searchParams: ISearchTerms) {
    if (Object.keys(searchParams).length === 0) return;

    setLoading(true);
    await subscribedAccessService
      .getMultiAccountSubscriptions(searchParams)
      .then((subscribedAccesses) => setSearchResults(subscribedAccesses))
      .catch(() =>
        alert.setErrorAlert(t('alertMessages.ownedMultiAccountSubscriptionSearch.onError'))
      );
    setLoading(false);
  };

  useEffect(() => {
    handleSearchChange(searchParams);
  }, [searchParams]);

  useEffect(() => {
    // Show table if multiple (or no) results.
    if (!searchResults) dispatch(setDisplayMode(HANDLE_RESULTS.DEFAULT));
    if (searchResults) dispatch(setDisplayMode(HANDLE_RESULTS.DISPLAY_TABLE));
  }, [searchResults]);

  return (
    <AuthenticatedTemplate>
      <Typography variant="MHHeading">Multi account subscription search</Typography>
      <Typography variant="MHCaption" mb={3}>
        Search for existing multi account subscriptions by user ID or subscription ID.
      </Typography>
      <Stack direction="column" flexWrap="wrap">
        <MultiAccountSubscriptionSearchBar setSearchTerms={setSearchParams} loading={loading} />
        {displayMode === HANDLE_RESULTS.DISPLAY_TABLE && (
          <>
            <Typography variant="MHHeading" mt={3} mb={1.5}>
              Results
            </Typography>
            <MultiAccountSubscriptionDataGrid
              error={false}
              loading={loading}
              subscribedAccesses={searchResults}
            />
          </>
        )}
      </Stack>
    </AuthenticatedTemplate>
  );
};
