import React, { FC, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ErrorOverlayDataGrid } from '../ErrorOverlayDataGrid';
import { IDENTITY_FIELDS } from '../../types/identity';
import { Button } from '@mui/material';
import { GridTooltip } from '../General/GridTooltip';
import { MembersHookResult } from '../../hooks/useMembers';

interface MultiAccountUserDataGridProps {
  membersHook: MembersHookResult;
  setToggleRemovePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiAccountUserDataGrid: FC<MultiAccountUserDataGridProps> = ({
  membersHook,
  setToggleRemovePopup,
}) => {
  const { members, loading, setSelectedMember } = membersHook;

  const identityFields = IDENTITY_FIELDS;
  const [resultsPageSize, setResultsPageSize] = useState<number>(10);
  const [resultsPageNumber, setResultsPageNumber] = useState<number>(0);

  const pageOptions = [10, 25, 50];
  const columns: GridColDef[] = [
    {
      field: identityFields.ID,
      headerName: 'User ID',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: identityFields.EMAIL,
      headerName: 'Email address',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'Edit',
      headerName: '',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () =>
          setSelectedMember(params.row.id, params.row.email).then(() => setToggleRemovePopup(true));
        return (
          <Button id={`remove-member-${params.row.id}`} onClick={onClick}>
            Remove
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={members}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlayDataGrid,
        }}
        rowsPerPageOptions={pageOptions}
        disableSelectionOnClick={true}
        onPageChange={(newPageNumber) => setResultsPageNumber(newPageNumber)}
        onPageSizeChange={(newPageSize) => setResultsPageSize(newPageSize)}
        pageSize={resultsPageSize}
        page={resultsPageNumber}
        getRowId={() => Math.floor(Math.random() * 1000)}
        loading={loading}
        autoHeight
        sx={{
          backgroundColor: 'white',
        }}
      />
    </>
  );
};

export default MultiAccountUserDataGrid;
