import { KeyboardKey } from '../types/keyboardKey';
import React from 'react';

export const onKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  key: KeyboardKey,
  callback: () => void
) => {
  if (event.key === key) {
    event.preventDefault();
    callback();
  }
};
