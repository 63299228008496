export enum ERROR_CODES {
  REACT_ERROR = 'ReactError',
  PAGE_NOT_FOUND = 'PageNotFound',
  BAD_REQUEST = 'BadRequestException',
  ADD_REGISTRATION_COMPLETION_FAILED = 'AddRegistrationCompletionException',
  API_ERROR = 'ApiErrorException',
  BRAND_GROUP_NOT_FOUND = 'BrandGroupNotFoundException',
  BRAND_NOT_FOUND = 'BrandNotFoundException',
  IDENTITY_NOT_FOUND = 'IdentityNotFoundException',
  MAIL_INFO_NOT_FOUND = 'MailInfoNotFoundException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  UNEXPECTED_ERROR = 'UnexpectedErrorException',
  CREATE_IDENTITY_EXCEPTION = 'CreateIdentityException',
}

export interface IApiError {
  code: string;
  message: string;
}

export interface IErrorResponse {
  body: ERROR_CODES;
  errors: IApiError[];
}
