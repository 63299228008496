import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BrandEntitlement } from '../../types/brands';

const initialState: BrandEntitlement[] = [];

const brandEntitlementsSlice = createSlice({
  name: 'brandEntitlements',
  initialState,
  reducers: {
    setBrandEntitlements: (state, action: PayloadAction<BrandEntitlement[]>) => {
      return action.payload;
    },
  },
});

export const selector = (state: RootState) => state.brandEntitlements;
export const { setBrandEntitlements } = brandEntitlementsSlice.actions;
export default brandEntitlementsSlice.reducer;
