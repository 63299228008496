import { LOCAL_STORAGE } from './providers/AuthProvider';
import { IAppConfig } from './types/app-config';

export const msalConfig = function (config: IAppConfig) {
  return {
    auth: {
      clientId: config.Oauth.clientId,
      tenantId: config.Oauth.authority,
      authority: `https://login.microsoftonline.com/${config.Oauth.authority}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: config.Oauth.redirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = function (config: IAppConfig) {
  return {
    scopes: config.Oauth.scopes,
    loginHint: localStorage.getItem(LOCAL_STORAGE.ACTIVE_ACCOUNT) ?? undefined,
  };
};
