import { CreateIdentityRequest, Identity, FullIdentity } from '../types/identity';
import { IPagedResult, ISearchResult, ISearchTerms } from '../types/search';
import { IActivity } from '../types/activity';
import { INewEmailRequest } from './mailService';
import { IUserData } from '../types/user-data';
import { apiService } from './apiService';
import { UserBlock } from '../types/user-block';
import { GENDER_CODE_MAP } from '../types/gender';
import { deepFilterObject } from '../util/objectUtil';

export type PartialIdentity = Partial<Identity> & Pick<Identity, 'id'>; // mandatory id

class IdentityService {
  public createIdentity(request: CreateIdentityRequest): Promise<PartialIdentity> {
    // POST only fields that have a value
    const data = deepFilterObject({ ...request }, (k, v) => !!v);

    return apiService.bffClient.post<PartialIdentity>('identities', data).then((r) => r.data);
  }

  public getIdentity(identityId: string): Promise<FullIdentity> {
    return apiService.bffClient.get<FullIdentity>(`identities/${identityId}`).then((r) => r.data);
  }

  public patchIdentity(identityId: string, identity: Partial<Identity>): Promise<Identity> {
    // TODO later when patch is implemented decently in IMS, and BFF forwards to IMS:

    // a) Map all empty strings to null:
    // const patch = deepMapObjectValues(identity, (_, value) => (value === '' ? null : value));

    // b) remove the following mapping to CIAM API fields:
    const genderCode = identity.demographicsPerson?.genderCode;

    const patch = {
      firstName: identity.namesPerson?.firstName || null,
      lastName: identity.namesPerson?.lastName || null,
      nickName: identity.namesPerson?.nickName || null,
      gender:
        genderCode && genderCode !== 'u'
          ? {
              type: GENDER_CODE_MAP[genderCode],
              custom: identity.demographicsPerson?.genderCustom || null,
            }
          : null,
      birthdate: identity.demographicsPerson?.dateOfBirth || null,
      phoneNumber: identity.contactpointsPhone?.phone || null,
    };

    return apiService.bffClient
      .patch<Identity>(`identities/${identityId}`, patch)
      .then((r) => r.data);
  }

  public updateIdentityEmail(identityId: string, data: INewEmailRequest): Promise<void> {
    return apiService.bffClient.put(`identities/${identityId}/email`, data);
  }

  public updateIdentityPassword(identityId: string, clientId: string): Promise<void> {
    return apiService.bffClient.post(`/identities/${identityId}/password-change-ticket`, {
      clientId,
    });
  }

  public sendNewVerificationMail(identityId: string, clientId: string): Promise<void> {
    return apiService.bffClient.post(`/identities/${identityId}/verify-email-request`, {
      clientId,
    });
  }

  public getIdentityActivityLogs(
    identityId: string,
    pageNumber = 0,
    pageSize = 10
  ): Promise<IPagedResult<IActivity[]>> {
    return apiService.bffClient
      .get(`/identities/${identityId}/logs`, { params: { pageNumber, pageSize } })
      .then((r) => r.data);
  }

  public getIdentities(
    searchTerms: ISearchTerms,
    pageNumber = 0,
    pageSize = 10
  ): Promise<IPagedResult<ISearchResult[]>> {
    const params = {
      ...searchTerms,
      pageSize,
      pageNumber,
    };

    return apiService.bffClient.get(`/identities/search`, { params }).then((r) => r.data);
  }

  public getMembers(
    identityId: string,
    subscriptionId: string,
    source: string
  ): Promise<Identity[]> {
    return apiService.bffClient
      .get(
        `identities/${identityId}/subscribed-accesses/${subscriptionId}/source/${source}/members`
      )
      .then((r) => r.data);
  }

  public getAllUserData(identityId: string): Promise<IUserData> {
    return apiService.bffClient.get(`identities/${identityId}/all-user-data`).then((r) => r.data);
  }

  public deleteIdentity(identityId: string): Promise<void> {
    return apiService.bffClient.delete(`identities/${identityId}`);
  }

  public getUserBlockList(identityId: string): Promise<UserBlock[]> {
    return apiService.bffClient
      .get<UserBlock[]>(`identities/${identityId}/user-blocks`)
      .then((r) => r.data);
  }

  public invalidateAccessTokensForIdentity(identityId: string): Promise<void> {
    return apiService.bffClient.post(`identities/${identityId}/invalidate-access-tokens`);
  }
}

export const identityService = new IdentityService();
