import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addressService,
  AddressServiceLanguage,
  AutoCompleteCitiesProps,
  AutoCompleteCitiesResponse,
  AutoCompleteStreetsProps,
  AutoCompleteStreetsResponse,
  GetCountriesProps,
  GetCountriesResponse,
  ResolveDutchAddressProps,
  ResolveDutchAddressResponse,
} from '../services/addressService';

type AddressService = {
  autoCompleteCities: (
    props: Omit<AutoCompleteCitiesProps, 'language'>
  ) => Promise<AutoCompleteCitiesResponse>;
  autoCompleteStreets: (
    props: Omit<AutoCompleteStreetsProps, 'language'>
  ) => Promise<AutoCompleteStreetsResponse>;
  autoCompleteCountries: (
    props: Omit<GetCountriesProps, 'language'>
  ) => Promise<GetCountriesResponse>;
  resolveDutchAddress: (props: ResolveDutchAddressProps) => Promise<ResolveDutchAddressResponse>;
};

export function useAddressService(): {
  addressServiceLanguage: AddressServiceLanguage;
  addressService: AddressService;
} {
  const { i18n } = useTranslation();

  const language = useMemo(() => {
    const browserLanguages = i18n.languages;
    const allowedLanguages = Object.values(AddressServiceLanguage);
    let language;

    /* eslint-disable no-labels */
    loop: for (const browserLanguage of browserLanguages) {
      for (const allowedLanguage of allowedLanguages) {
        if (browserLanguage.toLowerCase().includes(allowedLanguage.toLowerCase())) {
          language = allowedLanguage;
          break loop;
        }
      }
    }
    /* eslint-enable no-labels */
    return language ?? AddressServiceLanguage.NL;
  }, [i18n.languages]);

  const autoCompleteCities = useCallback(
    (props: Omit<AutoCompleteCitiesProps, 'language'>) => {
      return addressService.autoCompleteCities({
        ...props,
        language,
      });
    },
    [language]
  );

  const autoCompleteStreets = useCallback(
    (props: Omit<AutoCompleteStreetsProps, 'language'>) => {
      return addressService.autoCompleteStreets({
        ...props,
        language,
      });
    },
    [language]
  );

  const autoCompleteCountries = useCallback(
    (props: Omit<GetCountriesProps, 'language'>) => {
      return addressService.getCountries({
        ...props,
        language,
      });
    },
    [language]
  );

  const resolveDutchAddress = useCallback((props: ResolveDutchAddressProps) => {
    return addressService.resolveStreetFromPostcode({
      ...props,
    });
  }, []);

  return useMemo(
    () => ({
      addressServiceLanguage: language,
      addressService: {
        autoCompleteCities,
        autoCompleteStreets,
        autoCompleteCountries,
        resolveDutchAddress,
      },
    }),
    [autoCompleteCities, autoCompleteStreets, autoCompleteCountries, language, resolveDutchAddress]
  );
}
