import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray, Form, Formik, FormikErrors, FormikValues } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import * as Yup from 'yup';
import { subscribedAccessService } from '../../../services/subscribedAccessService';
import { ERROR_CODES } from '../../../types/error';
import {
  IEditManualSubscribedAccess,
  IEditSubscribedAccess,
  IEditSubscriptionBasedSubscribedAccess,
  IEntitlement,
  ISubscribedAccess,
  SUBSCRIBED_ACCESS_SOURCE,
  UpdateSubscribedAccess,
} from '../../../types/subscribed-access';

import { Add } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Identity } from '../../../types/identity';
import CustomDatePicker from '../../General/CustomDatePicker';
import { EntitlementError, EntitlementField } from './EntitlementField';
import './SubscribedAccessDialog.css';
import { getEarliestValidFrom, getLatestValidTo } from '../../../util/subscribedAccessUtils';
import useBrandGroupEntitlements from '../../../hooks/useBrandGroupEntitlements';
import { useAuth } from '../../../providers/AuthProvider';

export enum DIALOG_TYPE {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

type SubscribedAccessDialogProps = {
  open: boolean;
  type: DIALOG_TYPE;
  identityId: Identity['id'];
  onSave: () => void;
  onClose: () => void;
  data?: ISubscribedAccess;
};

export const SubscribedAccessDialog: FC<SubscribedAccessDialogProps> = ({
  open,
  type,
  identityId,
  onClose,
  onSave,
  data,
}) => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const { customerSupportMail } = useAuth();
  const { isKnownBrand, isKnownBrandCode } = useBrandGroupEntitlements();

  const initialValues: UpdateSubscribedAccess = useMemo(() => {
    if (data) {
      const earliestDate = getEarliestValidFrom(data);
      const latestDate = getLatestValidTo(data);
      return {
        ...data,
        defaultValidFrom: earliestDate ?? '',
        defaultValidTo: latestDate ?? '',
      };
    }
    return {
      status: 'active',
      defaultValidFrom: '',
      defaultValidTo: '',
      entitlements: [
        {
          brandCode: '',
          code: '',
          isActive: true,
          validFrom: '',
          validTo: '',
        },
      ],
    };
  }, [data]);

  const validationSchema = useMemo(() => {
    return Yup.object()
      .shape({
        status: Yup.string().required(),
        defaultValidFrom: Yup.date().required('A default valid from date is required'),
        defaultValidTo: Yup.date()
          .required('A default valid to date is required')
          .min(
            Yup.ref('defaultValidFrom'),
            t('subscribedAccess.validation.validToBeforeValidFrom') ?? 'Invalid date'
          ),
        entitlements: Yup.array()
          .of(
            Yup.object().shape({
              brandCode: Yup.string()
                .min(2)
                .required(t('subscribedAccess.validation.requiredBrand') ?? 'Invalid field'),
              code: Yup.string()
                .min(2)
                .required(t('subscribedAccess.validation.requiredBrandCode') ?? 'Invalid field'),
              validFrom: Yup.date(),
              validTo: Yup.date(),
            })
          )
          .min(1, 'At least one entitlement should be present')
          .required('Required'),
      })
      .test((values) => {
        const entitlements = values.entitlements ?? [];
        const validationErrors: Yup.ValidationError[] = [];
        entitlements.forEach(({ validFrom, validTo, brandCode, code }, index) => {
          const formikId = `entitlements.${index}`;
          const hasValidFromDate = !!validFrom;
          const hasValidToDate = !!validTo;
          const hasValidBrand = brandCode && isKnownBrand(brandCode);
          const hasValidBrandCode = code && isKnownBrandCode(brandCode, code);
          if (hasValidFromDate && !hasValidToDate) {
            validationErrors.push(
              new Yup.ValidationError(
                'A valid to date is required',
                undefined,
                `${formikId}.validTo`
              )
            );
          }
          if (hasValidToDate && !hasValidFromDate) {
            validationErrors.push(
              new Yup.ValidationError(
                'A valid from date is required',
                undefined,
                `${formikId}.validFrom`
              )
            );
          }
          if (dayjs(validTo).isBefore(dayjs(validFrom))) {
            validationErrors.push(
              new Yup.ValidationError(
                'A valid from date must be after the valid to date',
                undefined,
                `${formikId}.validTo`
              )
            );
          }
          if (!hasValidBrand) {
            validationErrors.push(
              new Yup.ValidationError(
                'A valid brand needs to be selected',
                undefined,
                `${formikId}.brandCode`
              )
            );
          }
          if (!hasValidBrandCode) {
            validationErrors.push(
              new Yup.ValidationError(
                'A valid code needs to be selected',
                undefined,
                `${formikId}.code`
              )
            );
          }
        });
        return new Yup.ValidationError(validationErrors);
      });
  }, [isKnownBrand, isKnownBrandCode, t]);

  const handleUpdate = useCallback(
    async (values: UpdateSubscribedAccess) => {
      const updateSubscribedAccessData: IEditSubscribedAccess = {
        entitlements: values.entitlements.map((entitlement) => {
          if (!entitlement.validFrom) {
            entitlement.validFrom = values.defaultValidFrom;
          }
          if (!entitlement.validTo) {
            entitlement.validTo = values.defaultValidTo;
          }
          return entitlement;
        }),
        status: values.status,
        importedBy: data
          ? data?.source === SUBSCRIBED_ACCESS_SOURCE.FROM_CUSSUP_EMPLOYEE
            ? customerSupportMail
            : data.importedBy
          : customerSupportMail,
        modificationTimeStamp: new Date().toISOString(),
      };

      try {
        const mergedSubscribedAccessData = { ...data, ...updateSubscribedAccessData } as
          | IEditManualSubscribedAccess
          | IEditSubscriptionBasedSubscribedAccess;

        await subscribedAccessService.putSubscribedAccess(identityId, mergedSubscribedAccessData);
      } catch (e) {
        console.error('Error while adding/editing subscribed access: ', e);
        handleError(Error(ERROR_CODES.REACT_ERROR));
      } finally {
        onSave();
      }
    },
    [customerSupportMail, data, handleError, identityId, onSave]
  );

  const getEntitlementError = useCallback(
    (errors: FormikErrors<FormikValues>, index: number): EntitlementError | undefined => {
      if (
        errors.entitlements &&
        Array.isArray(errors.entitlements) &&
        errors.entitlements?.length >= index - 1
      ) {
        return errors?.entitlements[index] as unknown as EntitlementError;
      }
    },
    []
  );

  const headerTitle = useMemo(() => {
    switch (type) {
      case DIALOG_TYPE.ADD:
        return t('subscribedAccess.addSubscribedAccess');
      case DIALOG_TYPE.EDIT:
        return t('subscribedAccess.editSubscribedAccess');
    }
  }, [t, type]);

  const saveLabel = useMemo(() => {
    switch (type) {
      case DIALOG_TYPE.ADD:
        return t('actions.add');
      case DIALOG_TYPE.EDIT:
        return t('actions.submit');
    }
  }, [t, type]);

  const onChangeDefaultDate = useCallback(
    (
      dateField: 'validFrom' | 'validTo',
      date: Date | undefined,
      currentValues: UpdateSubscribedAccess,
      setValues: (values: UpdateSubscribedAccess, shouldValidate: boolean) => void
    ) => {
      const defaultField = dateField === 'validFrom' ? 'defaultValidFrom' : 'defaultValidTo';
      const currentDefaultDate = currentValues[defaultField];

      const newDefaultDate = date?.toISOString() ?? '';

      const updatedEntitlements = currentValues.entitlements.map((entitlement) => {
        const currentDate = entitlement[dateField];
        const shouldChangeDate = !currentDate || currentDate === currentDefaultDate;
        return { ...entitlement, [dateField]: shouldChangeDate ? newDefaultDate : currentDate };
      });

      setValues(
        { ...currentValues, [defaultField]: newDefaultDate, entitlements: updatedEntitlements },
        true
      );
    },
    []
  );

  return (
    <Dialog open={open} maxWidth={'lg'}>
      <DialogContent>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleUpdate(values)}>
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setValues,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Typography variant="MHHeading" mb={2}>
                {headerTitle}
              </Typography>
              <Grid container mb={5} gap={1}>
                <Grid item>
                  <CustomDatePicker
                    label={'Default valid from'}
                    time={{ hour: 0, minute: 0, second: 0 }}
                    value={values.defaultValidFrom}
                    error={errors.defaultValidFrom}
                    onChange={(date) => onChangeDefaultDate('validFrom', date, values, setValues)}
                  />
                </Grid>
                <Grid item>
                  <CustomDatePicker
                    label={'Default valid to'}
                    time={{ hour: 23, minute: 59, second: 59 }}
                    value={values.defaultValidTo}
                    error={errors.defaultValidTo}
                    onChange={(date) => onChangeDefaultDate('validTo', date, values, setValues)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Status"
                    name="status"
                    onBlur={handleBlur}
                    value={values.status}
                    onChange={handleChange}
                    error={!!errors.status}
                    helperText={errors.status}
                    sx={{ minWidth: '230px' }}>
                    <MenuItem value="active">{t('general.active')}</MenuItem>
                    <MenuItem value="inactive">{t('general.inactive')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Typography variant="MHSubHeading" mb={2}>
                {t('subscribedAccess.entitlements')}
              </Typography>
              <Grid container direction="row" mb={5} rowGap={1}>
                <FieldArray
                  name="entitlements"
                  render={(arrayHelper) => (
                    <>
                      {values.entitlements?.map((element, index) => (
                        <EntitlementField
                          key={index}
                          formikId={`entitlements.${index}`}
                          error={getEntitlementError(errors, index)}
                          values={element}
                          setFieldValue={setFieldValue}
                          onRemove={
                            (values.entitlements?.length ?? 0) > 1
                              ? () => arrayHelper.remove(index)
                              : undefined
                          }
                        />
                      ))}
                      <Grid item xs={12} flexDirection="row" ml={1} mt={1}>
                        <Button
                          variant="text"
                          type="button"
                          color="info"
                          startIcon={<Add />}
                          onBlur={handleBlur}
                          onClick={() => {
                            const ent: IEntitlement = {
                              brandCode: '',
                              code: '',
                              isActive: true,
                              validFrom: values.defaultValidFrom,
                              validTo: values.defaultValidTo,
                            };
                            arrayHelper.push(ent);
                          }}>
                          {t('subscribedAccess.addEntitlement')}
                        </Button>
                        {!Array.isArray(errors?.entitlements) && (
                          <InputLabel sx={{ color: '#d32f2f', padding: '0.5rem 0 0.5rem 0' }}>
                            {errors?.entitlements}
                          </InputLabel>
                        )}
                      </Grid>
                    </>
                  )}
                />
              </Grid>
              <Grid container direction="row" mb={5} rowGap={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Imported by"
                    id="importedBy"
                    value={customerSupportMail}
                    onBlur={handleBlur}
                    inputProps={{ readOnly: true }}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
              <Grid container direction="row" justifyContent="end">
                <Grid item>
                  <Button
                    variant="brandContained"
                    color="brandGrey"
                    onClick={onClose}
                    disabled={isSubmitting}
                    sx={{ mr: 1 }}>
                    {t('actions.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="brandContained"
                    color="brandBlack"
                    startIcon={isSubmitting ? <CircularProgress size="1em" /> : undefined}
                    disabled={isSubmitting || !touched || !!Object.keys(errors).length}>
                    {saveLabel}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
