import { IEntitlement } from '../../../types/subscribed-access';
import { HeadCell } from '../../../types/table';

export const entitlementHeadCells: HeadCell<IEntitlement>[] = [
  {
    id: 'brandCode',
    label: 'Brand code',
    key: 'brandCode',
  },
  {
    id: 'code',
    label: 'Code',
    key: 'code',
  },
  {
    id: 'isActive',
    label: 'Active',
    key: 'isActive',
  },
  {
    id: 'validFrom',
    label: 'Valid From',
    key: 'validFrom',
  },
  {
    id: 'validTo',
    label: 'Valid To',
    key: 'validTo',
  },
];

export default entitlementHeadCells;
