import { ErrorFallBack } from '../views/Error';
import { ERROR_CODES } from '../types/error';
import React from 'react';
import { CUSSUP_ROLES } from '../types/auth';

interface ProtectedArgs {
  requiredRoles: CUSSUP_ROLES[];
  currentRoles: CUSSUP_ROLES[];
  children: JSX.Element;
}

function RoleAuthorizeRouteGuard({ requiredRoles, currentRoles, children }: ProtectedArgs) {
  if (requiredRoles.length > 0 && !requiredRoles.find((rr) => currentRoles?.includes(rr))) {
    return <ErrorFallBack error={Error(ERROR_CODES.NOT_AUTHORIZED)} />;
  }
  return children;
}
export default RoleAuthorizeRouteGuard;
