import axios from 'axios';
import { IAppConfig } from '../types/app-config';

class AppConfigService {
  public async getAppConfig(): Promise<IAppConfig> {
    const result = await axios.get('config.json');
    return {
      addressService: result.data.apiAddressService,
      bffUrl: result.data.bffUrl,
      Oauth: {
        authority: result.data.Oauth.authority,
        clientId: result.data.Oauth.clientId,
        redirectUri: result.data.Oauth.redirectUri,
        scopes: result.data.Oauth.scopes.split(';'),
      },
    };
  }
}

export const configService = new AppConfigService();
