import React, { FC } from 'react';
import { Box } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface TextFieldErrorProps {
  error: string | undefined;
  absolute?: boolean;
  customError?: string;
}

export const TextFieldError: FC<TextFieldErrorProps> = ({
  error,
  absolute = false,
  customError = error,
}) => {
  return error ? (
    <Box display="flex" alignItems="center" position={absolute ? 'absolute' : 'static'}>
      <ErrorOutlineOutlinedIcon fontSize="small" fontWeight="small" sx={{ mr: 0.5 }} />
      {customError}
    </Box>
  ) : null;
};
