import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { HANDLE_RESULTS, ISearchTerms } from '../types/search';
import { useDispatch } from 'react-redux';
import { setDisplayMode } from '../redux/reducers/displayModeReducer';
import { onKeyDown } from '../util/onKeyDown';
import { KeyboardKey } from '../types/keyboardKey';
import { FormikTextField } from './General/FormikTextField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { filterObject } from '../util/objectUtil';

import './UserSearchBar.css';

const UserSearchBar: FC<{
  searchTerms: ISearchTerms;
  setSearchTerms: React.Dispatch<React.SetStateAction<ISearchTerms>>;
  loading: boolean;
}> = ({ searchTerms, setSearchTerms, loading }) => {
  const dispatch = useDispatch();

  const ValidationSchema = Yup.object({
    auth0Id: Yup.string(),
    email: Yup.string().min(3, 'Please provide at least 3 characters'),
    firstName: Yup.string().min(3, 'Please provide at least 3 characters'),
    lastName: Yup.string().min(3, 'Please provide at least 3 characters'),
    subscriptionId: Yup.string(),
  });

  type FormValues = Yup.InferType<typeof ValidationSchema>;

  const initialValues: FormValues = {
    auth0Id: searchTerms.auth0Id || '',
    email: searchTerms.email || '',
    firstName: searchTerms.firstName || '',
    lastName: searchTerms.lastName || '',
    subscriptionId: searchTerms.subscriptionId || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formik) => {
        setSearchTerms(filterObject(values, (_, value) => value !== '')); // remove empty strings
        formik.setStatus({ justSubmitted: true });
      }}
      onReset={(values, formik) => {
        dispatch(setDisplayMode(HANDLE_RESULTS.DEFAULT));
        formik.setStatus({ justSubmitted: false });
        setSearchTerms({});
      }}
      validationSchema={ValidationSchema}>
      {(formik) => (
        <Form noValidate>
          <Stack
            alignItems="flex-end"
            direction="row"
            my={3}
            spacing={1}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              formik.setStatus({ justSubmitted: false });

              if (!formik.isValid || !formik.dirty)
                onKeyDown(e, KeyboardKey.ENTER, formik.submitForm);
            }}>
            <FormikTextField
              name="auth0Id"
              formikId="userSearch"
              label="User ID"
              placeholder="auth0|..."
              optionalLabel="Wildcards (*) are allowed"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              preventWhitespace
            />
            <FormikTextField
              name="email"
              formikId="emailSearch"
              label="Email"
              optionalLabel="Provide email prefix. Wildcards (*) are allowed"
              placeholder="john.doe@example.com"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              preventWhitespace
            />
            <FormikTextField
              name="firstName"
              formikId="firstNameSearch"
              label="First name"
              optionalLabel="Provide first name prefix. Wildcards (*) are not allowed"
              placeholder="John"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikTextField
              name="lastName"
              formikId="lastNameSearch"
              label="Last name"
              optionalLabel="Provide last name prefix. Wildcards (*) are not allowed"
              placeholder="Doe"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikTextField
              name="subscriptionId"
              formikId="subscriptionSearch"
              label="Subscription ID"
              placeholder="Subscription ID"
              optionalLabel="Only exact matches will be returned. Wildcards (*) are not allowed"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              preventWhitespace
            />
            <LoadingButton
              id="findUser"
              disabled={!formik.isValid || formik.status?.justSubmitted === true}
              loading={loading}
              type="submit"
              variant="brandContained"
              color="brandBlack"
              sx={{ height: '56px' }}
              data-cy={'btn_find_user'}>
              Find user
            </LoadingButton>
            <Button
              id="resetUser"
              onClick={() => {
                // This would not work by marking the button as type=reset; because simple formik reset reverts to initial values,
                // which could be filled in with search params from the URL. We want the fields all cleared.
                // So, we call reset explicitly, and AFTER, we set all fields to empty.
                formik.resetForm();
                Object.keys(formik.values).forEach((key) => formik.setFieldValue(key, ''));
              }}
              variant="brandContained"
              color="brandBlack"
              sx={{ height: '56px' }}
              data-cy={'btn_reset_user'}>
              Reset
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default UserSearchBar;
