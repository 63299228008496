import { useMemo } from 'react';

interface PaginationHookProps<T> {
  collection: T[];
  page: number;
  rowsPerPage: number;
}

export const usePaginationHook = <T,>({
  collection,
  page,
  rowsPerPage,
}: PaginationHookProps<T>): T[] => {
  return useMemo(
    () => collection.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    [collection, page, rowsPerPage]
  );
};
