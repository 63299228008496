import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem as BaseListItem,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Field, FieldInputProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MailService } from '../../services/mailService';
import { useErrorHandler } from 'react-error-boundary';
import CustomAutoComplete from '../General/CustomAutoComplete';
import { ITypeAhead } from '../../types/type-ahead';
import { useAlert } from '../../providers/AlertProvider';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { IdentityHookResult } from '../../hooks/useIdentity';
import { USER_DETAIL_POPUP_TYPE } from '../../types/user-detail-popup-type';
import LoadingButton from '@mui/lab/LoadingButton';

interface FormValues {
  clientId: string;
  email: string;
}

interface UserDetailPopupProps {
  showPopup: boolean;
  popupClosed: React.Dispatch<React.SetStateAction<boolean>>;
  popupType: USER_DETAIL_POPUP_TYPE | undefined;
  identityHook: IdentityHookResult;
}

const ListItem = styled(BaseListItem)({
  display: 'list-item',
});

export const UserDetailPopup: FC<UserDetailPopupProps> = ({
  showPopup,
  popupClosed,
  popupType,
  identityHook,
}) => {
  const { identity, deleteIdentity, latestBrandClientForIdentity } = identityHook; // TODO: handle identity that can be null, popup rework (https://jira.mediahuis.nl/browse/GC-2957)

  const initialValues: FormValues = {
    clientId: latestBrandClientForIdentity ? latestBrandClientForIdentity.clientId : '',
    email: '',
  };

  const clients = useSelector((state: RootState) => state.clients);
  const brandGroups = useSelector((state: RootState) => state.brandGroupConfig);
  const validationProperties = useSelector((state: RootState) => state.validationProperties);

  const [doShowPopup, setDoShowPopup] = useState<boolean>(false);
  const [showNewBrandPopUp, setShowNewBrandPopup] = useState<boolean>(false);
  const [deleteUserDataLoading, setDeleteUserDataLoading] = useState(false);
  const formValuesReference = useRef<FormValues>(initialValues);
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const alert = useAlert();

  const mailService = new MailService(clients, brandGroups);

  const ValidationSchema = Yup.object().shape({
    clientId: Yup.string().required(),
    email: Yup.string()
      .email('Please provide a valid email address')
      .when([], {
        is: () => popupType === USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL,
        then: Yup.string()
          .email()
          .required('A new email address is required')
          .max(
            validationProperties.maxEmailLength,
            `Email can have a maximum of ${validationProperties.maxEmailLength} characters`
          ),
        otherwise: Yup.string().email().notRequired(),
      }),
  });

  const handleCloseNewBrandPopup = () => {
    setShowNewBrandPopup(false);
  };

  const handleClose = () => {
    setDoShowPopup(false);
    popupClosed(false);
    identityHook.getIdentity();
  };

  const hasLoggedIn = (brandCode: string) => {
    if (identity) {
      let hasLoggedIn = false;
      identity.logins?.forEach((login) => {
        if (brandCode === login.brandId) {
          hasLoggedIn = true;
        }
      });
      return hasLoggedIn;
    }
  };

  const hasRegisteredAt = (brandCode: string) => {
    return identity?.registeredAt === brandCode;
  };

  const isNewBrand = (brandCode: string) => {
    return !(hasRegisteredAt(brandCode) || hasLoggedIn(brandCode));
  };

  const sendMailNewBrandPopup = () => {
    sendEmailRequest(formValuesReference.current);
    setShowNewBrandPopup(false);
  };

  const sendEmailRequest = (values: FormValues): void => {
    if (identity)
      mailService
        .handleSendEmailRequest(values, popupType, identity)
        .then(() => handleClose())
        .catch((e) =>
          alert.setErrorAlert(
            `${t('alertMessages.sendEmail.onError')}: ${e.response.data.errors[0].message}`
          )
        );
  };

  const handleSubmit = (values: FormValues) => {
    formValuesReference.current = values;
    const brandClient = clients.find((brand) => brand.clientId === values.clientId);
    if (!brandClient) return;

    if (isNewBrand(brandClient.brandCode)) {
      setShowNewBrandPopup(true);
    } else {
      sendEmailRequest(values);
    }
  };

  const handleUserDelete = async () => {
    if (identity?.id && !deleteUserDataLoading) {
      setDeleteUserDataLoading(true);
      deleteIdentity().then(() => {
        alert.setSuccessAlert(t('alertMessages.userDataDelete.onSuccess'));
        navigate(`../${ROUTES.SEARCH}`, { replace: true });
      }, handleError);
      setDeleteUserDataLoading(false);
    }
  };

  const getClientOptionList = () => {
    return clients?.map((element) => {
      return {
        label: element.clientName,
        key: element.clientId,
      } as ITypeAhead;
    });
  };

  const getAppTypeByClientId = (clientId: string) => {
    const appType = clients?.find((element) => element.clientId === clientId)?.appType;
    switch (appType) {
      case 'spa':
      case 'regularweb':
        return 'Website';
      case 'native':
        return 'Mobile';
      default:
        return 'Other';
    }
  };

  const getAutoCompleteValue = (clientId: string) => {
    return clients?.find((element) => element.clientId === clientId)?.clientName || clientId;
  };

  useEffect(() => {
    setDoShowPopup(showPopup);
  }, [showPopup]);

  return (
    <>
      {doShowPopup && (
        <Dialog open={true}>
          <DialogContent>
            <>
              <Stack
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                direction="row"
                spacing={2}>
                <Typography variant="MHHeading">
                  {popupType === USER_DETAIL_POPUP_TYPE.CHANGE_PASSWORD && 'Change user password'}
                  {popupType === USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL && 'Change user email'}
                  {popupType === USER_DETAIL_POPUP_TYPE.VERIFY_EMAIL_REQUEST &&
                    'Send new verification mail'}
                  {popupType === USER_DETAIL_POPUP_TYPE.DELETE_USER_DATA && 'Delete user'}
                </Typography>
                <Button variant="brandContained" color="brandGrey" onClick={handleClose}>
                  Close
                </Button>
              </Stack>
              {popupType !== USER_DETAIL_POPUP_TYPE.DELETE_USER_DATA && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={ValidationSchema}
                  onSubmit={(values) => handleSubmit(values)}>
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Stack spacing={2}>
                        <CustomAutoComplete
                          label="Brand"
                          name={'clientId'}
                          error={!!(errors.clientId && touched.clientId)}
                          helperText={
                            'Latest brand suggested. Select a specific brand to affect the styling of the email to be sent.'
                          }
                          setFieldValue={setFieldValue}
                          fieldValue={
                            values.clientId
                              ? {
                                  label: getAutoCompleteValue(values.clientId),
                                  key: values.clientId,
                                }
                              : null
                          }
                          options={getClientOptionList()}
                          render={(option: ITypeAhead) => (
                            <Box className="custom-autocomplete__dropdown-item__right">
                              {getAppTypeByClientId(option.key)}
                            </Box>
                          )}
                        />
                        {popupType === USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL && (
                          <Field name="email">
                            {({ field }: { field: FieldInputProps<unknown> }) => (
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                type="email"
                                error={!!(errors.email && touched.email)}
                                helperText={
                                  errors.email && touched.email
                                    ? errors.email
                                    : "Provide the user's new email address"
                                }
                                {...field}
                              />
                            )}
                          </Field>
                        )}
                        <Box>
                          <Button variant="brandContained" color="brandBlack" type="submit">
                            Send
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              )}
              {popupType === USER_DETAIL_POPUP_TYPE.DELETE_USER_DATA && (
                <>
                  <Stack direction="column" my={2}>
                    <Typography variant="MHParagraph">
                      Are you sure you want to delete this identity?
                      <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', mt: 1 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                          Auth0 identity information will be deleted
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                          Activity logs related to this identity will be deleted
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                          The customer will no longer be able to login on any connected service
                        </ListItem>
                        <ListItem>
                          The customer will lose access to all connected subscriptions
                        </ListItem>
                      </List>
                    </Typography>
                    <Alert severity="warning">This action is final and cannot be undone</Alert>
                  </Stack>
                  <LoadingButton
                    variant="brandContained"
                    color="brandBlack"
                    onClick={handleUserDelete}
                    loading={deleteUserDataLoading}
                    data-cy={'btn_user_delete_confirm'}>
                    Confirm
                  </LoadingButton>
                </>
              )}
            </>
          </DialogContent>
        </Dialog>
      )}

      <Dialog id="innerDialog" open={showNewBrandPopUp}>
        <DialogContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="MHHeading" mr={2}>
              You have selected a new brand for this user.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="MHCaption">
              Are you sure you want to send a mail from this brand?
            </Typography>
          </Box>
          <Stack justifyContent="space-between" direction={'row'}>
            <Button
              variant="brandContained"
              color="brandBlack"
              type="submit"
              id="sendNewBrand"
              onClick={sendMailNewBrandPopup}>
              Send
            </Button>
            <Button
              variant="brandContained"
              color="brandBlack"
              type="submit"
              id="cancelNewBrand"
              onClick={handleCloseNewBrandPopup}>
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
