import React, { FC } from 'react';
import { IEntitlement, IFlattenedSubscribedAccess } from '../../../types/subscribed-access';
import { isHighlightedEntitlement } from '../../../util/subscribedAccessUtils';
import CustomTable from '../../General/Table/CustomTable';
import { isEditableSource } from '../../../util/utils';
import { descendingObjectComparator } from '../../../util/sortingUtils';
import { HeadCell } from '../../../types/table';
import { TooltipCellConfig } from '../SubscribedAccessTable/SubscribedAccessRowCellConfig';

interface EntitlementsTableProps {
  data: IFlattenedSubscribedAccess;
  handleEdit?: (row: IFlattenedSubscribedAccess, index: number) => void;
  headCells: HeadCell<any>[];
  rowConfig: TooltipCellConfig<any>[];
}

const EntitlementsTable: FC<EntitlementsTableProps> = ({
  data,
  handleEdit,
  headCells,
  rowConfig,
}) => {
  const customHandleEdit = (row: IEntitlement, index: number) => {
    return handleEdit ? handleEdit(data, index) : undefined;
  };

  return (
    <>
      <CustomTable
        collection={data.entitlements}
        handleEditClick={isEditableSource(data.source) && handleEdit ? customHandleEdit : undefined}
        headerCells={headCells}
        rowCellConfig={rowConfig}
        isHighlighted={isHighlightedEntitlement}
        isPaginated={false}
        isSortable={true}
        comparator={descendingObjectComparator}
      />
    </>
  );
};

export default EntitlementsTable;
