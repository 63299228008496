import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { identityService } from '../../services/identityService';
import { UserDetailGrid } from '../../components/UserDetailGrid/UserDetailGrid';
import { subscribedAccessService } from '../../services/subscribedAccessService';
import {
  IFlattenedSubscribedAccess,
  ISubscribedAccess,
  SUBSCRIBED_ACCESS_TAB_VALUES,
} from '../../types/subscribed-access';
import TabPanel from '../../components/General/TabPanel';
import {
  DIALOG_TYPE,
  SubscribedAccessDialog,
} from '../../components/SubscribedAccess/SubscribedAccessDialog/SubscribedAccessDialog';
import { UserDetailPopup } from '../../components/UserDetailsPopup/UserDetailPopup';
import { Spinner } from '../../components/General/Spinner';
import UserActivityDataGrid from '../../components/UserActivityDataGrid';
import { CUSSUP_ROLES } from '../../types/auth';
import UserConsentDataGrid from '../../components/UserConsentDataGrid';
import { useAlert } from '../../providers/AlertProvider';
import RoleAuthorizeShowGuard from '../../Guards/RoleAuthorizeShowGuard';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { IActivity } from '../../types/activity';
import usePagedCollection, { UsePagedCollectionHookResult } from '../../hooks/usePagedCollection';
import useCollection, { UseCollectionHookResult } from '../../hooks/useCollection';
import { useNavigate, useParams } from 'react-router-dom';
import UseIdentity from '../../hooks/useIdentity';
import { USER_DETAIL_POPUP_TYPE } from '../../types/user-detail-popup-type';
import SubscribedAccessTableContainer from '../../components/SubscribedAccess/SubscribedAccessTable/SubscribedAccessesTableContainer';
import SubscribedAccessHistoryTableContainer from '../../components/SubscribedAccess/SubscribedAccessTable/SubscribedAccessHistoryTableContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '../../providers/AuthProvider';

export const UserDetail: FC = () => {
  const { auth0Id } = useParams();
  const identityIdParam = useMemo(() => decodeURI(auth0Id as string), [auth0Id]);
  const identityHook = UseIdentity(identityIdParam);
  const {
    identity,
    identityLoading,
    getIdentity,
    getLatestBrandClientForIdentity,
    invalidateAccessTokens,
  } = identityHook;
  const { roles } = useAuth();

  const [allUserDataLoading, setAllUserDataLoading] = useState(false);
  const [invalidateSessionsLoading, setInvalidateSessionsLoading] = useState(false);
  const [userDetailPopupType, setUserDetailPopupType] = useState<
    USER_DETAIL_POPUP_TYPE | undefined
  >(undefined);
  const [showEditUserDetailPopup, setShowUserDetailPopup] = useState<boolean>(false);
  const [subscribedAccessDialogType, setSubscribedAccessDialogtype] = useState<DIALOG_TYPE>(
    DIALOG_TYPE.ADD
  );
  const [subscribedAccessData, setSubscribedAccessData] = useState<ISubscribedAccess>();
  const [showSubscribedAccessDialog, setShowSubscribedAccessDialog] = useState<boolean>(false);

  const [tabValue, setTabValue] = useState<string>(SUBSCRIBED_ACCESS_TAB_VALUES.CURRENT);
  const [editModeEnabled, setEditMode] = useState(false);
  const [isSavingEditedUserData, setIsSavingEditedUserData] = useState(false);
  const alert = useAlert();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const subscribedAccessesCollectionHook: UseCollectionHookResult<ISubscribedAccess> =
    useCollection();
  const subscribedAccessesHistoryCollectionHook: UsePagedCollectionHookResult<ISubscribedAccess> =
    usePagedCollection();
  const activityLogsCollectionHook: UsePagedCollectionHookResult<IActivity> = usePagedCollection();

  useEffect(() => {
    getIdentity();
  }, []);

  useEffect(() => {
    if (!identity) return;
    subscribedAccessesCollectionHook.setCollectionLoader(
      () => () => subscribedAccessService.getSubscribedAccesses(identity.id)
    );
    subscribedAccessesHistoryCollectionHook.setCollectionLoader(
      () => (pageSize: number, pageNumber: number) =>
        subscribedAccessService.getSubscribedAccessHistory(identity.id, pageSize, pageNumber)
    );
    activityLogsCollectionHook.setCollectionLoader(
      () => (pageSize: number, pageNumber: number) =>
        identityService.getIdentityActivityLogs(identity.id, pageNumber, pageSize)
    );
  }, [identity]);

  useEffect(() => {
    // Dependency on collection loader for activity logs, then get latest known brand.
    if (activityLogsCollectionHook.collection)
      getLatestBrandClientForIdentity(activityLogsCollectionHook.collection);
  }, [activityLogsCollectionHook.collection]);

  const viewPreviousSearchResults = () => {
    navigate(-1);
  };

  const showUserDetailPopup = (popupType: USER_DETAIL_POPUP_TYPE) => {
    setUserDetailPopupType(popupType);
    setShowUserDetailPopup(true);
  };

  const exportUserDataJson = async () => {
    setAllUserDataLoading(true);
    if (identity && !allUserDataLoading) {
      const userData = await identityService.getAllUserData(identity.id);
      if (userData) {
        try {
          await saveAs(
            new Blob([JSON.stringify(userData, null, 1)], { type: 'application/json' }),
            `${identity.id}.json`
          );
          alert.setSuccessAlert(t('alertMessages.userDataExport.onSuccess'));
        } catch (e) {
          alert.setErrorAlert(t('alertMessages.userDataExport.onError'));
        }
      }
      setAllUserDataLoading(false);
    }
  };

  const invalidateAllSessionsOnClick = async () => {
    setInvalidateSessionsLoading(true);
    await invalidateAccessTokens(
      () => alert.setSuccessAlert(t('alertMessages.invalidateAllSessions.onSuccess')),
      () => alert.setErrorAlert(t('alertMessages.invalidateAllSessions.onError'))
    ).finally(() => setInvalidateSessionsLoading(false));
  };

  const deleteUserData = async () => {
    setUserDetailPopupType(USER_DETAIL_POPUP_TYPE.DELETE_USER_DATA);
    setShowUserDetailPopup(true);
  };

  const showAddSubscribedAccessDialog = useCallback(() => {
    setSubscribedAccessData(undefined);
    setSubscribedAccessDialogtype(DIALOG_TYPE.ADD);
    setShowSubscribedAccessDialog(true);
  }, []);

  const onEditSubscribedAccess = useCallback((data: IFlattenedSubscribedAccess) => {
    setSubscribedAccessData(data);
    setSubscribedAccessDialogtype(DIALOG_TYPE.EDIT);
    setShowSubscribedAccessDialog(true);
  }, []);

  const onUpdateSubscribedAccess = useCallback(async () => {
    await identityHook.getIdentity();
    setShowSubscribedAccessDialog(false);
  }, [identityHook]);

  return identity ? (
    <AuthenticatedTemplate>
      <Stack justifyContent="space-between" mb={1.5} alignItems="center" direction="row">
        <Stack direction="row">
          <IconButton onClick={viewPreviousSearchResults} sx={{ mr: 0.5 }}>
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <Stack direction="column">
            <Typography variant="MHHeading">Identity information</Typography>
            <Typography variant="MHCaption">
              An overview of a user&apos;s personal information, subscribed access and activity log.
            </Typography>
          </Stack>
        </Stack>
        {!editModeEnabled && (
          <Button
            variant="brandContained"
            color="brandBlack"
            id="editUserDetails"
            onClick={() => setEditMode(true)}>
            Edit
          </Button>
        )}
        {editModeEnabled && (
          <Stack spacing={0.5} direction="row">
            <Button
              variant="brandContained"
              color="brandRed"
              id="cancelUserDetails"
              form="userDetailForm"
              disabled={isSavingEditedUserData}
              type="reset">
              Cancel
            </Button>
            <LoadingButton
              variant="brandContained"
              color="brandBlack"
              id="saveUserDetails"
              form="userDetailForm"
              loading={isSavingEditedUserData}
              type="submit">
              Save
            </LoadingButton>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" mb={2} spacing={0.5}>
        <Button
          startIcon={<EditOutlinedIcon />}
          id="changePassword"
          onClick={() => showUserDetailPopup(USER_DETAIL_POPUP_TYPE.CHANGE_PASSWORD)}
          size="small"
          variant="brandOutlined"
          color="brandBlack">
          Change password
        </Button>
        <Button
          startIcon={<EmailOutlinedIcon />}
          id="changeEmail"
          onClick={() => showUserDetailPopup(USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL)}
          size="small"
          variant="brandOutlined"
          color="brandBlack">
          Change email
        </Button>
        <LoadingButton
          startIcon={<LogoutOutlinedIcon />}
          size="small"
          variant="brandOutlined"
          color="brandBlack"
          disabled={identityLoading}
          loading={invalidateSessionsLoading}
          onClick={invalidateAllSessionsOnClick}>
          {t('invalidateAllSessionsButtonText')}
        </LoadingButton>
        <RoleAuthorizeShowGuard
          requiredRoles={[CUSSUP_ROLES.CUSSUP_DATA_PROTECTION]}
          currentRoles={roles}>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            id="deleteUserData"
            size="small"
            variant="contained"
            color="brandRed"
            onClick={deleteUserData}>
            Delete user data
          </Button>
        </RoleAuthorizeShowGuard>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={10} md={8}>
          <UserDetailGrid
            identityHook={identityHook}
            editModeEnabled={editModeEnabled}
            setEditMode={setEditMode}
            setSavingEditedUserData={setIsSavingEditedUserData}></UserDetailGrid>
        </Grid>
      </Grid>
      <SubscribedAccessDialog
        type={subscribedAccessDialogType}
        identityId={identity.id}
        open={showSubscribedAccessDialog}
        onClose={() => setShowSubscribedAccessDialog(false)}
        onSave={onUpdateSubscribedAccess}
        data={subscribedAccessData}
      />
      {showEditUserDetailPopup && (
        <UserDetailPopup
          identityHook={identityHook}
          showPopup={showEditUserDetailPopup}
          popupClosed={setShowUserDetailPopup}
          popupType={userDetailPopupType}
        />
      )}

      <Stack justifyContent="space-between" alignItems="center" direction="row" my={1.5}>
        <Typography variant="MHHeading">Subscribed access</Typography>
        <Button
          variant="brandContained"
          color="brandBlack"
          id="add-subscribed-access-button"
          onClick={showAddSubscribedAccessDialog}>
          Add
        </Button>
      </Stack>

      <Box mb={1.5}>
        <TabPanel
          initialValue={SUBSCRIBED_ACCESS_TAB_VALUES.CURRENT}
          values={Object.values(SUBSCRIBED_ACCESS_TAB_VALUES)}
          setTabValue={setTabValue}
        />
      </Box>
      {tabValue === SUBSCRIBED_ACCESS_TAB_VALUES.CURRENT && (
        <SubscribedAccessTableContainer
          onEdit={onEditSubscribedAccess}
          collectionHook={subscribedAccessesCollectionHook}
        />
      )}
      {tabValue === SUBSCRIBED_ACCESS_TAB_VALUES.HISTORY && (
        <SubscribedAccessHistoryTableContainer
          collectionHook={subscribedAccessesHistoryCollectionHook}
        />
      )}

      <Typography variant="MHHeading" my={3}>
        Activity
      </Typography>
      <UserActivityDataGrid pagedCollectionHook={activityLogsCollectionHook} />
      <Typography variant="MHHeading" my={3}>
        Consents
      </Typography>
      <UserConsentDataGrid identityHook={identityHook} />

      <RoleAuthorizeShowGuard
        requiredRoles={[CUSSUP_ROLES.CUSSUP_DATA_PROTECTION]}
        currentRoles={roles}>
        <>
          <Typography variant="MHHeading" my={3}>
            Export all user data
          </Typography>
          <LoadingButton
            variant="brandContained"
            color="brandBlack"
            id="getAllUserDataJsonBtn"
            onClick={exportUserDataJson}
            loading={allUserDataLoading}
            sx={{
              mr: 1,
            }}>
            Export user data (JSON)
          </LoadingButton>
          {allUserDataLoading && (
            <Typography variant="MHCaption" component="span" mx={1}>
              {t('alertMessages.userDataExport.onLoading')}
            </Typography>
          )}
        </>
      </RoleAuthorizeShowGuard>
    </AuthenticatedTemplate>
  ) : (
    <Spinner />
  );
};
