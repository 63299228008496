import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import MultiAccountUserDataGrid from '../../components/MultiAccount/MultiAccountUserDataGrid';
import UseMembers from '../../hooks/useMembers';
import { RemoveMemberPopUp } from '../../components/Members/RemoveMemberPopUp';
import { AddMemberPopUp } from '../../components/Members/AddMemberPopUp';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

export const MultiAccountDetail: FC = () => {
  const [toggleRemovePopup, setToggleRemovePopup] = useState<boolean>(false);
  const [toggleAddPopup, setToggleAddPopup] = useState<boolean>(false);

  const navigate = useNavigate();
  const membersHook = UseMembers();

  const viewPreviousSearchResults = () => {
    navigate(-1);
  };

  useEffect(() => {
    membersHook.getMembers();
  }, []);

  return (
    <AuthenticatedTemplate>
      <Stack justifyContent="space-between" direction="row" mb={3} alignItems="center">
        <Stack direction="row">
          <IconButton onClick={viewPreviousSearchResults} sx={{ mr: 0.5 }}>
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <Stack direction="column">
            <Typography variant="MHHeading">Manage multi account subscription</Typography>
            <Typography variant="MHCaption">
              View, remove or add members to the multi account subscription.
            </Typography>
          </Stack>
        </Stack>
        <Button variant="brandContained" color="brandBlack" onClick={() => setToggleAddPopup(true)}>
          Add
        </Button>
      </Stack>

      <MultiAccountUserDataGrid
        membersHook={membersHook}
        setToggleRemovePopup={setToggleRemovePopup}
      />

      <AddMemberPopUp
        membersHook={membersHook}
        showPopup={toggleAddPopup}
        setShowPopup={setToggleAddPopup}
      />

      <RemoveMemberPopUp
        membersHook={membersHook}
        showPopup={toggleRemovePopup}
        setShowPopup={setToggleRemovePopup}
      />
    </AuthenticatedTemplate>
  );
};
