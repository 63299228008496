import { ComponentType } from 'react';
import { UserSearch } from '../views/UserSearch';
import { Login } from '../views/Login';
import { UserDetail } from '../views/UserDetail';
import { CUSSUP_ROLES } from '../types/auth';
import { CreateIdentity } from '../views/CreateIdentity';
import { MultiAccountSearch } from '../views/MultiAccountSearch';
import { MultiAccountDetail } from '../views/MultiAccountDetail';

export enum ROUTES {
  ROOT = '/',
  SEARCH = 'user/search',
  DETAIL = 'user/detail/:auth0Id',
  IDENTITIES = '/identities',
  SEARCH_MA = '/multiaccount/search',
  MA_DETAIL = '/multiaccount/detail/:auth0Id/subscriptionId/:subscriptionId/source/:source',
}

interface GuardedRoute {
  path: string;
  Component: ComponentType;

  requiredRoles: CUSSUP_ROLES[];
  props?: Record<string, unknown>;
}

export const Routes: GuardedRoute[] = [
  {
    path: ROUTES.ROOT,
    Component: Login,
    requiredRoles: [],
  },
  {
    path: ROUTES.SEARCH,
    Component: UserSearch,
    requiredRoles: [
      CUSSUP_ROLES.CUSSUP_GENERAL,
      CUSSUP_ROLES.CUSSUP_SPECIALIZED,
      CUSSUP_ROLES.CUSSUP_DATA_PROTECTION,
    ],
  },
  {
    path: ROUTES.SEARCH_MA,
    Component: MultiAccountSearch,
    requiredRoles: [CUSSUP_ROLES.CUSSUP_SPECIALIZED, CUSSUP_ROLES.CUSSUP_DATA_PROTECTION],
  },
  {
    path: ROUTES.MA_DETAIL,
    Component: MultiAccountDetail,
    requiredRoles: [CUSSUP_ROLES.CUSSUP_SPECIALIZED, CUSSUP_ROLES.CUSSUP_DATA_PROTECTION],
  },
  {
    path: ROUTES.DETAIL,
    Component: UserDetail,
    requiredRoles: [
      CUSSUP_ROLES.CUSSUP_GENERAL,
      CUSSUP_ROLES.CUSSUP_SPECIALIZED,
      CUSSUP_ROLES.CUSSUP_DATA_PROTECTION,
    ],
  },
  {
    path: ROUTES.IDENTITIES,
    Component: CreateIdentity,
    requiredRoles: [
      CUSSUP_ROLES.CUSSUP_GENERAL,
      CUSSUP_ROLES.CUSSUP_SPECIALIZED,
      CUSSUP_ROLES.CUSSUP_DATA_PROTECTION,
    ],
  },
];
