import { AddressServiceLanguage } from '../services/addressService';
import { object, ObjectSchema, string, ValidationError } from 'yup';
import { CountryConfig } from './getCountryConfig';
import {
  IMS_VALIDATION_PROPERTIES,
  IValidationProperties,
  validateAddress,
} from '../types/validation';
import dayjs from 'dayjs';
import { debounce } from './debounce';
import { parseDateString } from './utils';

interface UserDetailValidationBuilder {
  addFirstName(): UserDetailValidationBuilder;

  addLastName(): UserDetailValidationBuilder;

  addNickName(): UserDetailValidationBuilder;

  addBirthDay(): UserDetailValidationBuilder;

  addGender(): UserDetailValidationBuilder;

  addAddress(
    language: AddressServiceLanguage,
    countryConfig: CountryConfig
  ): UserDetailValidationBuilder;

  addTelephone(): UserDetailValidationBuilder;

  build(): ObjectSchema<any>;
}

export class UserDetailValidationSchemaBuilder implements UserDetailValidationBuilder {
  private baseObject: ObjectSchema<any>;
  private readonly validationProps: IValidationProperties;

  constructor(validationProps: IValidationProperties) {
    this.baseObject = object();
    this.validationProps = validationProps;
  }

  private async validateAddress(
    values: { city?: string; postalCode?: string; street?: string; houseNumber?: string },
    language: AddressServiceLanguage,
    countryConfig: CountryConfig
  ): Promise<boolean | ValidationError> {
    const validationResult = await validateAddress({
      language,
      address: {
        city: values.city,
        postalCode: values.postalCode,
        street: values.street,
        houseNumber: values.houseNumber,
      },
      countryConfig,
    });
    const validationErrors: ValidationError[] = [];
    // Checks if values are filled in & valid
    if (
      (values.city || values.street || values.postalCode || values.houseNumber) &&
      !countryConfig.countryISO
    ) {
      validationErrors.push(
        new ValidationError(
          'Country is required when adding address.',
          undefined,
          'address.countryCode'
        )
      );
    }

    if (values.city && !validationResult.isValidCity && countryConfig.validation.validateCity) {
      validationErrors.push(new ValidationError('Invalid city.', undefined, 'address.city'));
    }
    if (
      values.postalCode &&
      !validationResult.isValidPostalCode &&
      countryConfig.validation.validatePostcode
    ) {
      validationErrors.push(
        new ValidationError('Invalid postal code.', undefined, 'address.postalCode')
      );
    }
    if (
      values.city &&
      values.postalCode &&
      countryConfig.validation.matchPostcodeAndCity &&
      !validationResult.isValidMatchPostalCodeCity
    ) {
      validationErrors.push(
        new ValidationError("Postal code and city don't match", undefined, 'address.city')
      );
    }

    if (
      values.street &&
      !validationResult.isValidStreet &&
      countryConfig.validation.validateStreet
    ) {
      validationErrors.push(new ValidationError('Invalid street', undefined, 'address.street'));
    }

    if (
      values.houseNumber &&
      !validationResult.isValidHouseNumber &&
      countryConfig.validation.validateHouseNumber
    ) {
      validationErrors.push(
        new ValidationError('Invalid house number', undefined, 'address.houseNumber')
      );
    }
    return validationErrors.length > 0 ? new ValidationError(validationErrors) : true;
  }

  addAddress(
    language: AddressServiceLanguage,
    countryConfig: CountryConfig
  ): UserDetailValidationBuilder {
    let addressObject = object().test(
      debounce((values) => this.validateAddress(values, language, countryConfig), 300)
    );

    addressObject = addressObject.shape({
      city: string()
        .max(IMS_VALIDATION_PROPERTIES.MAX_CITY_LENGTH)
        .matches(IMS_VALIDATION_PROPERTIES.UNICODE_CITY_REGEX),
      postalCode: string()
        .max(IMS_VALIDATION_PROPERTIES.MAX_POSTAL_CODE_LENGTH)
        .matches(IMS_VALIDATION_PROPERTIES.UNICODE_POSTAL_CODE_REGEX),
      street: string()
        .max(IMS_VALIDATION_PROPERTIES.MAX_STREET_LENGTH)
        .matches(IMS_VALIDATION_PROPERTIES.UNICODE_STREET_REGEX),
      houseNumber: string()
        .max(IMS_VALIDATION_PROPERTIES.MAX_HOUSE_NUMBER_LENGTH)
        .matches(IMS_VALIDATION_PROPERTIES.UNICODE_HOUSE_NUMBER_REGEX),
      box: string()
        .optional()
        .max(IMS_VALIDATION_PROPERTIES.BOX_NUMBER_LENGTH)
        .matches(IMS_VALIDATION_PROPERTIES.BOX_NUMBER_REGEX),
      countryCode: string().optional(),
    });
    this.baseObject = this.baseObject.shape({
      address: addressObject,
    });
    return this;
  }

  private getMaxBirthdateYear() {
    return dayjs().get('year') - this.validationProps.maxBirthdateYearDifferenceFromToday;
  }

  addBirthDay() {
    this.updateDemographicsPersonSchema((schema) =>
      schema.shape({
        dateOfBirth: string()
          .optional()
          .test('valid-date', 'Invalid date format', (value) => {
            // Check if the date format is valid
            if (!value) return true;
            return /^\d{4}-\d{2}-\d{2}$/.test(value ?? '');
          })
          .test('min-year', 'Year is too early', (value) => {
            if (!value) return true;
            const date = parseDateString(value);
            const minYear = this.validationProps.minBirthdateYear;
            return date.year >= minYear;
          })
          .test('max-year', 'Year is too late', (value) => {
            if (!value) return true;
            const date = parseDateString(value ?? '');
            const maxYear = this.getMaxBirthdateYear();
            return date.year < maxYear;
          })
          .test('valid-month', 'Invalid month', (value) => {
            if (!value) return true;
            const date = parseDateString(value ?? '');
            return date.month > 0 && date.month <= 12;
          })
          .test('valid-day', 'Invalid day', (value) => {
            if (!value) return true;
            const date = parseDateString(value ?? '');
            return date.day > 0 && date.day <= 31;
          }),
      })
    );
    return this;
  }

  private updateNamesPersonSchema(update: (schema: any) => any): void {
    const currentSchema = this.baseObject.fields.namesPerson as any;
    const namesPersonSchema = currentSchema ? currentSchema.clone() : object();
    this.baseObject = this.baseObject.shape({
      namesPerson: update(namesPersonSchema),
    });
  }

  private updateDemographicsPersonSchema(update: (schema: any) => any): void {
    const currentSchema = this.baseObject.fields.demographicsPerson as any;
    const demographicsPersonSchema = currentSchema ? currentSchema.clone() : object();
    this.baseObject = this.baseObject.shape({
      demographicsPerson: update(demographicsPersonSchema),
    });
  }

  addFirstName(): UserDetailValidationBuilder {
    this.updateNamesPersonSchema((schema) =>
      schema.shape({
        firstName: string()
          .optional()
          .max(
            this.validationProps.maxFirstNameLength,
            `First name can be ${this.validationProps.maxFirstNameLength} characters`
          )
          .matches(
            new RegExp(this.validationProps.unicodeTextRegex, 'u'),
            `Please provide a valid first name`
          ),
      })
    );
    return this;
  }

  addLastName(): UserDetailValidationBuilder {
    this.updateNamesPersonSchema((schema) =>
      schema.shape({
        lastName: string()
          .optional()
          .max(
            this.validationProps.maxLastNameLength,
            `Last name can be ${this.validationProps.maxLastNameLength} characters`
          )
          .matches(
            new RegExp(this.validationProps.unicodeTextRegex, 'u'),
            `Please provide a valid last name`
          ),
      })
    );
    return this;
  }

  addNickName(): UserDetailValidationBuilder {
    this.updateNamesPersonSchema((schema) =>
      schema.shape({
        nickName: string()
          .required(`Please provide a nickname`)
          .min(
            this.validationProps.minNickNameLength,
            `Nickname must be at least ${this.validationProps.minNickNameLength} character${
              this.validationProps.minNickNameLength > 1 ? 's' : ''
            }`
          )
          .max(
            this.validationProps.maxNickNameLength,
            `Nickname can be ${this.validationProps.maxNickNameLength} characters`
          ),
      })
    );
    return this;
  }

  addGender(): UserDetailValidationBuilder {
    this.updateDemographicsPersonSchema((schema) =>
      schema.shape({
        genderType: string().oneOf(['male', 'female', 'other'], 'Invalid gender').nullable(),
        genderCustom: string()
          .optional()
          .max(
            this.validationProps.maxGenderCustomLength,
            `Gender custom can be ${this.validationProps.maxGenderCustomLength} characters`
          ),
      })
    );
    return this;
  }

  addTelephone(): UserDetailValidationBuilder {
    const schema = object().shape({
      phone: string().matches(
        new RegExp(this.validationProps.phoneRegex),
        'Invalid telephone number.'
      ),
    });

    this.baseObject = this.baseObject.shape({
      contactpointsPhone: schema,
    });
    return this;
  }

  build(): ObjectSchema<any> {
    return this.baseObject;
  }
}
