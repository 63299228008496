import { useEffect, useState } from 'react';
import { IPagedResult } from '../types/search';

const usePagedCollection = <T>() => {
  const [collection, setCollection] = useState<IPagedResult<T[]>>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collectionLoader, setCollectionLoader] =
    useState<(pageSize: number, pageNumber: number) => Promise<IPagedResult<T[]>>>();

  const loadCollection: () => Promise<void> = () => {
    if (!collectionLoader) return Promise.resolve();
    setIsLoading(true);
    return collectionLoader(pageSize, pageNumber)
      .then((result) => setCollection(result))
      .catch(() => setHasErrors(true))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadCollection();
  }, [pageSize, pageNumber, collectionLoader]);

  return {
    setPageSize,
    setPageNumber,
    setCollectionLoader,
    collection,
    hasErrors,
    isLoading,
    pageNumber,
    pageSize,
  };
};

export type UsePagedCollectionHook<T> = typeof usePagedCollection<T>;
export type UsePagedCollectionHookResult<T> = ReturnType<UsePagedCollectionHook<T>>;
export default usePagedCollection;
