import React, { FC } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GridTooltip } from '../General/GridTooltip';
import { ISearchResult } from '../../types/search';
import { mapSubscriptionInfoToString } from '../../helpers/listToDelimitedStringMapper';

interface SearchResultsDataGridProps {
  searchResults: ISearchResult[] | null;
  loading: boolean;
  setResultsPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setResultsPageSize: React.Dispatch<React.SetStateAction<number>>;
  resultsPageNumber: number;
  resultsPageSize: number;
}

const SearchResultsDataGrid: FC<SearchResultsDataGridProps> = ({
  searchResults,
  setResultsPageNumber,
  setResultsPageSize,
  resultsPageNumber,
  resultsPageSize,
  loading,
}) => {
  const navigate = useNavigate();
  const selectUser = (user: ISearchResult) => {
    navigate(`../user/detail/${user.auth0Id}`);
  };

  const pageOptions = [10, 25, 50];
  const columns: GridColDef[] = [
    {
      field: 'auth0Id',
      headerName: 'Auth0 ID',
      minWidth: 260,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 300,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        mapSubscriptionInfoToString(params.row.subscribedAccesses, (item) => item.source),
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'subscriptionId',
      headerName: 'Subscription ID',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        mapSubscriptionInfoToString(params.row.subscribedAccesses, (item) => item.subscriptionId),
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        mapSubscriptionInfoToString(params.row.subscribedAccesses, (item) => item.orderId),
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
  ];

  return (
    searchResults && (
      <DataGrid
        autoHeight
        columns={columns}
        getRowId={() => Math.floor(Math.random() * 100000)}
        loading={loading}
        paginationMode="server"
        onPageChange={(newPageNumber) => setResultsPageNumber(newPageNumber)}
        onPageSizeChange={(newPageSize) => setResultsPageSize(newPageSize)}
        onRowClick={(row) => selectUser(row.row)}
        rowCount={100}
        rowsPerPageOptions={pageOptions}
        pageSize={
          !pageOptions.includes(searchResults.length) ? resultsPageSize : searchResults.length
        }
        page={searchResults.length > resultsPageSize ? 0 : resultsPageNumber}
        rows={searchResults}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
        {...searchResults}
      />
    )
  );
};

export default SearchResultsDataGrid;
