import React, { FC, useCallback, useState } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { Sorting } from '../../../util/sortingUtils';
import { HeadCell, PaginationInfo } from '../../../types/table';
import TableHeadWithSorting from '../Table/TableHeadWithSorting';
import CustomTablePagination from '../Table/CustomTablePagination';
import ExpandableTableBody from './ExpandableTableBody';
import { GenericExpandableTableComponentProps } from './ExpandedTableComponent';
import { useSortingHook } from '../../../hooks/useSortingHook';
import { usePaginationHook } from '../../../hooks/usePagination';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';

interface ExpandableTableProps<T> {
  collection: T[];
  comparator: (a: T, b: T, orderBy: keyof T | undefined) => 1 | -1 | 0;
  isExpandable?: (row: T) => boolean;
  isEditable?: (row: T) => boolean;
  headerCells: readonly HeadCell<T>[];
  rowCellConfig: TooltipCellConfig<T>[];
  handleEditClick?: (row: T, index: number) => void;
  GenericExpandableTableComponent: FC<GenericExpandableTableComponentProps<T>>;
  expandableTableHeadCells: HeadCell<any>[];
  expendableTableRowConfig: TooltipCellConfig<any>[];
  paginationInfo: PaginationInfo;
}

const ExpandableCustomTable = <T,>({
  collection,
  comparator,
  isExpandable,
  isEditable,
  headerCells,
  rowCellConfig,
  handleEditClick,
  GenericExpandableTableComponent,
  paginationInfo,
  expandableTableHeadCells,
  expendableTableRowConfig,
}: ExpandableTableProps<T>) => {
  const [sorting, setSorting] = useState<Sorting<T>>({ orderBy: undefined, order: 'asc' });
  const { pageNumber, pageSize, setPageNumber, setPageSize } = paginationInfo;

  const orderComparator = useCallback(
    (a: T, b: T) =>
      ((sorting.order === 'desc' ? 1 : -1) * comparator(a, b, sorting.orderBy)) as 1 | -1 | 0,
    [comparator, sorting.order, sorting.orderBy]
  );
  const sortedCollection = useSortingHook({ collection, comparator: orderComparator });
  const paginatedRows = usePaginationHook({
    collection: sortedCollection,
    page: pageNumber,
    rowsPerPage: pageSize,
  });

  return (
    <TableContainer>
      <Table style={{ borderSpacing: '0' }}>
        <TableHeadWithSorting<T>
          headCells={headerCells}
          sorting={sorting}
          onSortingChange={setSorting}
        />
        <TableBody>
          <ExpandableTableBody
            data={paginatedRows}
            handleEditClick={handleEditClick}
            isExpandable={isExpandable}
            isEditable={isEditable}
            GenericExpandableTableComponent={GenericExpandableTableComponent}
            rowCellConfig={rowCellConfig}
            headCells={expandableTableHeadCells}
            rowConfig={expendableTableRowConfig}
          />
        </TableBody>
      </Table>
      <CustomTablePagination
        collection={collection || []}
        page={pageNumber}
        rowsPerPage={pageSize}
        setNewPage={setPageNumber}
        setNewRowsPerPage={setPageSize}
      />
    </TableContainer>
  );
};

export default ExpandableCustomTable;
