import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BrandGroupConfig } from '../../types/brands';

const initialState: BrandGroupConfig[] = [];

const brandGroupConfigSlice = createSlice({
  name: 'brandGroupConfig',
  initialState,
  reducers: {
    loadBrandGroupConfig: (state, action: PayloadAction<BrandGroupConfig[]>) => {
      return action.payload;
    },
  },
});

export const selector = (state: RootState) => state.brandGroupConfig;
export const { loadBrandGroupConfig } = brandGroupConfigSlice.actions;
export default brandGroupConfigSlice.reducer;
