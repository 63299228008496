export enum SEARCH_RESULT_COLUMNS {
  AUTH0_ID = 'Auth0 ID',
  EMAIL = 'Email',
  SOURCE = 'Source',
  SUBSCRIBER = 'Subscriber',
  SUBSCRIPTION = 'Subscription',
  ORDER = 'Order',
}

export enum HANDLE_RESULTS {
  DEFAULT,
  REDIRECT,
  DISPLAY_TABLE,
}

export enum DELIMETERS {
  SEPERATOR = ' | ',
  NOT_AVAILABLE = ' / ',
}

export const SharedEmoji = String.fromCodePoint(0x1f465);
export const OwnerEmoji = String.fromCodePoint(0x1f451);

export interface ISearchTerms {
  auth0Id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionId?: string;
  orderId?: string;
}

export interface ISubscribedAccessInfo {
  source: string;
  subscriptionId: string;
  orderId: string;
  isShared: boolean;
  role: string;
}

export interface ISearchResult {
  auth0Id: string;
  firstName: string;
  lastName: string;
  email: string;
  subscribedAccesses: ISubscribedAccessInfo[];
}

export interface IPagedResult<T> {
  pageSize: number;
  pageNumber: number;
  total: number;
  data: T;
}
