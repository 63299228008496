import React from 'react';
import { TableBody } from '@mui/material';
import { CustomEditableRow } from './CustomEditableRow';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';

interface CustomTableBodyProps<T> {
  visibleRows: T[];
  isHighlighted: (data: T) => boolean;
  handleEditClick?: (row: T, index: number) => void;
  rowCellConfig: TooltipCellConfig<T>[];
}

const CustomTableBody = <T,>({
  visibleRows,
  isHighlighted,
  handleEditClick,
  rowCellConfig,
}: CustomTableBodyProps<T>) => {
  return (
    <TableBody>
      {visibleRows &&
        visibleRows.map((row, index) => (
          <React.Fragment key={index}>
            <CustomEditableRow
              data={row}
              isHighlighted={isHighlighted}
              index={index}
              handleEditClick={handleEditClick}
              rowCellConfig={rowCellConfig}
            />
          </React.Fragment>
        ))}
    </TableBody>
  );
};

export default CustomTableBody;
