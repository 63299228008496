import React, { FC } from 'react';
import { HANDLE_RESULTS, ISearchTerms } from '../../types/search';
import { useDispatch } from 'react-redux';
import { setDisplayMode } from '../../redux/reducers/displayModeReducer';
import { onKeyDown } from '../../util/onKeyDown';
import { KeyboardKey } from '../../types/keyboardKey';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, Stack } from '@mui/material';
import { FormikTextField } from '../General/FormikTextField';
import LoadingButton from '@mui/lab/LoadingButton';

interface FormValues {
  userId: string;
  email: string;
  subscriptionId: string;
}

const MultiAccountSubscriptionSearchBar: FC<{
  setSearchTerms: React.Dispatch<React.SetStateAction<ISearchTerms>>;
  loading: boolean;
}> = ({ setSearchTerms, loading }) => {
  const dispatch = useDispatch();
  const initialValues: FormValues = {
    userId: '',
    email: '',
    subscriptionId: '',
  };

  const ValidationSchema = Yup.object().shape({
    userId: Yup.string(),
    email: Yup.string().min(3, 'Please provide at least 3 characters'),
    subscriptionId: Yup.string(),
  });

  const setUserSearch = (values: FormValues) => {
    // Set a searchTerm property only if a value was given.
    setSearchTerms({
      ...(values.userId && { auth0Id: values.userId }),
      ...(values.email && { email: `${values.email}*` }),
      ...(values.subscriptionId && { subscriptionId: values.subscriptionId }),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => setUserSearch(values)}
      onReset={() => {
        dispatch(setDisplayMode(HANDLE_RESULTS.DEFAULT));
        setSearchTerms({});
      }}
      validationSchema={ValidationSchema}>
      {({ isValid, submitForm, dirty, ...formik }) => (
        <Form noValidate>
          <Stack
            alignItems="flex-end"
            direction="row"
            my={3}
            spacing={1}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (!isValid || !dirty) onKeyDown(e, KeyboardKey.ENTER, submitForm);
            }}>
            <FormikTextField
              name="userId"
              formikId="userSearch"
              label="User ID"
              placeholder="auth0|..."
              optionalLabel="Wildcards (*) are allowed"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikTextField
              name="email"
              formikId="emailSearch"
              label="Email"
              optionalLabel="Provide email prefix. Wildcards (*) are allowed"
              placeholder="john.doe@example.com"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikTextField
              name="subscriptionId"
              formikId="subscriptionSearch"
              label="Subscription ID"
              placeholder="Subscription ID"
              optionalLabel="Only exact matches will be returned. Wildcards (*) are not allowed"
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
            />
            <LoadingButton
              id="findSubscription"
              disabled={!isValid || !dirty}
              loading={loading}
              type="submit"
              variant="brandContained"
              color="brandBlack"
              sx={{ height: '56px' }}>
              Find subscription
            </LoadingButton>
            <Button
              id="resetSubscription"
              type="reset"
              variant="brandContained"
              color="brandBlack"
              sx={{ height: '56px' }}>
              Reset
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default MultiAccountSubscriptionSearchBar;
