import { BrandClient, BrandGroupConfig } from '../types/brands';
import { FormikValues } from 'formik';
import { identityService } from './identityService';
import { USER_DETAIL_POPUP_TYPE } from '../types/user-detail-popup-type';
import { Identity } from '../types/identity';

export interface INewEmailRequest {
  newEmail: string;
  brand: BrandClient['brandCode'];
  brandGroup: BrandGroupConfig['id'];
  clientId: BrandClient['clientId'];
  language: 'nl';
}

class MailService {
  clients: BrandClient[];
  brandGroups: BrandGroupConfig[];

  constructor(clientList: BrandClient[], brandGroupList: BrandGroupConfig[]) {
    this.clients = clientList;
    this.brandGroups = brandGroupList;
  }

  // TODO: formikValues and popupType as parameters future proof?
  public handleSendEmailRequest = async (
    formikValues: FormikValues,
    popupType: USER_DETAIL_POPUP_TYPE | undefined,
    identity: Pick<Identity, 'id' | 'email'>
  ) => {
    const brandClient = this.clients.find((brand) => brand.clientId === formikValues.clientId);
    if (!brandClient) return;
    switch (popupType) {
      case USER_DETAIL_POPUP_TYPE.CHANGE_PASSWORD:
        return await this.sendChangePasswordMail(brandClient, popupType, identity);
      case USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL:
        return await this.sendChangeEmailMail(formikValues, brandClient, popupType, identity);
      case USER_DETAIL_POPUP_TYPE.VERIFY_EMAIL_REQUEST:
        return await this.sendNewVerifyMail(brandClient, popupType, identity);
    }
  };

  private sendChangePasswordMail = async (
    brandClient: BrandClient,
    popupType: USER_DETAIL_POPUP_TYPE,
    identity: Pick<Identity, 'id' | 'email'>
  ) => {
    const data: INewEmailRequest = {
      brand: '',
      brandGroup: '',
      language: 'nl',
      newEmail: '',
      clientId: brandClient.clientId,
    };
    return await this.sendEmail(data, popupType, identity);
  };

  private sendChangeEmailMail = async (
    formikValues: FormikValues,
    brandClient: BrandClient,
    popupType: USER_DETAIL_POPUP_TYPE,
    identity: Pick<Identity, 'id' | 'email'>
  ) => {
    const brandGroup = this.brandGroups.find(({ brands }) =>
      brands.some((brand) => brand.id === brandClient.brandCode)
    );
    if (!brandGroup || !formikValues.email) return;
    const data: INewEmailRequest = {
      newEmail: formikValues.email,
      brand: brandClient.brandCode,
      brandGroup: brandGroup.id,
      clientId: brandClient.clientId,
      language: 'nl',
    };

    return await this.sendEmail(data, popupType, identity);
  };

  private sendEmail = async (
    newMailRequest: INewEmailRequest,
    popupType: USER_DETAIL_POPUP_TYPE,
    identity: Pick<Identity, 'id' | 'email'>
  ) => {
    if (popupType === USER_DETAIL_POPUP_TYPE.CHANGE_PASSWORD) {
      return await identityService.updateIdentityPassword(identity.id, newMailRequest.clientId);
    }
    if (popupType === USER_DETAIL_POPUP_TYPE.CHANGE_EMAIL) {
      return await identityService.updateIdentityEmail(identity.id, newMailRequest);
    }
    if (popupType === USER_DETAIL_POPUP_TYPE.VERIFY_EMAIL_REQUEST) {
      return await identityService.sendNewVerificationMail(identity.id, newMailRequest.clientId);
    }
  };

  private sendNewVerifyMail = async (
    brandClient: BrandClient,
    popupType: USER_DETAIL_POPUP_TYPE,
    identity: Pick<Identity, 'id' | 'email'>
  ) => {
    const brandGroup = this.brandGroups.find(({ brands }) =>
      brands.some((brand) => brand.id === brandClient.brandCode)
    );
    if (!brandGroup || !identity.email) return;

    const data: INewEmailRequest = {
      newEmail: identity.email,
      brand: brandClient.brandCode,
      brandGroup: brandGroup.id,
      clientId: brandClient.clientId,
      language: 'nl',
    };
    return await this.sendEmail(data, popupType, identity);
  };
}

export { MailService };
