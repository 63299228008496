import {
  addressService,
  AddressServiceLanguage,
  AutoCompleteCitiesResponse,
} from '../services/addressService';
import { CountryConfig } from '../util/getCountryConfig';

export type Result = { ok: true } | { ok: false; message: string };

export const IMS_VALIDATION_PROPERTIES = {
  MAX_STREET_LENGTH: 100,
  MAX_HOUSE_NUMBER_LENGTH: 10,
  BOX_NUMBER_LENGTH: 6,
  MAX_CITY_LENGTH: 80,
  MAX_POSTAL_CODE_LENGTH: 9,
  MAX_COUNTRY_CODE_LENGTH: 2,
  // English letters, accented letters, numbers, spaces (\u0020), non-breaking spaces(\u00A0), hyphens(\u002D) and apostrophes(\u0027)
  // No leading, trailing spaces or dashes and no more than one space in a row.
  UNICODE_CITY_REGEX:
    /^[A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u0027]+(?:[\u0020\u00A0\u002D][A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u0027]+)*$/u,
  // English letters, accented letters, numbers, spaces (\u0020), non-breaking spaces(\u00A0), hyphens(\u002D) and apostrophes(\u0027)
  // No leading, trailing spaces or dashes and no more than one space in a row.
  UNICODE_STREET_REGEX:
    /^[A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u0027]+(?:[\u0020\u00A0\u002D][A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u0027]+)*$/u,
  // English letters, and numbers, spaces (\u0020), non-breaking spaces(\u00A0), hyphens(\u002D)
  // No leading, trailing spaces or dashes and no more than one space in a row.
  UNICODE_POSTAL_CODE_REGEX: /^[A-Za-z\p{N}]+(?:[\u0020\u00A0\u002D][A-Za-z\p{N}]+)*$/u,
  // English letters, numbers, spaces (\u0020), non-breaking spaces(\u00A0), hyphens(\u002D)
  // No leading, trailing spaces or dashes and no more than one space in a row.
  UNICODE_HOUSE_NUMBER_REGEX: /^[A-Za-z\p{N}]+(?:[\u0020\u00A0\u002D][A-Za-z\p{N}]+)*$/u,
  // English letters, accented letters, numbers, spaces (\s), non-breaking spaces(\u00A0), hyphens(\u002D) and point(\u002E)
  // No leading, trailing spaces or dashes and no more than one space in a row.
  BOX_NUMBER_REGEX:
    /^[A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u002E]+(?:[\u0020\u00A0\u002D][A-Za-zÀ-ÖØ-öø-ÿ\p{N}\u002E]+)*$/u,
};

export interface IValidationProperties {
  maxFirstNameLength: number;
  maxLastNameLength: number;
  maxRawPhoneNumberLength: number;
  maxNickNameLength: number;
  minNickNameLength: number;
  maxPostalCodeRegionalNews: number;
  maxGenderCustomLength: number;
  maxEmailLength: number;
  maxBrandLength: number;
  maxBrandCodeLength: number;
  maxClientIdLength: number;
  maxSubscriptionTypeLength: number;
  maxImportedByLength: number;
  maxStatusLength: number;
  unicodeTextRegex: string;
  minBirthdateYear: number;
  maxBirthdateYearDifferenceFromToday: number;
  phoneRegex: string;
}

type ValidateAddressResult = {
  isValidCity: boolean;
  isValidPostalCode: boolean;
  isValidMatchPostalCodeCity: boolean;
  isValidStreet: boolean;
  isValidHouseNumber: boolean;
};

type ValidateAddressProps = {
  language: AddressServiceLanguage;
  address: {
    postalCode?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
  };
  countryConfig: CountryConfig;
};

export async function validateAddress(props: ValidateAddressProps): Promise<ValidateAddressResult> {
  const { countryConfig, language, address } = props;
  const cityInput = address.city;
  const postalCodeInput = address.postalCode;

  let citiesResult: AutoCompleteCitiesResponse | undefined;
  let postalCodesResult: AutoCompleteCitiesResponse | undefined;

  let isValidCity = false;
  let isValidPostalCode = false;
  let isValidMatchPostalCodeCity = false;
  let isValidStreet = false;

  // City validation
  if (cityInput && props.countryConfig.validation.validateCity) {
    citiesResult = await addressService.autoCompleteCities({
      language,
      countryISO: countryConfig.countryISO,
      key: cityInput,
    });
    isValidCity = citiesResult.Cities.some(
      (city) => city.Name.toLowerCase() === cityInput.toLowerCase()
    );
  }
  // Postal code validation
  if (postalCodeInput && props.countryConfig.validation.validatePostcode) {
    postalCodesResult = await addressService.autoCompleteCities({
      language,
      countryISO: countryConfig.countryISO,
      key: postalCodeInput,
    });
    isValidPostalCode = postalCodesResult.Cities.some(
      (city) => city.PostalCode.toLowerCase() === postalCodeInput.toLowerCase()
    );
  }
  // Postal code & city match validation
  if (
    cityInput &&
    postalCodeInput &&
    postalCodesResult &&
    props.countryConfig.validation.matchPostcodeAndCity
  ) {
    isValidMatchPostalCodeCity = postalCodesResult.Cities.reduce(
      (prev, curr) =>
        prev ||
        (curr.Name.toLowerCase() === cityInput.toLowerCase() &&
          curr.PostalCode.toLowerCase() === postalCodeInput.toLowerCase()),
      false
    );
  }
  // Street validation
  if (postalCodeInput && isValidPostalCode && address.street) {
    const streetsResult = await addressService.autoCompleteStreets({
      language,
      countryISO: countryConfig.countryISO,
      postalCode: postalCodeInput,
      key: address.street,
    });
    isValidStreet = streetsResult.Streets.some(
      (street) => street.Name.toLowerCase() === address.street?.toLowerCase()
    );
  }
  let isValidHouseNumber = false;
  if (
    cityInput &&
    postalCodeInput &&
    isValidPostalCode &&
    isValidStreet &&
    address.street &&
    address.houseNumber
  ) {
    const addressResult = await addressService.validateAddress({
      addressToValidate: {
        street: {
          name: address.street,
        },
        city: {
          postalCode: postalCodeInput,
          name: cityInput,
        },
        houseNumber: address.houseNumber,
        countryISOCode: countryConfig.countryISO,
      },
    });
    if (props.countryConfig.validation.validateHouseNumber)
      isValidHouseNumber = addressResult.ValidationResult.ValidationPerField.some(
        (field) => field.Key === 'number' && field.Value
      );
  }

  return {
    isValidCity,
    isValidPostalCode,
    isValidMatchPostalCodeCity,
    isValidStreet,
    isValidHouseNumber,
  };
}
