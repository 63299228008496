export const luceneEscape = (str: string): string => {
  const toEscape = [
    ' ',
    '\\',
    ' ',
    '+',
    '-',
    '&',
    '|',
    '!',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '^',
    '"',
    '~',
    '*',
    '?',
    ':',
    '/',
  ];

  return str
    .split('')
    .map((c) => (toEscape.includes(c) ? `\\${c}` : c))
    .join('');
};
