import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import UserSearchBar from '../../components/UserSearchBar';
import { identityService } from '../../services/identityService';
import { HANDLE_RESULTS, ISearchResult, ISearchTerms } from '../../types/search';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayMode } from '../../redux/reducers/displayModeReducer';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import SearchResultsDataGrid from '../../components/UserSearch/SearchResultsDataGrid';
import { useTypedSearchParams } from '../../hooks/useTypedSearchParams';
import { Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { luceneEscape } from '../../helpers/lucene';

export const UserSearch: FC = () => {
  const { displayMode } = useSelector((state: RootState) => state.displayMode);
  const [searchParams, setSearchParams] = useTypedSearchParams<ISearchTerms>();
  const [searchResults, setSearchResults] = useState<ISearchResult[] | null>(null);
  const [resultsPageSize, setResultsPageSize] = useState<number>(10);
  const [resultsPageNumber, setResultsPageNumber] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearchChange = async function (
    searchParams: ISearchTerms,
    pageNumber: number,
    pageSize: number
  ) {
    if (Object.keys(searchParams).length === 0) return;

    setLoading(true);

    const request = {
      ...(searchParams.auth0Id && { auth0Id: searchParams.auth0Id }),
      ...(searchParams.email && { email: `${searchParams.email}*` }),
      ...(searchParams.firstName && { firstName: `${luceneEscape(searchParams.firstName)}*` }),
      ...(searchParams.lastName && { lastName: `${luceneEscape(searchParams.lastName)}*` }),
      ...(searchParams.subscriptionId && { subscriptionId: searchParams.subscriptionId }),
    };

    await identityService
      .getIdentities(request, pageNumber, pageSize)
      .then((pagedIdentities) => setSearchResults(pagedIdentities.data))
      .catch((err: unknown) => {
        if (err instanceof AxiosError) {
          alert.setErrorAlert(
            err.response?.status === 400
              ? t('alertMessages.userSearch.badRequest')
              : t('alertMessages.userSearch.onError')
          );
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    setResultsPageNumber(0);
    handleSearchChange(searchParams, resultsPageNumber, resultsPageSize);
  }, [searchParams]);

  useEffect(() => {
    handleSearchChange(searchParams, resultsPageNumber, resultsPageSize);
  }, [resultsPageNumber, resultsPageSize]);

  useEffect(() => {
    // Show table if multiple (or no) results, redirect to detail if only one.
    if (!searchResults) dispatch(setDisplayMode(HANDLE_RESULTS.DEFAULT));
    if (searchResults) {
      const resultCount = searchResults.length;
      if (resultCount === 1 && resultsPageNumber === 0) {
        dispatch(setDisplayMode(HANDLE_RESULTS.REDIRECT));

        // Navigate to basic search page before redirecting.
        // This avoids redirecting instantly again when pressing back button on the detail page.
        navigate('../user/search');
        navigate(`../user/detail/${searchResults[0].auth0Id}`);
      }
      if (resultCount === 0 || resultCount > 1)
        dispatch(setDisplayMode(HANDLE_RESULTS.DISPLAY_TABLE));
    }
  }, [searchResults]);

  return (
    <AuthenticatedTemplate>
      <Typography variant="MHHeading">User search</Typography>
      <Typography variant="MHCaption" mb={3}>
        Look up a user to display his current subscribed access and subscribed access history.
      </Typography>
      <Stack direction="column" flexWrap="wrap">
        <UserSearchBar
          searchTerms={searchParams}
          setSearchTerms={setSearchParams}
          loading={loading}
        />
        {displayMode === HANDLE_RESULTS.DISPLAY_TABLE && (
          <>
            <Typography variant="MHHeading" mt={3} mb={2}>
              Results
            </Typography>
            <SearchResultsDataGrid
              searchResults={searchResults}
              setResultsPageSize={setResultsPageSize}
              setResultsPageNumber={setResultsPageNumber}
              resultsPageNumber={resultsPageNumber}
              resultsPageSize={resultsPageSize}
              loading={loading}
            />
          </>
        )}
      </Stack>
    </AuthenticatedTemplate>
  );
};
