// utils/formInitialValues.js

import type { FullIdentity, Identity } from '../types/identity';
import type { DeepPartial } from './util-types';

export const getInitialUserDetailValues = (
  identity: DeepPartial<Identity> | DeepPartial<FullIdentity>
): Required<Identity> => ({
  id: identity?.id ?? '',
  email: identity?.email ?? '',
  emailVerified: identity?.emailVerified ?? false,
  address: {
    street: identity?.address?.street ?? '',
    houseNumber: identity?.address?.houseNumber ?? '',
    houseNumberExtension: identity?.address?.houseNumberExtension ?? '',
    city: identity?.address?.city ?? '',
    postalCode: identity?.address?.postalCode ?? '',
    countryCode: identity?.address?.countryCode ?? '',
  },
  contactpointsPhone: {
    phone: identity?.contactpointsPhone?.phone ?? '',
  },
  demographicsPerson: {
    genderCode: identity?.demographicsPerson?.genderCode ?? 'u',
    genderCustom: identity?.demographicsPerson?.genderCustom ?? '',
    dateOfBirth: identity?.demographicsPerson?.dateOfBirth ?? '',
  },
  namesPerson: {
    firstName: identity?.namesPerson?.firstName ?? '',
    lastName: identity?.namesPerson?.lastName ?? '',
    nickName: identity?.namesPerson?.nickName ?? '',
  },
  identityLevels: identity?.identityLevels ?? [],
});
