import { useMemo } from 'react';

export type SortingHookProps<T> = {
  collection: T[];
  comparator: (a: T, b: T) => 1 | -1 | 0;
};

export const useSortingHook = <T,>({ collection, comparator }: SortingHookProps<T>): T[] => {
  return useMemo(() => [...collection].sort(comparator), [collection, comparator]);
};
