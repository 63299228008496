import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { HANDLE_RESULTS } from '../../types/search';

const initialState = { displayMode: HANDLE_RESULTS.DEFAULT };

const displayModeSlice = createSlice({
  name: 'displayMode',
  initialState,
  reducers: {
    setDisplayMode: (state, action: PayloadAction<HANDLE_RESULTS>) => {
      state = { displayMode: action.payload };
      return state;
    },
  },
});

export const selector = (state: RootState) => state.displayMode;
export const { setDisplayMode } = displayModeSlice.actions;
export default displayModeSlice.reducer;
