import { useMemo, useState } from 'react';
import { Identity } from '../types/identity';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { identityService } from '../services/identityService';
import { useAlert } from '../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { subscribedAccessService } from '../services/subscribedAccessService';
import { delay } from '../util/delay';

interface IPartialMember {
  id: Identity['id'];
  email: Identity['email'];
}

const useMembers = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  const { auth0Id, subscriptionId, source } = useParams();
  const ownerIdentityIdParam = useMemo(() => decodeURI(auth0Id as string), [auth0Id]);
  const subscriptionIdParam = useMemo(() => decodeURI(subscriptionId as string), [subscriptionId]);
  const sourceParam = useMemo(() => decodeURI(source as string), [source]);

  const isMissingParam = useMemo(
    () => !ownerIdentityIdParam || !subscriptionIdParam || !sourceParam,
    [ownerIdentityIdParam, subscriptionIdParam, sourceParam]
  );

  const handleError = useErrorHandler();
  const [members, setMembers] = useState<Identity[]>([]);
  const [selectedMember, setSelectedMember] = useState<IPartialMember | null>(null);
  const [loading, setLoading] = useState(false);

  const getMembers = async () => {
    const getMembersRequest = async () =>
      await identityService
        .getMembers(ownerIdentityIdParam, subscriptionIdParam, sourceParam)
        .then((identities) => setMembers(identities), handleError);
    await handleRequest(getMembersRequest);
  };

  const addMember = async (email: string) => {
    const addMemberRequest = async () =>
      await subscribedAccessService
        .addMemberToMultiAccountSubscription(
          ownerIdentityIdParam,
          email,
          subscriptionIdParam,
          sourceParam
        )
        .then(() => delay(1500))
        .then(() => getMembers(), handleError);
    await handleRequest(addMemberRequest);
  };

  const removeMember = async (memberId: string) => {
    const removeMemberRequest = async () =>
      await subscribedAccessService
        .removeMemberFromMultiAccountSubscription(
          ownerIdentityIdParam,
          memberId,
          subscriptionIdParam,
          sourceParam
        )
        .then(() => getMembers(), handleError);
    await handleRequest(removeMemberRequest);
  };

  const updateSelectedMember = async (
    memberId: IPartialMember['id'],
    memberEmail: IPartialMember['email']
  ) =>
    setSelectedMember({
      id: memberId,
      email: memberEmail,
    });

  const handleRequest = async (customFunction: () => void) => {
    if (isMissingParam) {
      alert.setErrorAlert(t('alertMessages.multiAccountSubscriptionMembers.onMissingParams'));
      return;
    }
    setLoading(true);
    await customFunction();
    setLoading(false);
  };

  return {
    members,
    getMembers,
    addMember,
    removeMember,
    selectedMember,
    setSelectedMember: updateSelectedMember,
    ownerIdentityIdParam,
    subscriptionIdParam,
    sourceParam,
    loading,
  };
};

export type MembersHook = typeof useMembers;
export type MembersHookResult = ReturnType<MembersHook>;
export default useMembers;
