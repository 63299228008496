export enum ALERT_STATUS {
  NONE = 'NONE',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface IAlertContext {
  alert: ALERT_STATUS;
  alertText: string | null;
  setSuccessAlert: (...args: any[]) => void;
  setErrorAlert: (...args: any[]) => void;
  clearAlert: () => void;
}
