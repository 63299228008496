import { PartialAzureToken } from '../providers/AuthProvider';

export enum CUSSUP_ROLES {
  CUSSUP_GENERAL = 'Cussup-GeneralSupport',
  CUSSUP_SPECIALIZED = 'Cussup-SpecializedSupport',
  CUSSUP_DATA_PROTECTION = 'Cussup-DataProtectionSupport',
  CUSSUP_SPECIALIZED_SUBSCRIPTION_SUPPORT = 'Cussup-SpecializedSubscriptionSupport',
  CUSSUP_FORBIDDEN = 'Cussup-Forbidden', // Role does not exist, use to block functionality altogether
}

export const EVERY_ROLE: CUSSUP_ROLES[] = Object.values(CUSSUP_ROLES);
export const SPECIALIZED_AND_DATA_PROTECTION: CUSSUP_ROLES[] = [
  CUSSUP_ROLES.CUSSUP_SPECIALIZED,
  CUSSUP_ROLES.CUSSUP_DATA_PROTECTION,
];
export const DATA_PROTECTION: CUSSUP_ROLES[] = [CUSSUP_ROLES.CUSSUP_DATA_PROTECTION];
export const FORBIDDEN: CUSSUP_ROLES[] = [CUSSUP_ROLES.CUSSUP_FORBIDDEN];

export enum SUBSCRIBED_ACCESS_ACTIONS {
  ADD_SUBSCRIBED_ACCESS = 'add-subscribed-access',
  EDIT_SUBSCRIBED_ACCESS = 'edit-subscribed-access',
}

export interface IAuthContext {
  handleLogin: () => void;
  handleLogout: () => void;
  getApiAccessToken: () => Promise<PartialAzureToken['accessToken'] | undefined>;
  roles: CUSSUP_ROLES[];
  customerSupportMail: string;
  isAuthenticated: boolean;
}

interface AuthorizedActions {
  action: string;
  roles: CUSSUP_ROLES[];
}

export const AuthorizedActionList: AuthorizedActions[] = [
  {
    action: SUBSCRIBED_ACCESS_ACTIONS.ADD_SUBSCRIBED_ACCESS,
    roles: EVERY_ROLE,
  },
  {
    action: SUBSCRIBED_ACCESS_ACTIONS.EDIT_SUBSCRIBED_ACCESS,
    roles: EVERY_ROLE,
  },
];
