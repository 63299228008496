import { BrandClient, BrandEntitlement, BrandGroupConfig } from '../types/brands';
import { apiService } from './apiService';

class BrandService {
  public async getBrandEntitlements(): Promise<BrandEntitlement[]> {
    return apiService.bffClient.get<BrandEntitlement[]>(`/brands/entitlements`).then((r) => r.data);
  }

  public async getBrandClientIds(): Promise<BrandClient[]> {
    return apiService.bffClient.get<BrandClient[]>(`/brands/clientIds`).then((r) => r.data);
  }

  public async getBrandGroups(): Promise<BrandGroupConfig[]> {
    return apiService.bffClient.get<BrandGroupConfig[]>(`/brand-groups`).then((r) => r.data);
  }
}

export const brandService = new BrandService();
