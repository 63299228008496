import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { useErrorHandler } from 'react-error-boundary';
import { brandService } from '../services/brandService';
import { validationService } from '../services/validationService';
import { loadBrandGroupConfig } from '../redux/reducers/brandGroupConfigReducer';
import { setBrandEntitlements } from '../redux/reducers/brandEntitlementsReducer';
import { setClients } from '../redux/reducers/clientsReducer';
import { setValidationProperties } from '../redux/reducers/validationPropertiesReducer';
import { useAuth } from '../providers/AuthProvider';

const useFetchExternalInfo = (): boolean => {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const { isAuthenticated, getApiAccessToken } = useAuth();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [fetchingExternalConfig, setFetchingExternalConfig] = useState(false);

  const fetchToken = useCallback(async () => {
    const fetchedToken = await getApiAccessToken();
    if (fetchedToken) {
      setToken(fetchedToken);
    }
  }, [getApiAccessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      setFetchingExternalConfig(true);
      fetchToken().catch(() => setFetchingExternalConfig(false));
    }
  }, [isAuthenticated, fetchToken]);

  const fetchExternalInfo = useCallback(
    async (token: string) => {
      const [brandGroups, entitlements, clientIds, validationProperties] = await Promise.all([
        brandService.getBrandGroups(),
        brandService.getBrandEntitlements(),
        brandService.getBrandClientIds(),
        validationService.getValidationProperties(token),
      ]);
      dispatch(loadBrandGroupConfig(brandGroups));
      dispatch(setBrandEntitlements(entitlements));
      dispatch(setClients(clientIds));
      dispatch(setValidationProperties(validationProperties));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAuthenticated && token) {
      setFetchingExternalConfig(true);
      fetchExternalInfo(token)
        .catch(handleError)
        .finally(() => setFetchingExternalConfig(false));
    }
  }, [token, handleError, isAuthenticated, fetchExternalInfo]);

  return fetchingExternalConfig;
};

export default useFetchExternalInfo;
