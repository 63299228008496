import React, { useCallback, useEffect, useState } from 'react';
import {
  useFetchCountriesSuggestions,
  useGetCountryIsoCode,
  useGetCountryName,
} from '../../hooks/useFetchCountriesSuggestions';
import { FormikProps } from 'formik';
import { AddressFormValues } from './FormikAddressFrom';
import DebouncedAutoComplete from '../WrapperComponents/DebouncedAutoComplete';
import { TextFieldError } from '../General/TextFieldError';
import { TextField } from '@mui/material';

export type CountryInputProps<T extends AddressFormValues> = {
  formik: FormikProps<T>;
  editModeEnabled: boolean;
  label: string;
  validationMessage?: string;
  onChange: (countryIso: string) => void;
};

export const CountryInput = <T extends AddressFormValues>({
  formik,
  editModeEnabled,
  label,
  validationMessage,
  onChange,
}: CountryInputProps<T>) => {
  const { values, setFieldValue } = formik;
  const fetchCountrySuggestions = useFetchCountriesSuggestions();
  const getCountryIsoCode = useGetCountryIsoCode();
  const onCountryChange = useCallback(
    async (name: string) => {
      setCountryName(name);
      const countryIso = await getCountryIsoCode(name);
      await setFieldValue('address.countryCode', countryIso);
      onChange(countryIso ?? '');
    },
    [getCountryIsoCode, setFieldValue, onChange]
  );

  const getCountryName = useGetCountryName();

  const [countryName, setCountryName] = useState('');
  useEffect(() => {
    if (values.address.countryCode) {
      getCountryName(values.address.countryCode).then((name) => setCountryName(name ?? ''));
    }
  }, []); /* (intentionally left empty) */ // eslint-disable-line react-hooks/exhaustive-deps

  return editModeEnabled ? (
    <DebouncedAutoComplete
      size="small"
      label={label}
      value={countryName}
      onChange={onCountryChange}
      fetchSuggestions={fetchCountrySuggestions}
      validationMessage={validationMessage}
      dataCy="CountryInput"
    />
  ) : (
    <TextField
      value={countryName}
      inputProps={{ 'aria-label': 'Country' }}
      variant="outlined"
      size="small"
      className="white_outlined uneditable"
      fullWidth
      required={false}
      label={values.address.countryCode ? null : 'Country'}
      error={!!validationMessage}
      helperText={<TextFieldError error={validationMessage} />}
      disabled={!editModeEnabled}
      InputLabelProps={{ shrink: false }}
      id="address.countryCode"
    />
  );
};
