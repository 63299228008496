import React from 'react';
import { Box, Toolbar } from '@mui/material';
import { Spinner } from './Spinner';
import AppRouter from '../../router/index';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBack } from '../../views/Error';
import { useLocation } from 'react-router-dom';

interface MainViewProps {
  loading: boolean;
}

const MainView: React.FC<MainViewProps> = ({ loading }) => {
  const location = useLocation();
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      {loading ? (
        <Spinner />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallBack} resetKeys={[location]}>
          <AppRouter />
        </ErrorBoundary>
      )}
    </Box>
  );
};

export default MainView;
