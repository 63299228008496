import dayjs from 'dayjs';

export type Order = 'asc' | 'desc';

export type Sorting<T> = {
  orderBy: keyof T | undefined;
  order: Order;
};

export function descendingObjectComparator<T>(a: T, b: T, orderBy: keyof T | undefined) {
  if (!orderBy) return 0; // no sorting
  if (a[orderBy] < b[orderBy]) return 1;
  if (a[orderBy] > b[orderBy]) return -1;
  return 0;
}

export function dateStringComparator(a: string | undefined | null, b: string | undefined | null) {
  if (!a && !b) return 0; // null dates are equal
  if (!a || (a && b && dayjs(a).isBefore(dayjs(b)))) return 1;
  if (!b || (a && b && dayjs(a).isAfter(dayjs(b)))) return -1;
  return 0;
}
