import React, { FC, ReactNode } from 'react';
import { TableCell } from '@mui/material';

interface StyledTableCellProps {
  children?: ReactNode;
  label: string;
  style?: React.CSSProperties;
}

const StyledTableCell: FC<StyledTableCellProps> = ({ children, label, style }) => {
  return (
    <TableCell
      style={{
        maxWidth: '10em',
        maxHeight: '2em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        ...style,
      }}
      data-cy={'cell_' + label}>
      {children}
    </TableCell>
  );
};

export default StyledTableCell;
