import React, { FC, useState } from 'react';
import { ExpandableEditableRow } from './ExpandableRow';
import { GenericExpandableTableComponentProps } from './ExpandedTableComponent';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';
import { Collapse, TableCell, TableRow } from '@mui/material';
import { HeadCell } from '../../../types/table';

interface ExpandableTableBodyProps<T> {
  data: T[];
  rowCellConfig: TooltipCellConfig<T>[];
  isExpandable?: (row: T) => boolean;
  isEditable?: (row: T) => boolean;
  handleEditClick?: (row: T, index: number) => void;
  GenericExpandableTableComponent: FC<GenericExpandableTableComponentProps<T>>;
  headCells: HeadCell<T>[];
  rowConfig: TooltipCellConfig<T>[];
}

const ExpandableTableBody = <T,>({
  data,
  rowCellConfig,
  isExpandable,
  isEditable,
  handleEditClick,
  GenericExpandableTableComponent,
  headCells,
  rowConfig,
}: ExpandableTableBodyProps<T>) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const handleRowClick = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  return (
    <>
      {data?.map((row, index) => (
        <React.Fragment key={index}>
          {/* Subscribed access rows */}
          <ExpandableEditableRow
            row={row}
            index={index}
            isExpendable={isExpandable}
            isEditable={isEditable}
            rowCellConfig={rowCellConfig}
            expandedRows={expandedRows}
            handleRowClick={handleRowClick}
            handleEditClick={handleEditClick}
          />

          {/* Expandable entitlement component */}

          <TableRow style={{ background: '#F0F0F0' }}>
            <TableCell
              colSpan={10}
              sx={expandedRows.includes(index) ? undefined : { padding: '0' }}>
              <Collapse in={expandedRows.includes(index)}>
                <GenericExpandableTableComponent
                  data={row}
                  handleEdit={handleEditClick}
                  headCells={headCells}
                  rowConfig={rowConfig}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  );
};

export default ExpandableTableBody;
