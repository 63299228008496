import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const ErrorOverlayDataGrid: FC = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" my={1} spacing={1}>
      <ErrorOutlineIcon fontSize="small" color="primary" />
      <Typography color="primaryBase.main" variant="body2">
        An error occured, data could not be loaded.
      </Typography>
    </Stack>
  );
};
