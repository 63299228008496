import React from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import NavigationListItem from './NavigationListItem';
import { ROUTES } from '../../router/routes';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

interface MultiAccountNavigationSectionProps {
  open: boolean;
  onToggle: () => void;
}

const MultiAccountNavigationSection: React.FC<MultiAccountNavigationSectionProps> = ({
  open,
  onToggle,
}) => {
  return (
    <div>
      <ListItemButton onClick={onToggle} data-cy={'btn_multi_account'}>
        <ListItemIcon>
          <ShareOutlinedIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText primary="Multi Account" />
        {open ? (
          <ExpandMoreOutlinedIcon fontSize="medium" />
        ) : (
          <ExpandLessOutlinedIcon fontSize="medium" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavigationListItem
            route={ROUTES.SEARCH_MA}
            Icon={SearchOutlinedIcon}
            text={'Search multi account'}
            sx={{ paddingLeft: 4 }}
            label={'btn_search_multi_account'}
            data-cy={'btn_search_multi_account'}
          />
        </List>
      </Collapse>
    </div>
  );
};

export default MultiAccountNavigationSection;
