import { apiService, IApiService } from './apiService';

export enum AddressServiceLanguage {
  NL = 'NL',
  FR = 'FR',
}

export type AutoCompleteCitiesProps = {
  countryISO: string;
  language: AddressServiceLanguage;
  limit?: number;
  key: string;
};

export type AutoCompleteCitiesResponse = {
  Cities: Array<{
    CityId: number;
    PostalCode: string;
    Name: string;
  }>;
  Information: {
    EncounteredError: boolean;
    Messages: Array<{
      Code: string;
      MessageText: string;
      ErrorType: number;
    }>;
  };
};

export type AutoCompleteStreetsProps = {
  countryISO: string;
  language: AddressServiceLanguage;
  postalCode: string;
  limit?: number;
  key: string;
};

export type AutoCompleteStreetsResponse = {
  Streets: Array<{
    Name: string;
    StreetId: number;
  }>;
  Information: {
    EncounteredError: boolean;
    Messages: Array<{
      Code: string;
      MessageText: string;
      ErrorType: number;
    }>;
  };
};

export type ValidateAddressProps = {
  addressToValidate: {
    street: {
      name: string;
    };
    city: {
      name: string;
      postalCode: string;
    };
    houseNumber: string;
    countryISOCode: string;
  };
};

export type ValidateAddressResponse = {
  ValidationResult: {
    IsValid: boolean;
    ValidationPerField: Array<{
      Key: 'city' | 'street' | 'postalCode' | 'country' | 'number';
      Value: boolean;
    }>;
    ErrorMessage: string | null;
    Information: {
      EncounteredError: boolean;
      Messages: Array<{
        Code: string;
        MessageText: string;
        ErrorType: number;
      }>;
    };
  };
  Information: {
    EncounteredError: boolean;
    Messages: Array<{
      Code: string;
      MessageText: string;
      ErrorType: number;
    }>;
  };
};

export type GetCountriesProps = {
  language: AddressServiceLanguage;
};

export type GetCountriesResponse = [
  {
    IsoCode: string;
    Name: string;
  },
];

export type ResolveDutchAddressProps = {
  postCode: string;
  houseNumber: number;
};

export type ResolveDutchAddressResponse = {
  Address: {
    Street: {
      Name: string;
    };
  };
};

export interface IAddressService {
  autoCompleteCities(props: AutoCompleteCitiesProps): Promise<AutoCompleteCitiesResponse>;

  autoCompleteStreets(props: AutoCompleteStreetsProps): Promise<AutoCompleteStreetsResponse>;

  validateAddress(props: ValidateAddressProps): Promise<ValidateAddressResponse>;

  getCountries(props: GetCountriesProps): Promise<GetCountriesResponse>;

  resolveStreetFromPostcode(props: ResolveDutchAddressProps): Promise<ResolveDutchAddressResponse>;
}

class AddressService implements IAddressService {
  private getRelativeAddressAutoCompleteUrl = (countryISO: string): string =>
    `/Address/AutoComplete/Cities/${countryISO}?api-version=2.0`;

  private getRelativeStreetAutoCompleteUrl = (countryISO: string): string =>
    `/Address/AutoComplete/Streets/${countryISO}?api-version=2.0`;

  private getRelativeAddressValidateUrl = (): string => '/Address/Validate?api-version=1.1';

  private getRelativeAddressCountriesUrl = (lng: string): string =>
    `/Countries/Language/${lng}?api-version=2.0`;

  private getRelativeDutchAddressUrl = (): string => '/Address/ResolveDutchAddress?api-version=1.0';

  private apiService: IApiService;

  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async autoCompleteCities(props: AutoCompleteCitiesProps): Promise<AutoCompleteCitiesResponse> {
    const { countryISO, ...requestBody } = props;
    const response = await this.apiService.addressClient.post<AutoCompleteCitiesResponse>(
      this.getRelativeAddressAutoCompleteUrl(countryISO),
      { ...requestBody }
    );
    return response.data;
  }

  async autoCompleteStreets(props: AutoCompleteStreetsProps): Promise<AutoCompleteStreetsResponse> {
    const { countryISO, ...requestBody } = props;
    const response = await this.apiService.addressClient.post<AutoCompleteStreetsResponse>(
      this.getRelativeStreetAutoCompleteUrl(countryISO),
      { ...requestBody }
    );
    return response.data;
  }

  async validateAddress(props: ValidateAddressProps): Promise<ValidateAddressResponse> {
    const response = await this.apiService.addressClient.post<ValidateAddressResponse>(
      this.getRelativeAddressValidateUrl(),
      {
        ...props,
      }
    );
    return response.data;
  }

  async getCountries(props: GetCountriesProps): Promise<GetCountriesResponse> {
    const response = await this.apiService.addressClient.get<GetCountriesResponse>(
      this.getRelativeAddressCountriesUrl(props.language)
    );
    return response.data;
  }

  async resolveStreetFromPostcode(
    props: ResolveDutchAddressProps
  ): Promise<ResolveDutchAddressResponse> {
    const response = await this.apiService.addressClient.post<ResolveDutchAddressResponse>(
      this.getRelativeDutchAddressUrl(),
      { houseNumber: props.houseNumber, postalCode: props.postCode }
    );
    return response.data;
  }
}

export const addressService = new AddressService(apiService);
