import { useSearchParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useMemo } from 'react';

export function useTypedSearchParams<T>(): [T, Dispatch<SetStateAction<T>>] {
  const [searchParams, setSearchParams] = useSearchParams();
  // memo is used here to have a computed value of searchParms so that it does not trigger an infinite loop.
  // return Object.fromEntries directly and using it to listen to changes in searchParams will cause an infinite loop.
  const queryParamsObject = useMemo<T>(
    () => Object.fromEntries(searchParams.entries()) as T,
    [searchParams]
  );
  return [queryParamsObject, setSearchParams] as unknown as [T, Dispatch<SetStateAction<T>>];
}
