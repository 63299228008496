import React, { FC } from 'react';
import SignInButton from '../../components/SignInButton';
import { Box, Stack, Typography } from '@mui/material';

export const Login: FC = () => {
  return (
    <Stack direction="column">
      <Typography variant="MHHeading">CIAM Customer Support</Typography>
      <Typography variant="MHCaption" mb={3}>
        Log in with Microsoft to gain access to the application.
      </Typography>
      <Box>
        <SignInButton />
      </Box>
    </Stack>
  );
};
