import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface NavigationListItemProps {
  route: string;
  Icon: typeof SvgIcon;
  text: string;
  sx?: React.CSSProperties;
  label: string;
}

const NavigationListItem: React.FC<NavigationListItemProps> = ({
  route,
  Icon,
  text,
  sx,
  label,
}) => {
  const navigate = useNavigate();

  return (
    <ListItemButton onClick={() => navigate(route)} sx={sx} data-cy={label}>
      <ListItemIcon>
        <Icon fontSize="medium" />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default NavigationListItem;
