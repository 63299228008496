import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { HeadCell } from '../../../types/table';

interface CustomTableHeadProps<T> {
  headCells: readonly HeadCell<T>[];
}

const CustomTableHead = <T,>({ headCells }: CustomTableHeadProps<T>): JSX.Element => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              paddingLeft: '0.5rem',
              paddingRight: 0,
            }}>
            {headCell.label}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
