import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IAppConfig } from '../../types/app-config';

type PickIAppConfig = Pick<IAppConfig, 'bffUrl'>;

// Pick: Restrict data passed to Redux to prevent security breaches
const initialState: PickIAppConfig = { bffUrl: '' };

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    loadConfig: (state, action: PayloadAction<PickIAppConfig>) => {
      return action.payload;
    },
  },
});

export const selector = (state: RootState) => state.appConfig;
export const { loadConfig } = appConfigSlice.actions;
export default appConfigSlice.reducer;
