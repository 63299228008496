import React, { ChangeEvent, FC } from 'react';
import { Field, FieldInputProps, FormikTouched, getIn } from 'formik';
import { Box, InputLabel, TextField, Typography, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface FormikTextFieldProps {
  name: string;
  formikId: string;
  label?: string;
  optionalLabel?: string;
  placeholder?: string;
  errors?: Record<string, string>;
  touched?: FormikTouched<Record<string, string>>;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  preventWhitespace?: boolean;
}

// Chameleon TextField wrapped with Formik helpers
export const FormikTextField: FC<FormikTextFieldProps> = ({
  name,
  formikId,
  label = '',
  optionalLabel = '',
  placeholder = '',
  errors,
  touched,
  setFieldValue,
  preventWhitespace = false,
}) => {
  return (
    // Formik Field component to be used as a part of Formik Form component
    <Field name={name}>
      {({ field }: { field: FieldInputProps<string> }) => (
        <Box>
          <InputLabel htmlFor={formikId}>
            <Box display="flex" alignItems="center" gap="5px">
              <Typography variant="system" fontWeight="bold">
                {label}
              </Typography>
              {!!optionalLabel && (
                <Tooltip title={optionalLabel}>
                  <HelpIcon fontSize="inherit" color="disabled" />
                </Tooltip>
              )}
            </Box>
          </InputLabel>
          <TextField
            {...field}
            hiddenLabel
            id={formikId}
            color="brandBlack"
            placeholder={placeholder}
            error={getIn(errors, name) && getIn(touched, name)}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue(
                name,
                preventWhitespace ? event.target.value.trim() : event.target.value
              )
            }
          />
          {!!(getIn(errors, name) && getIn(touched, name)) && (
            <Typography
              variant="caption"
              color="error"
              display="flex"
              alignItems="center"
              position="absolute"
              pt={0.5}>
              <ErrorOutlineOutlinedIcon fontSize="small" fontWeight="small" sx={{ mr: 0.5 }} />
              {getIn(errors, name)}
            </Typography>
          )}
        </Box>
      )}
    </Field>
  );
};
