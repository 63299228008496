import { InvalidityReason } from '../util/subscribedAccessUtils';

export interface IEntitlement {
  brandCode: string;
  code: string;
  isActive: boolean;
  invalidityReason?: InvalidityReason;
  validFrom?: string;
  validTo?: string;
}

export interface ISubscribedAccess {
  eventType: string;
  createdOn: string;
  endDate?: string;
  identityId: string;
  email: string;
  importedBy: string;
  orderId: string;
  entitlements: IEntitlement[];
  source: string;
  startDate: string;
  subscriptionId: string;
  manualSubscriptionId: string;
  subscriptionType: string;
  status: string;
  total: number;
}

export type UpdateSubscribedAccess = Pick<ISubscribedAccess, 'status' | 'entitlements'> & {
  defaultValidFrom: string;
  defaultValidTo: string;
};

export type IEditSubscribedAccess = {
  endDate?: string;
  entitlements: IEntitlement[];
  status: string;
  importedBy: string;
  modificationTimeStamp: string;
};

export type IEditManualSubscribedAccess = IEditSubscribedAccess & {
  startDate?: string;
  manualSubscriptionId?: string;
};

export type IEditSubscriptionBasedSubscribedAccess = IEditSubscribedAccess & {
  startDate: string;
  source: string;
  subscriptionId: string;
  subscriptionType: string;
};

export type EntitlementCode = {
  brandCode: string;
  code: string;
};

export type IFlattenedSubscribedAccess = ISubscribedAccess & {
  entitlementCodes: EntitlementCode[];
};

export enum SUBSCRIBED_ACCESS_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum SUBSCRIBED_ACCESS_POPUP_TYPE {
  ADD = 'Add',
  EDIT = 'Edit',
}

export enum SUBSCRIBED_ACCESS_SOURCE {
  FROM_CUSSUP_EMPLOYEE = 'MANUAL',
}

export enum SUBSCRIBED_ACCESS_FIELDS {
  EVENT_TYPE = 'eventType',
  BRAND_CODE = 'brandCode',
  CREATED_ON = 'createdOn',
  END_DATE = 'endDate',
  IDENTITY_ID = 'identityId',
  EMAIL = 'email',
  ROLE = 'role',
  IMPORTED_BY = 'importedBy',
  ORDER_ID = 'orderId',
  CODE = 'code',
  SOURCE = 'source',
  START_DATE = 'startDate',
  SUBSCRIPTION_ID = 'subscriptionId',
  MANUAL_SUBSCRIPTION_ID = 'manualSubscriptionId',
  STATUS = 'status',
  SUBSCRIPTION_TYPE = 'subscriptionType',
}

export enum SUBSCRIBED_ACCESS_TAB_VALUES {
  CURRENT = 'Current',
  HISTORY = 'History',
}

export enum ROLES {
  OWNER = 'Owner',
  MEMBER = 'Member',
}
