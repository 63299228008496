import { formatDateString } from '../../../util/utils';
import React, { CSSProperties, useMemo } from 'react';
import {
  getEarliestValidFrom,
  getEntitlementCodes,
  getLatestValidTo,
  isHighlightedEntitlement,
} from '../../../util/subscribedAccessUtils';
import { Box, Icon, Typography } from '@mui/material';
import {
  EntitlementCode,
  IFlattenedSubscribedAccess,
  ISubscribedAccess,
} from '../../../types/subscribed-access';
import { Cancel, Check, Warning } from '@mui/icons-material';
import { HtmlTooltip } from '../../General/Reusable/HtmlTooltip';

export interface TooltipCellConfig<T> {
  title: string;
  propertyKey: keyof T;
  render?: (value: T, key: keyof T) => React.ReactNode;
  style?: (value: any) => CSSProperties;
  width?: number;
  showTooltip?: boolean;
}

function uniqueFilter(value: unknown, index: number, self: unknown[]) {
  return self.indexOf(value) === index;
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[]>
  );

const SAStatusIcon = (value: ISubscribedAccess) => {
  const hasInactiveEntitlements = value.entitlements?.some((e) => isHighlightedEntitlement(e));
  const hasNoActiveEntitlements = value.entitlements?.every((e) => isHighlightedEntitlement(e));

  if (hasInactiveEntitlements) {
    return (
      <div style={{ color: 'warning' }}>
        <HtmlTooltip
          placement="bottom-start"
          title={
            hasNoActiveEntitlements ? (
              <Typography color="inherit">All entitlements are inactive</Typography>
            ) : (
              <>
                Has entitlements that are <em>not</em> applicable at this time
              </>
            )
          }
          style={{ color: hasNoActiveEntitlements ? 'error' : 'warning' }}>
          <Icon color={hasNoActiveEntitlements ? 'error' : 'warning'}>
            {(hasNoActiveEntitlements && <Cancel />) || <Warning />}
          </Icon>
        </HtmlTooltip>
      </div>
    );
  } else {
    return (
      <HtmlTooltip
        placement="bottom-start"
        title={<Typography color="inherit">All entitlements are applicable</Typography>}>
        <Icon>
          <Check></Check>
        </Icon>
      </HtmlTooltip>
    );
  }
};

const renderEntitlementCodes = (value: ISubscribedAccess) => {
  return (
    <Box display="flex" gap={1} flexGrow="1">
      {SAStatusIcon(value)}
      <Box display="flex" gap={1} flexDirection="column" flexGrow="1">
        {Object.values(groupBy(getEntitlementCodes(value), (c) => c.brandCode)).map(
          (codes: EntitlementCode[], index) => (
            <Box key={index} justifyContent="space-between" display="flex" alignItems="center">
              <div style={{ padding: '0 1em', alignSelf: 'start' }}>{codes[0].brandCode}</div>
              <div style={{ padding: '0 1em' }}>
                {useMemo(
                  () =>
                    codes
                      .map((c) => c.code)
                      .filter(uniqueFilter)
                      .map((c, index) => {
                        return (
                          <div key={index} style={{ textAlign: 'end' }}>
                            {c}
                          </div>
                        );
                      }),
                  [codes]
                )}
              </div>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
const renderStartDate = (value: ISubscribedAccess) => {
  const earliestValidFrom = getEarliestValidFrom(value);
  return formatDateString(earliestValidFrom ?? '');
};

const renderEndDate = (value: ISubscribedAccess) => formatDateString(getLatestValidTo(value) ?? '');
export const subscribedAccessCellConfig: TooltipCellConfig<IFlattenedSubscribedAccess>[] = [
  {
    title: 'Entitlement Codes',
    propertyKey: 'entitlementCodes',
    render: renderEntitlementCodes,
    width: 1.6,
    showTooltip: false,
  },
  { title: 'Source', propertyKey: 'source', width: 0.8, showTooltip: false },
  { title: 'Subscription ID', propertyKey: 'subscriptionId', width: 1.3 },
  { title: 'Order ID', propertyKey: 'orderId', width: 1.3 },
  { title: 'Start date', propertyKey: 'startDate', render: renderStartDate, width: 1.2 },
  { title: 'End date', propertyKey: 'endDate', render: renderEndDate, width: 1.2 },
  { title: 'Imported by', propertyKey: 'importedBy', width: 2 },
];
