import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BrandClient } from '../../types/brands';

const initialState: BrandClient[] = [];

const clientsReducer = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<BrandClient[]>) => {
      return action.payload;
    },
  },
});

export const selector = (state: RootState) => state.clients;
export const { setClients } = clientsReducer.actions;
export default clientsReducer.reducer;
