import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import { Sorting } from '../../../util/sortingUtils';
import { HeadCell } from '../../../types/table';

interface TableHeadWithSortingProps<T> {
  headCells: readonly HeadCell<T>[];
  sorting: Sorting<T>;
  onSortingChange: (sorting: Sorting<T>) => void;
}

const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
};

const TableHeadWithSorting = <T,>({
  headCells,
  sorting,
  onSortingChange,
}: TableHeadWithSortingProps<T>): JSX.Element => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            sortDirection={headCell.key && sorting.orderBy === headCell.key && sorting.order}
            style={{ fontWeight: 'bold', paddingLeft: '0.5rem', paddingRight: 0 }}>
            {headCell.key && (
              <Box justifyContent="space-between" display="flex" alignItems="center">
                <TableSortLabel
                  active={sorting.orderBy === headCell.key}
                  direction={sorting.orderBy === headCell.key ? sorting.order : 'asc'}
                  onClick={() =>
                    onSortingChange({
                      orderBy: headCell.key!,
                      order: sorting.order === 'desc' ? 'asc' : 'desc',
                    })
                  }>
                  {headCell.label}
                  {sorting.orderBy === headCell.key ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sorting.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                {index < headCells.length - 1 && headCells[index + 1].label?.length ? (
                  <div style={{ width: '1.6px', height: '1rem', backgroundColor: '#cecece' }} />
                ) : null}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadWithSorting;
