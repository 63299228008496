import React, { FC, useEffect, useState } from 'react';
import { IConsent, USER_CONSENT_FIELDS } from '../types/consent';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ErrorOverlayDataGrid } from './ErrorOverlayDataGrid';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GridTooltip } from './General/GridTooltip';
import { IdentityHookResult } from '../hooks/useIdentity';

interface UserConsentDataGridProps {
  identityHook: IdentityHookResult;
}

const UserConsentDataGrid: FC<UserConsentDataGridProps> = ({ identityHook }) => {
  const { identity } = identityHook;
  const [consents, setConsents] = useState<IConsent[]>([]);

  useEffect(() => {
    if (!identity) return;
    identity.consents && setConsents(identity.consents);
  }, []);

  const columns: GridColDef[] = [
    {
      field: USER_CONSENT_FIELDS.CONSENT_ID,
      headerName: 'Consent Id',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: USER_CONSENT_FIELDS.CONSENTED_ON,
      headerName: 'Consented On',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const date = new Date(params.row.consentedOn);
        return `${params.row.consentedOn ? date.toLocaleString(undefined, { hour12: false }) : ''}`;
      },
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
  ];

  return (
    <>
      {identity && (
        <DataGrid
          autoHeight
          columns={columns}
          components={{
            ErrorOverlay: ErrorOverlayDataGrid,
          }}
          getRowId={() => Math.floor(Math.random() * 10000)}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 50]}
          rows={consents}
          sx={{
            backgroundColor: 'white',
          }}
        />
      )}
    </>
  );
};

export default UserConsentDataGrid;
