import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IValidationProperties } from '../../types/validation';

const initialState: IValidationProperties = {} as IValidationProperties;

const validationPropertiesSlice = createSlice({
  name: 'validationProperties',
  initialState,
  reducers: {
    setValidationProperties: (state, action: PayloadAction<IValidationProperties>) => {
      return action.payload;
    },
  },
});

export const selector = (state: RootState) => state.validationProperties;
export const { setValidationProperties } = validationPropertiesSlice.actions;
export default validationPropertiesSlice.reducer;
