import { ITypeAhead } from '../types/type-ahead';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const useBrandGroupEntitlements = () => {
  const brandEntitlements = useSelector((state: RootState) => state.brandEntitlements);
  const brandGroups = useSelector((state: RootState) => state.brandGroupConfig);

  function getHumanReadableBrandFromBrandCode(brandCode: string | undefined) {
    if (!brandCode) return 'None';

    const brands = brandGroups.flatMap((brandGroup) => brandGroup.brands);
    const brandName = brands.find((brand) => brand.id === brandCode)?.name;

    if (brandName) return `${brandName} (${brandCode})`;
    return brandCode;
  }

  function getBrandOptionList(): ITypeAhead[] {
    return brandEntitlements.map((element) => {
      return {
        label: getHumanReadableBrandFromBrandCode(element.brandCode),
        key: element.brandCode,
      } as ITypeAhead;
    });
  }

  function isKnownBrand(code?: string): boolean {
    if (code) {
      const brand = brandEntitlements?.find((be) => be.brandCode === code);
      return !!(brand && brand.entitlements);
    }
    return false;
  }
  function isKnownBrandCode(brandCode?: string, code?: string): boolean {
    if (brandCode && code) {
      const brand = brandEntitlements?.find((be) => be.brandCode === brandCode);
      if (brand) return brand.entitlements.includes(code);
    }
    return false;
  }

  function getEntitlementOptionList(code?: string): ITypeAhead[] {
    if (isKnownBrand(code)) {
      return (
        brandEntitlements
          .find((be) => be.brandCode === code)
          ?.entitlements.map((entitlement) => {
            return {
              label: entitlement,
              key: entitlement,
            } as ITypeAhead;
          }) || []
      );
    }
    return [] as ITypeAhead[];
  }

  return {
    getHumanReadableBrandFromBrandCode,
    getBrandOptionList,
    getEntitlementOptionList,
    isKnownBrand,
    isKnownBrandCode,
  };
};

export type BrandGroupEntitlementsHook = typeof useBrandGroupEntitlements;
export type BrandGroupEntitlementsResult = ReturnType<BrandGroupEntitlementsHook>;
export default useBrandGroupEntitlements;
