import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { ALERT_STATUS, IAlertContext } from '../types/alert';

const AlertContext = createContext<IAlertContext>({
  alert: ALERT_STATUS.NONE,
  alertText: null,
  setSuccessAlert: () => {},
  setErrorAlert: () => {},
  clearAlert: () => {},
});

const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alert, setAlert] = useState<ALERT_STATUS>(ALERT_STATUS.NONE);
  const [alertText, setAlertText] = useState<IAlertContext['alertText']>(null);
  const [currentTimeout, setCurrentTimeout] = useState<number | null>();

  const clearTimeOut = () => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }
  };

  const setSuccessAlert = (text: string, timeout: number) => {
    setAlertText(text);
    setAlert(ALERT_STATUS.SUCCESS);
    clearTimeOut();
    setCurrentTimeout(
      setTimeout(
        () => {
          clearAlert();
        },
        timeout * 1000 || 10000
      ) as unknown as number
    );
  };

  const setErrorAlert = (text: string, timeout: number) => {
    setAlertText(text);
    setAlert(ALERT_STATUS.ERROR);
    clearTimeOut();
    setCurrentTimeout(
      setTimeout(
        () => {
          clearAlert();
        },
        timeout * 1000 || 10000
      ) as unknown as number
    );
  };

  const clearAlert = () => {
    setAlert(ALERT_STATUS.NONE);
    setAlertText(null);
    clearTimeOut();
  };

  return (
    <AlertContext.Provider value={{ alert, alertText, setSuccessAlert, setErrorAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);
export { AlertProvider, useAlert };
