import { Field, FieldInputProps, FieldMetaProps, FormikErrors, FormikProps } from 'formik';
import { Grid, TextField, Typography } from '@mui/material';
import { TextFieldError } from '../General/TextFieldError';
import React, { useMemo } from 'react';
import { CountryInput } from './CountryInput';

export type AddressFormValues = {
  address: {
    street?: string;
    houseNumber?: string;
    box?: string | undefined;
    city: string;
    postalCode: string;
    countryCode: string;
  };
};

interface FormikAddressFormProps<T extends AddressFormValues> {
  formikProps: FormikProps<T>;
  editModeEnabled: boolean;
  onCountryChange: (value: string) => void;
}

export const FormikAddressFrom = <T extends AddressFormValues>({
  formikProps,
  editModeEnabled,
  onCountryChange,
}: FormikAddressFormProps<T>) => {
  const { errors } = formikProps;
  const countryError = useMemo(
    () => (errors as FormikErrors<AddressFormValues>).address?.countryCode,
    [errors]
  );
  return (
    <>
      <Grid item xs={4} display="flex">
        <Typography variant="MHCaption" my="auto">
          Street
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Field name="address.street">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              id="address.street"
              inputProps={{ 'aria-label': 'Street' }}
              variant="outlined"
              size="small"
              className="white_outlined uneditable"
              fullWidth
              required={false}
              label={meta.value ? null : 'Street'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={!editModeEnabled}
              InputLabelProps={{ shrink: false }}
              {...field}
            />
          )}
        </Field>
      </Grid>

      <Grid item xs={4} display="flex">
        <Typography variant="MHCaption" my="auto">
          House & bus-number
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Field name="address.houseNumber">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              inputProps={{ 'aria-label': 'House-number' }}
              variant="outlined"
              size="small"
              className="white_outlined uneditable"
              fullWidth
              required={false}
              label={meta.value ? null : 'House number'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={!editModeEnabled}
              InputLabelProps={{ shrink: false }}
              {...field}
              name="address.houseNumber"
              id="address.houseNumber"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={4}>
        <Field name="address.houseNumberExtension">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              id="address.houseNumberExtension"
              inputProps={{ 'aria-label': 'Bus-number' }}
              variant="outlined"
              size="small"
              className="white_outlined uneditable"
              fullWidth
              required={false}
              label={meta.value ? null : 'Extension'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={!editModeEnabled}
              InputLabelProps={{ shrink: false }}
              {...field}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={4} display="flex">
        <Typography variant="MHCaption" my="auto">
          Postal code & city
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Field name="address.postalCode">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              id="address.postalCode"
              inputProps={{ 'aria-label': 'Postal code' }}
              variant="outlined"
              size="small"
              className="white_outlined uneditable"
              fullWidth
              required={false}
              label={meta.value ? null : 'Postal code'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={!editModeEnabled}
              InputLabelProps={{ shrink: false }}
              {...field}
            />
          )}
        </Field>
      </Grid>

      <Grid item xs={4}>
        <Field name="address.city">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              id="address.city"
              inputProps={{ 'aria-label': 'City' }}
              variant="outlined"
              size="small"
              className="white_outlined uneditable"
              fullWidth
              required={false}
              label={meta.value ? null : 'City'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={!editModeEnabled}
              InputLabelProps={{ shrink: false }}
              {...field}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={4} display="flex">
        <Typography variant="MHCaption" my="auto">
          Country
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <CountryInput
          formik={formikProps}
          label={''}
          editModeEnabled={editModeEnabled}
          onChange={onCountryChange}
          validationMessage={countryError}
        />
      </Grid>
    </>
  );
};
