import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

export const Spinner: FC = () => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};
