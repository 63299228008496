import { FormControl, FormControlLabel, Switch as MSwitch } from '@mui/material';
import React, { FC, useCallback } from 'react';

type SwitchProps = {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
};

export const Switch: FC<SwitchProps> = ({ label, checked, onChange }) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={<MSwitch color="info" checked={checked} onChange={handleChange} />}
        label={label}
        color="secondary"
        labelPlacement="end"
      />
    </FormControl>
  );
};
