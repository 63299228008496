import { renderDateString } from '../../../util/utils';
import { TooltipCellConfig } from '../SubscribedAccessTable/SubscribedAccessRowCellConfig';
import {
  getEntitlementIsActiveCellStyle,
  getEntitlementValidFromCellStyle,
  getEntitlementValidToCellStyle,
} from '../../../util/subscribedAccessUtils';
import React from 'react';
import { Box, Chip } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import type { SvgIconComponent } from '@mui/icons-material';
import { IEntitlement } from '../../../types/subscribed-access';

const renderEntitlementStatus = (e: IEntitlement) => {
  const IsActiveIcon: SvgIconComponent = e.isActive ? CheckCircle : Cancel;
  return (
    <Box display={'flex'} alignItems={'center'} gap={1}>
      <IsActiveIcon />
      {e.invalidityReason && (
        <Chip
          size="small"
          color={e.invalidityReason === 'expired' ? 'warning' : 'info'}
          label={e.invalidityReason}
        />
      )}
    </Box>
  );
};

export const entitlementRowCellConfig: TooltipCellConfig<IEntitlement>[] = [
  { title: 'Brand Code', propertyKey: 'brandCode', width: 1, showTooltip: false },
  { title: 'Code', propertyKey: 'code', width: 1, showTooltip: false },
  {
    title: 'IsActive',
    propertyKey: 'isActive',
    render: renderEntitlementStatus,
    style: getEntitlementIsActiveCellStyle,
    width: 1,
    showTooltip: false,
  },
  {
    title: 'Valid From',
    propertyKey: 'validFrom',
    render: renderDateString,
    style: getEntitlementValidFromCellStyle,
    width: 2,
  },
  {
    title: 'Valid To',
    propertyKey: 'validTo',
    render: renderDateString,
    style: getEntitlementValidToCellStyle,
    width: 2,
  },
];
