import {
  EntitlementCode,
  IEntitlement,
  IFlattenedSubscribedAccess,
  ISubscribedAccess,
} from '../types/subscribed-access';
import dayjs from 'dayjs';
import { dateStringComparator, descendingObjectComparator } from './sortingUtils';

const warningColor = '#ff8800';
const infoColor = '#0288d1';

export type InvalidityReason = 'upcoming' | 'expired';
export const isInactive = (isActive?: boolean) => (isActive !== undefined ? !isActive : false);

export const isDateInFuture = (validFrom?: string) => {
  return validFrom ? new Date(validFrom) > new Date() : false;
};

export const isDateInPast = (dateString?: string): boolean => {
  return dateString ? new Date(dateString) < new Date() : false;
};

export function isHighlightedEntitlement(entitlement: IEntitlement) {
  return isInactiveEntitlement(entitlement.isActive, entitlement.validFrom, entitlement.validTo);
}

export function isInactiveEntitlement(isActive?: boolean, validFrom?: string, validTo?: string) {
  return isInactive(isActive) || isDateInFuture(validFrom) || isDateInPast(validTo);
}

export const getEntitlementIsActiveCellStyle = (isActive: boolean) => {
  return {
    color: isInactive(isActive) ? warningColor : 'inherit',
    fontWeight: isInactive(isActive) ? 'bold' : 'normal',
  };
};

export const getEarliestValidFrom = (data: ISubscribedAccess): string | null => {
  return data.entitlements.reduce(
    (acc, curr) => {
      if (acc === null || !curr.validFrom) return null; // if any validFrom is null, keep propagating null through
      if (acc === undefined || dayjs(curr.validFrom).isBefore(dayjs(acc))) {
        return curr.validFrom;
      }
      return acc;
    },
    undefined as unknown as string | null
  );
};

export const getLatestValidTo = (data: ISubscribedAccess): string | null => {
  return data.entitlements.reduce(
    (acc, curr) => {
      if (acc === null || !curr.validTo) return null; // if any validTo is null, keep propagating null through
      if (acc === undefined || dayjs(curr.validTo).isAfter(dayjs(acc))) {
        return curr.validTo;
      }
      return acc;
    },
    undefined as unknown as string | null
  );
};

export const getEntitlementValidFromCellStyle = (validFrom: string) => {
  return {
    color: isDateInFuture(validFrom) ? infoColor : 'inherit',
    fontWeight: isDateInFuture(validFrom) ? 'bold' : 'normal',
  };
};

export const getEntitlementValidToCellStyle = (validTo: string) => {
  return {
    color: isDateInPast(validTo) ? warningColor : 'inherit',
    fontWeight: isDateInPast(validTo) ? 'bold' : 'normal',
  };
};

export const getEntitlementRowStyle = (isHighlighted: boolean) => {
  return {
    backgroundColor: isHighlighted ? '#dcdcdc' : '#eaffe3',
    color: isHighlighted ? '#8d8d8d' : 'inherit',
    paddingLeft: '0.5rem',
  };
};

export function isExpandableSubscribedAccess(access: ISubscribedAccess) {
  return access.entitlements && access.entitlements.length > 0;
}

export function mapSubscribedAccessesToTableComponents(
  subscribedAccesses: ISubscribedAccess[]
): IFlattenedSubscribedAccess[] {
  return subscribedAccesses.map((access) => ({
    ...access,
    entitlementCodes: getEntitlementCodes(access),
    entitlements: access.entitlements.map((e) => {
      const invalidityReason = isDateInFuture(e.validFrom)
        ? 'upcoming'
        : isDateInPast(e.validTo)
          ? 'expired'
          : undefined;
      return {
        ...e,
        invalidityReason,
      };
    }),
  }));
}

export const getEntitlementCodes = (access: ISubscribedAccess): EntitlementCode[] => {
  return access.entitlements
    .filter((e) => !isInactiveEntitlement(e.isActive, e.validFrom, e.validTo))
    .map((e) => {
      return {
        brandCode: e.brandCode,
        code: e.code,
      };
    });
};

export function mapSubscribedAccessesHistoryToTableComponents(
  subscribedAccesses: ISubscribedAccess[]
): IFlattenedSubscribedAccess[] {
  return subscribedAccesses.map((access) => ({
    ...access,
    entitlementCodes: getEntitlementCodes(access),
  }));
}

const entitlementsByFirstBrandCodeComparator = (
  a: IFlattenedSubscribedAccess,
  b: IFlattenedSubscribedAccess
): -1 | 0 | 1 => {
  const getFirstBrandCode = (x: IFlattenedSubscribedAccess) =>
    x && x.entitlementCodes && x.entitlementCodes.length > 0 ? x.entitlementCodes[0].brandCode : '';

  const [aBrand, bBrand] = [a, b].map(getFirstBrandCode);
  if (aBrand < bBrand) return 1;
  if (aBrand > bBrand) return -1;
  return 0;
};

export const subscribedAccessComparator = (
  a: IFlattenedSubscribedAccess,
  b: IFlattenedSubscribedAccess,
  orderBy: keyof IFlattenedSubscribedAccess | undefined
): -1 | 0 | 1 => {
  switch (orderBy) {
    case 'startDate':
      return dateStringComparator(getEarliestValidFrom(a), getEarliestValidFrom(b));
    case 'endDate':
      return dateStringComparator(getLatestValidTo(a), getLatestValidTo(b));
    case 'entitlementCodes':
      return entitlementsByFirstBrandCodeComparator(a, b);
    default:
      return descendingObjectComparator(a, b, orderBy);
  }
};

export const entitlementsComparator = (
  a: IEntitlement,
  b: IEntitlement,
  orderBy: keyof IEntitlement | undefined
): -1 | 0 | 1 => {
  switch (orderBy) {
    case 'validFrom':
    case 'validTo':
      return dateStringComparator(a[orderBy], b[orderBy]);
    default:
      return descendingObjectComparator(a, b, orderBy);
  }
};
