import React from 'react';
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Grid,
  Typography,
} from '@mui/material';
import { Field, FieldInputProps, FieldMetaProps, FormikProps } from 'formik';
import { TextFieldError } from '../General/TextFieldError';
import { GenderCode, GENDER_CODE_MAP } from '../../types/gender';

export type GenderFormValues = {
  demographicsPerson: {
    genderCode: GenderCode;
    genderCustom?: string;
  };
};

interface GenderFieldProps<T extends GenderFormValues> {
  id: string;
  editModeEnabled: boolean;
  formikProps: FormikProps<T>;
}

export const GenderField = <T extends GenderFormValues>({
  id,
  editModeEnabled,
  formikProps,
}: GenderFieldProps<T>) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const { setFieldValue } = formikProps;
    setFieldValue('demographicsPerson.genderCode', event.target.value);
    setFieldValue('demographicsPerson.genderCustom', '');
  };

  return (
    <>
      <Grid item xs={4} display="flex">
        <Typography variant="MHCaption" my="auto">
          Gender
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Field name="demographicsPerson.genderCode">
          {({ field }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <FormControl id={id} variant="outlined" size="small" fullWidth>
              {editModeEnabled ? (
                <>
                  <Select value={field.value} onChange={handleChange} size="small">
                    {Object.entries(GENDER_CODE_MAP).map(([key, value]) => (
                      <MenuItem id={value} key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <TextField
                  id={id}
                  inputProps={{ 'aria-label': 'Gender' }}
                  variant="outlined"
                  size="small"
                  className="white_outlined"
                  fullWidth
                  required={false}
                  label={null}
                  disabled
                  InputLabelProps={{ shrink: false }}
                  value={GENDER_CODE_MAP[field.value as GenderCode] ?? 'Unknown'}
                />
              )}
            </FormControl>
          )}
        </Field>
      </Grid>
      <Grid item xs={4}>
        <Field name="demographicsPerson.genderCustom">
          {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
            <TextField
              id="demographicsPerson.genderCustom"
              inputProps={{ 'aria-label': 'Custom gender' }}
              variant="outlined"
              size="small"
              className="white_outlined"
              fullWidth
              required={false}
              label={meta.value ? null : 'Custom gender'}
              error={!!meta.error}
              helperText={<TextFieldError error={meta.error} />}
              disabled={
                !(editModeEnabled && formikProps.values.demographicsPerson.genderCode === 'o')
              }
              InputLabelProps={{ shrink: false }}
              {...field}
            />
          )}
        </Field>
      </Grid>
    </>
  );
};

export default GenderField;
