import React, { FC } from 'react';
import { ISubscribedAccess, SUBSCRIBED_ACCESS_FIELDS } from '../../types/subscribed-access';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ErrorOverlayDataGrid } from '../ErrorOverlayDataGrid';
import { GridTooltip } from '../General/GridTooltip';
import { useNavigate } from 'react-router-dom';
import { subscribedAccessService } from '../../services/subscribedAccessService';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

interface MultiAccountSubscribedAccessDataGridProps {
  subscribedAccesses: ISubscribedAccess[] | null;
  loading: boolean;
  error: boolean;
}

const MultiAccountSubscriptionDataGrid: FC<MultiAccountSubscribedAccessDataGridProps> = ({
  subscribedAccesses,
  loading,
  error,
}) => {
  const [internalLoading, setInternalLoading] = React.useState<boolean>(false);
  const accessFields = SUBSCRIBED_ACCESS_FIELDS;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const alert = useAlert();
  const columns: GridColDef[] = [
    {
      field: accessFields.SOURCE,
      headerName: 'Source',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: accessFields.SUBSCRIPTION_ID,
      headerName: 'Subscription ID',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: accessFields.IDENTITY_ID,
      headerName: 'User ID',
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: accessFields.EMAIL,
      headerName: 'E-mail',
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
    {
      field: accessFields.ROLE,
      headerName: 'Role',
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => <GridTooltip params={params} />,
    },
  ];

  async function navigateToMultiAccountDetail(subscriptionId: string, source: string) {
    setInternalLoading(true);
    try {
      const ownerId =
        await subscribedAccessService.getMultiAccountSubscriptionOwner(subscriptionId);
      if (ownerId) {
        navigate(
          `../multiaccount/detail/${ownerId}/subscriptionId/${subscriptionId}/source/${source}`
        );
      } else {
        alert.setErrorAlert(t('alertMessages.multiAccountSubscriptionMembers.onError'));
      }
    } finally {
      setInternalLoading(false);
    }
  }

  return (
    subscribedAccesses && (
      <DataGrid
        error={error || undefined}
        rows={subscribedAccesses}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlayDataGrid,
        }}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        onRowClick={(params) =>
          navigateToMultiAccountDetail(params.row.subscriptionId, params.row.source)
        }
        getRowId={() => Math.floor(Math.random() * 1000)}
        loading={loading || internalLoading}
        autoHeight
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    )
  );
};

export default MultiAccountSubscriptionDataGrid;
