import { IconButton, TableRow } from '@mui/material';
import React from 'react';
import { CustomRow } from './CustomRow';
import StyledTableCell from '../StyledTableCell';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';
import { getEntitlementRowStyle } from '../../../util/subscribedAccessUtils';
import { ModeEdit } from '@mui/icons-material';

interface CustomEditableRowProps<T> {
  data: T;
  isHighlighted: (data: T) => boolean;
  index: number;
  handleEditClick?: (row: T, index: number) => void;
  rowCellConfig: TooltipCellConfig<T>[];
}

export const CustomEditableRow = <T,>({
  data,
  isHighlighted,
  index,
  handleEditClick,
  rowCellConfig,
}: CustomEditableRowProps<T>) => {
  return (
    <TableRow key={index} style={getEntitlementRowStyle(isHighlighted(data) || false)}>
      <CustomRow row={data} rowCellConfig={rowCellConfig} />
      {(handleEditClick && (
        <StyledTableCell label={'edit'} style={{ textAlign: 'right' }}>
          <IconButton onClick={() => handleEditClick(data, index)}>
            <ModeEdit />
          </IconButton>
        </StyledTableCell>
      )) || <StyledTableCell label={'edit'} />}
    </TableRow>
  );
};
