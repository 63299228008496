import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Field, FieldInputProps, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { MembersHookResult } from '../../hooks/useMembers';

interface AddMemberPopUpProps {
  membersHook: MembersHookResult;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
}

export const AddMemberPopUp: FC<AddMemberPopUpProps> = ({
  membersHook,
  setShowPopup,
  showPopup,
}) => {
  const { loading, addMember } = membersHook;
  const validationProperties = useSelector((state: RootState) => state.validationProperties);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [storedFormikValues, setFormikValues] = useState<FormikValues>();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address')
      .required('An email address is required')
      .max(
        validationProperties.maxEmailLength,
        `Email address can have a maximum of ${validationProperties.maxEmailLength} characters`
      ),
  });

  const confirmMemberEmail = (values: FormikValues) => {
    setFormikValues(values);
    setShowDialog(true);
  };

  const onSubmit = async (memberEmail: string) => {
    await addMember(memberEmail);
    setShowDialog(false);
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <Dialog open={showPopup}>
          <DialogContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="MHHeading" mr={8}>
                Add a new member to subscription
              </Typography>
              <Button
                variant="brandContained"
                color="brandGrey"
                onClick={() => setShowPopup(false)}>
                Close
              </Button>
            </Box>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values) => confirmMemberEmail(values)}
              validationSchema={ValidationSchema}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Box mb={2}>
                    <Field name="email">
                      {({ field }: { field: FieldInputProps<unknown> }) => (
                        <TextField
                          required
                          fullWidth
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          type="email"
                          error={!!(errors.email && touched.email)}
                          helperText={
                            errors.email && touched.email
                              ? errors.email
                              : "Provide the member's email address"
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Button
                      variant="brandContained"
                      color="brandBlack"
                      type="submit"
                      disabled={isSubmitting || !touched || !!Object.keys(errors).length}>
                      Add
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}

      <Dialog id="innerDialog" open={showDialog}>
        <DialogContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="MHHeading" mr={2}>
              You are about to invite a new member to this subscription
            </Typography>
            <Button variant="contained" color="brandGrey" onClick={() => setShowDialog(false)}>
              Close
            </Button>
          </Box>
          <Box mb={2}>
            <Typography variant="MHCaption">
              Are you sure you want to add this member to the multi account subscription? This will
              send an email to
              <Typography variant="MHCaption" fontWeight="bold" component="span">
                {` ${storedFormikValues?.email}`}
              </Typography>
              .
            </Typography>
          </Box>
          <Box>
            <Button
              variant="brandContained"
              color="brandBlack"
              onClick={() => onSubmit(storedFormikValues?.email)}
              disabled={loading}
              type="submit">
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
