import TableCell from './TableCell';
import React from 'react';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';

interface CustomRowProps<T> {
  row: T;
  rowCellConfig: TooltipCellConfig<T>[];
}

export const CustomRow = <T,>({ row, rowCellConfig }: CustomRowProps<T>) => {
  return (
    <>
      {rowCellConfig.map((config, cellIndex) => (
        <TableCell key={cellIndex} value={row} {...config} />
      ))}
    </>
  );
};
