import { useEffect, useState } from 'react';

const useCollection = <T>() => {
  const [collection, setCollection] = useState<Array<T>>();
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collectionLoader, setCollectionLoader] =
    useState<(pageSize?: number, pageNumber?: number) => Promise<Array<T>>>();
  const loadCollection: () => Promise<void> = () => {
    if (!collectionLoader) return Promise.resolve();
    setIsLoading(true);
    return collectionLoader()
      .then((result) => setCollection(result))
      .catch(() => setHasErrors(true))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadCollection();
  }, [collectionLoader]);

  return {
    setCollectionLoader,
    hasErrors,
    isLoading,
    collection,
  };
};

export type UseCollectionHook<T> = typeof useCollection<T>;
export type UseCollectionHookResult<T> = ReturnType<UseCollectionHook<T>>;
export default useCollection;
