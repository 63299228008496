import React from 'react';
import { CUSSUP_ROLES } from '../types/auth';

interface ProtectedArgs {
  requiredRoles: CUSSUP_ROLES[];
  currentRoles: CUSSUP_ROLES[];
  children: JSX.Element;
}

function RoleAuthorizeShowGuard({ requiredRoles, currentRoles, children }: ProtectedArgs) {
  if (requiredRoles.length > 0 && requiredRoles.find((rr) => currentRoles?.includes(rr))) {
    return children;
  }
  if (requiredRoles.length === 0) {
    return children;
  }
  return <></>;
}

export default RoleAuthorizeShowGuard;
