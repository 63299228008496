import React, { FC } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { UserCreateGrid } from '../../components/UserDetailGrid/UserCreateGrid';
import { Grid, Stack, Typography } from '@mui/material';

export const CreateIdentity: FC = () => {
  return (
    <AuthenticatedTemplate>
      <Stack justifyContent="space-between" mb={2}>
        <Stack direction="column">
          <Typography variant="MHHeading">Create identities</Typography>
          <Typography variant="MHCaption" mb={2}>
            Manually create single identities.
          </Typography>
        </Stack>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={10} md={8}>
          <UserCreateGrid></UserCreateGrid>
        </Grid>
      </Grid>
    </AuthenticatedTemplate>
  );
};
