import { IFlattenedSubscribedAccess, SUBSCRIBED_ACCESS_SOURCE } from '../types/subscribed-access';
import { CUSSUP_ROLES } from '../types/auth';

export const isEditableSubscribedAccess = (
  access: IFlattenedSubscribedAccess,
  userRoles: CUSSUP_ROLES[]
): boolean => {
  if (isEditableSource(access.source)) return true;
  return (
    userRoles.length > 0 &&
    userRoles.includes(CUSSUP_ROLES.CUSSUP_SPECIALIZED_SUBSCRIPTION_SUPPORT) &&
    access.subscriptionId !== undefined &&
    access.orderId === undefined
  );
};

export const isEditableSource = (source: string) => {
  return source === SUBSCRIBED_ACCESS_SOURCE.FROM_CUSSUP_EMPLOYEE;
};

export const formatDateString = (value?: unknown) => {
  return value && typeof value === 'string'
    ? new Date(value).toLocaleString(undefined, { hour12: false })
    : '';
};

export const renderDateString = <T,>(row: T, propertyKey: keyof T): string => {
  const value = row[propertyKey];
  return formatDateString(value);
};

export function capitalize(value: string): string {
  const firstCharacterCapitalized = value.charAt(0).toUpperCase();
  return firstCharacterCapitalized + value.slice(1);
}

export function parseDateString(value: string): { year: number; month: number; day: number } {
  const [year, month, day] = value?.split('-').map(Number) ?? [];
  return { year, month, day };
}
