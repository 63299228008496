import { Identity } from '../types/identity';
import { IValidationProperties, Result } from '../types/validation';
import { store } from '../redux/store';
import { apiService } from './apiService';

class ValidationService {
  private appConfig = () => store.getState().appConfig;

  public validateEditIdentity(
    { namesPerson }: Partial<Identity>,
    validationProperties: IValidationProperties
  ): Result {
    if (
      namesPerson?.firstName &&
      namesPerson?.firstName?.length > validationProperties.maxFirstNameLength
    ) {
      return {
        ok: false,
        message: `Validation error: First name cannot be longer than ${validationProperties.maxFirstNameLength} characters.`,
      };
    }
    if (
      namesPerson?.lastName &&
      namesPerson?.lastName?.length > validationProperties.maxLastNameLength
    ) {
      return {
        ok: false,
        message: `Validation error: Last name cannot be longer than ${validationProperties.maxLastNameLength} characters.`,
      };
    }
    if (
      namesPerson?.nickName &&
      namesPerson?.nickName?.length > validationProperties.maxNickNameLength
    ) {
      return {
        ok: false,
        message: `Validation error: Nickname cannot be longer than ${validationProperties.maxNickNameLength} characters.`,
      };
    }
    return { ok: true };
  }

  public async getValidationProperties(token: string): Promise<IValidationProperties> {
    return apiService.bffClient
      .get(`validation/properties`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        return r.data;
      });
  }
}

export const validationService = new ValidationService();
