import * as React from 'react';
import {
  Checkbox,
  checkboxClasses,
  CheckboxProps,
  Icon,
  styled,
  svgIconClasses,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface GreyCheckboxProps extends CheckboxProps {
  label?: string;
}

export const GreyCheckbox = styled(({ label, ...props }: GreyCheckboxProps) => (
  <Checkbox
    disableRipple
    color="default"
    checkedIcon={<CheckIcon />}
    icon={<Icon />}
    inputProps={label ? { 'aria-label': label } : {}}
    {...props}
  />
))(() => ({
  [`&.${checkboxClasses.root}`]: {
    width: '1rem',
    height: '1rem',
    borderWidth: 1,
    borderColor: '#9a9a9a',
    borderStyle: 'solid',
    borderRadius: 3,
    backgroundColor: 'white',
    marginTop: '0.2rem',

    '&:hover': {
      backgroundColor: '#efefef',
    },

    [`&.${checkboxClasses.disabled}`]: {
      backgroundColor: '#EFEFEF',
    },

    [`& .${svgIconClasses.root}`]: {
      stroke: '#D23E3E',
      strokeWidth: 1.2,
    },
  },
}));
