import React from 'react';
import { Box, Drawer, List, Toolbar } from '@mui/material';
import NavigationListItem from './NavigationListItem';
import MultiAccountSection from './MultiAccountNavigationSection';
import { AuthenticatedTemplate } from '@azure/msal-react';
import RoleAuthorizeShow from '../../Guards/RoleAuthorizeShowGuard';
import { EVERY_ROLE, SPECIALIZED_AND_DATA_PROTECTION } from '../../types/auth';
import { ROUTES } from '../../router/routes';
import { useAuth } from '../../providers/AuthProvider';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface SidebarNavigationProps {
  open: boolean;
  onToggle: () => void;
}

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({ open, onToggle }) => {
  const { roles } = useAuth();

  return (
    <Drawer
      sx={{
        width: 250,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent">
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <NavigationListItem
            route={ROUTES.ROOT}
            Icon={LockOutlinedIcon}
            text={'Authentication'}
            label={'btn_authentication'}
          />
          <AuthenticatedTemplate>
            <RoleAuthorizeShow requiredRoles={EVERY_ROLE} currentRoles={roles}>
              <div>
                <NavigationListItem
                  route={ROUTES.SEARCH}
                  Icon={SearchOutlinedIcon}
                  text={'Search user'}
                  label={'btn_search_user'}
                  data-cy={'btn_search_user'}
                />
                <NavigationListItem
                  route={ROUTES.IDENTITIES}
                  Icon={PersonAddAlt1OutlinedIcon}
                  text={'Identities'}
                  label={'btn_identities'}
                  data-cy={'btn_identities'}
                />
              </div>
            </RoleAuthorizeShow>
            <RoleAuthorizeShow requiredRoles={SPECIALIZED_AND_DATA_PROTECTION} currentRoles={roles}>
              <MultiAccountSection open={open} onToggle={onToggle} />
            </RoleAuthorizeShow>
          </AuthenticatedTemplate>
        </List>
      </Box>
    </Drawer>
  );
};

export default SidebarNavigation;
