import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { MembersHookResult } from '../../hooks/useMembers';

interface RemoveMemberPopUpProps {
  membersHook: MembersHookResult;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
}

export const RemoveMemberPopUp: FC<RemoveMemberPopUpProps> = ({
  membersHook,
  setShowPopup,
  showPopup,
}) => {
  const { loading, removeMember, selectedMember } = membersHook;

  const onSubmit = async (memberId: string) => {
    await removeMember(memberId).then(() => setShowPopup(false));
  };

  return (
    <>
      {selectedMember && showPopup && (
        <Dialog open={showPopup}>
          <DialogContent>
            <Stack flexDirection="column" spacing={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="MHHeading" mr={2}>
                  You are about to remove an existing member
                </Typography>
                <Button
                  variant="brandContained"
                  color="brandGrey"
                  onClick={() => setShowPopup(false)}>
                  Close
                </Button>
              </Box>
              <Box>
                <Typography variant="MHCaption">
                  Are you sure you want remove member
                  <Typography variant="MHCaption" fontWeight="bold" component="span">
                    {` ${selectedMember.email} `}
                  </Typography>
                  from the multi account subscription?
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="brandContained"
                  color="brandRed"
                  onClick={() => onSubmit(selectedMember.id)}
                  disabled={loading}>
                  Remove
                </Button>
              </Box>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
