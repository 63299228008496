import React, { FC } from 'react';
import { useAlert } from '../../providers/AlertProvider';
import { ALERT_STATUS } from '../../types/alert';
import { Alert as MuiAlert, AlertColor } from '@mui/material';

export const Alert: FC = () => {
  const alert = useAlert();

  if (alert.alert !== ALERT_STATUS.NONE) {
    return (
      <MuiAlert
        onClose={alert.clearAlert}
        severity={alert.alert.toLowerCase() as AlertColor}
        variant="filled"
        sx={{
          position: 'fixed',
          top: 80,
          right: 10,
          zIndex: 9999,
        }}>
        {alert.alertText ? alert.alertText : null}
      </MuiAlert>
    );
  } else {
    return null;
  }
};
