import React, { FC, useMemo, useState } from 'react';
import { Paper, TableContainer } from '@mui/material';
import { IFlattenedSubscribedAccess, ISubscribedAccess } from '../../../types/subscribed-access';
import { UseCollectionHookResult } from '../../../hooks/useCollection';
import TableErrorComponent from '../../General/TableErrorComponent';
import { Spinner } from '../../General/Spinner';
import subscribedAccessHeadCells from './SubscribedAccessHeadCells';
import {
  isExpandableSubscribedAccess,
  mapSubscribedAccessesToTableComponents,
  subscribedAccessComparator,
} from '../../../util/subscribedAccessUtils';
import ExpandableCustomTable from '../../General/ExpandableTable/ExpandableCustomTable';
import EntitlementsTable from '../EntitlementsTable/EntitlementsTable';
import { PaginationInfo } from '../../../types/table';
import { subscribedAccessCellConfig } from './SubscribedAccessRowCellConfig';
import { isEditableSubscribedAccess } from '../../../util/utils';
import EntitlementHeadCells from '../EntitlementsTable/EntitlementHeadCells';
import { entitlementRowCellConfig } from '../EntitlementsTable/EntitlementRowCellConfig';
import roleAuthorizeShowGuard from '../../../Guards/RoleAuthorizeShowGuard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useAuth } from '../../../providers/AuthProvider';

interface SubscribedAccessTableProps {
  collectionHook: UseCollectionHookResult<ISubscribedAccess>;
  onEdit: (data: IFlattenedSubscribedAccess) => void;
}

// Responsible for coordinating the display of subscribed access data within a table
const SubscribedAccessTableContainer: FC<SubscribedAccessTableProps> = ({
  collectionHook,
  onEdit,
}) => {
  const { collection, hasErrors, isLoading } = collectionHook;
  const { roles } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const paginationInfo: PaginationInfo = useMemo(
    () => ({
      pageNumber: page,
      pageSize: rowsPerPage,
      setPageNumber: setPage,
      setPageSize: setRowsPerPage,
    }),
    [page, rowsPerPage]
  );

  const flattenedSubscribedAccess = useMemo(
    () => mapSubscribedAccessesToTableComponents(collection || []),
    [collection]
  );

  return (
    <TableContainer component={Paper}>
      {!hasErrors && !isLoading && (
        <ExpandableCustomTable<IFlattenedSubscribedAccess>
          rowCellConfig={subscribedAccessCellConfig}
          collection={flattenedSubscribedAccess}
          comparator={subscribedAccessComparator}
          headerCells={subscribedAccessHeadCells}
          GenericExpandableTableComponent={EntitlementsTable}
          paginationInfo={paginationInfo}
          isEditable={(row) => isEditableSubscribedAccess(row, roles)}
          handleEditClick={onEdit}
          isExpandable={isExpandableSubscribedAccess}
          expandableTableHeadCells={EntitlementHeadCells}
          expendableTableRowConfig={entitlementRowCellConfig}
        />
      )}
      {hasErrors && (
        <TableErrorComponent message={'An error occurred, data could not be loaded.'} />
      )}
      {!hasErrors && isLoading && <Spinner></Spinner>}
    </TableContainer>
  );
};

export default SubscribedAccessTableContainer;
