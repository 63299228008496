import {
  IEditManualSubscribedAccess,
  IEditSubscribedAccess,
  IEditSubscriptionBasedSubscribedAccess,
  ISubscribedAccess,
} from '../types/subscribed-access';
import { IPagedResult, ISearchTerms } from '../types/search';
import { apiService } from './apiService';
import { luceneEscape } from '../helpers/lucene';

class SubscribedAccessService {
  public async getSubscribedAccesses(identityId: string): Promise<ISubscribedAccess[]> {
    return apiService.bffClient
      .get<ISubscribedAccess[]>(`/identities/${identityId}/subscribed-accesses`)
      .then((r) => r.data);
  }

  public async getMultiAccountSubscriptions(
    searchTerms: ISearchTerms
  ): Promise<ISubscribedAccess[]> {
    const params = {
      ...searchTerms,
      subscriptionType: 'shared',
    };

    return apiService.bffClient
      .get<ISubscribedAccess[]>(`/subscribed-accesses`, { params })
      .then((r) => r.data);
  }

  public getSubscribedAccessHistory(
    identityId: string,
    pageSize = 10,
    pageNumber = 0
  ): Promise<IPagedResult<ISubscribedAccess[]>> {
    return apiService.bffClient
      .get<IPagedResult<ISubscribedAccess[]>>(
        `/identities/${identityId}/subscribed-accesses/history`,
        {
          params: {
            pageSize,
            pageNumber,
          },
        }
      )
      .then((r) => r.data);
  }

  public async addMemberToMultiAccountSubscription(
    identityId: string,
    email: string,
    subscriptionId: string,
    source: string
  ): Promise<number> {
    return apiService.bffClient
      .post<number>(
        `/identities/${identityId}/subscribed-accesses/${subscriptionId}/source/${source}/members`,
        { email }
      )
      .then((r) => r.status);
  }

  private async putManualSubscribedAccess(
    identityId: string,
    body: IEditManualSubscribedAccess
  ): Promise<number> {
    return apiService.bffClient
      .put(`/identities/${identityId}/subscribed-accesses/support`, body)
      .then((r) => r.status);
  }

  private async putSubscriptionBasedSubscribedAccess(
    identityId: string,
    body: IEditSubscriptionBasedSubscribedAccess
  ): Promise<number> {
    return apiService.bffClient
      .put(
        `/identities/${identityId}/subscribed-accesses/subscription/${body.subscriptionId}/source/${body.source}`,
        [body]
      )
      .then((r) => r.status);
  }

  public isEditSubscriptionBasedSubscribedAccess(
    body: IEditSubscribedAccess
  ): body is IEditSubscriptionBasedSubscribedAccess {
    return (body as IEditSubscriptionBasedSubscribedAccess).subscriptionId !== undefined;
  }

  public async putSubscribedAccess(
    identityId: string,
    body: IEditSubscribedAccess
  ): Promise<number> {
    if (this.isEditSubscriptionBasedSubscribedAccess(body)) {
      return this.putSubscriptionBasedSubscribedAccess(identityId, body);
    } else {
      return this.putManualSubscribedAccess(identityId, body);
    }
  }

  public async removeMemberFromMultiAccountSubscription(
    identityId: string,
    memberId: string,
    subscriptionId: string,
    source: string
  ): Promise<number> {
    return apiService.bffClient
      .delete(
        `/identities/${identityId}/subscribed-accesses/${subscriptionId}/source/${source}/members/${memberId}`
      )
      .then((r) => r.status);
  }

  public getMultiAccountSubscriptionOwner(subscriptionId: string): Promise<string> {
    const params = {
      subscriptionId,
      subscriptionType: 'shared',
      isOwner: true,
    };

    return apiService.bffClient
      .get<ISubscribedAccess[]>(`/subscribed-accesses`, { params })
      .then((r) => r.data)
      .then((r) => r[0]?.identityId);
  }
}

export const subscribedAccessService = new SubscribedAccessService();
