import React, { FC } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';

interface GridTooltipProps {
  params: GridRenderCellParams;
}

export const GridTooltip: FC<GridTooltipProps> = ({ params }) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#666666',
      maxWidth: 230,
      fontSize: theme.typography.pxToRem(14),
      inset: 'auto auto 0px 0px',
      padding: '4px 8px 8px 8px',
    },
  }));

  return (
    <HtmlTooltip
      placement="bottom-start"
      title={
        <>
          <Typography color="inherit">{params.colDef.headerName}</Typography>
          <em>{params.value}</em>
        </>
      }>
      <span className="table-cell-truncate">{params.value}</span>
    </HtmlTooltip>
  );
};
