export function debounce<F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
): (...args: Parameters<F>) => Promise<Awaited<ReturnType<F>>> {
  let timeoutId: ReturnType<typeof setTimeout>;
  const debounced = (...args: Parameters<F>): Promise<Awaited<ReturnType<F>>> =>
    new Promise((resolve, reject) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, waitFor);
    });
  return debounced;
}
