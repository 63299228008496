import { AppBar, Toolbar } from '@mui/material';
import { Logo } from '@mediahuis/chameleon-react';
import React from 'react';

const MediahuisHeader: React.FC = () => {
  return (
    <AppBar
      className="app-bar"
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Logo width="200px" className="mh-logo" alt="Logo Mediahuis" />
      </Toolbar>
    </AppBar>
  );
};

export default MediahuisHeader;
