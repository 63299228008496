import { IFlattenedSubscribedAccess } from '../../../types/subscribed-access';
import { HeadCell } from '../../../types/table';

export const subscribedAccessHeadCells: readonly HeadCell<IFlattenedSubscribedAccess>[] = [
  {
    id: 'entitlementCodes',
    label: 'Applicable entitlements',
    key: 'entitlementCodes',
  },
  {
    id: 'source',
    label: 'Source',
    key: 'source',
  },
  {
    id: 'subscriptionId',
    label: 'Subscription ID',
    key: 'subscriptionId',
  },
  {
    id: 'orderId',
    label: 'Order ID',
    key: 'orderId',
  },
  {
    id: 'startDate',
    label: 'Start date',
    key: 'startDate',
  },
  {
    id: 'endDate',
    label: 'End date',
    key: 'endDate',
  },
  {
    id: 'importedBy',
    label: 'Imported by',
    key: 'importedBy',
  },
  {
    id: 'expand',
    label: '',
  },
  {
    id: 'edit',
    label: '',
  },
  {
    id: 'active',
    label: '',
  },
];

export default subscribedAccessHeadCells;
