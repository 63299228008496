import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RoleAuthorizeRouteGuard from '../Guards/RoleAuthorizeRouteGuard';
import { useAuth } from '../providers/AuthProvider';
import { ERROR_CODES } from '../types/error';
import { ErrorFallBack } from '../views/Error';
import { Login } from '../views/Login';
import { Routes as routeList } from './routes';

function AppRouter() {
  const { isAuthenticated, roles } = useAuth();

  return (
    <Routes>
      {!isAuthenticated ? (
        <Route path="*" element={<Login />} />
      ) : (
        <>
          {routeList.map(({ path, Component, requiredRoles, props }, index) => (
            <Route
              key={`route-${index}`}
              path={path}
              element={
                <RoleAuthorizeRouteGuard requiredRoles={requiredRoles} currentRoles={roles}>
                  <Component {...props} />
                </RoleAuthorizeRouteGuard>
              }
            />
          ))}
          <Route path="*" element={<ErrorFallBack error={Error(ERROR_CODES.PAGE_NOT_FOUND)} />} />
        </>
      )}
    </Routes>
  );
}

export default AppRouter;
