import axios, { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { IAppConfig } from '../types/app-config';
import { PublicClientApplication } from '@azure/msal-browser';

export interface IApiService {
  get bffClient(): AxiosInstance;
  get addressClient(): AxiosInstance;
}

class ApiService implements IApiService {
  private _bffClient: AxiosInstance | null = null;
  private _anonymousClient: AxiosInstance | null = null;
  private _addressClient: AxiosInstance | null = null;

  public get bffClient(): AxiosInstance {
    if (!this._bffClient) {
      throw new Error('BFF client not initialized');
    }
    return this._bffClient;
  }

  public get anonymousClient(): AxiosInstance {
    if (!this._anonymousClient) {
      throw new Error('Anonymous client not initialized');
    }
    return this._anonymousClient;
  }

  public get addressClient(): AxiosInstance {
    if (!this._addressClient) {
      throw new Error('Address client not initialized');
    }
    return this._addressClient;
  }

  public init(msalInstance: PublicClientApplication, appConfig: IAppConfig) {
    this._bffClient = axios.create({
      baseURL: `${appConfig.bffUrl}/api/v1`,
    });
    this._addressClient = axios.create({
      baseURL: appConfig.addressService,
    });

    this._anonymousClient = axios.create({});

    const interceptor = async (config: InternalAxiosRequestConfig) => {
      config.headers = {
        ...(config.headers || {}),
      } as AxiosRequestHeaders;
      const accessToken = (
        await msalInstance.acquireTokenSilent({
          authority: `https://login.microsoftonline.com/${appConfig.Oauth.authority}`,
          scopes: appConfig.Oauth.scopes,
        })
      ).accessToken;
      if (accessToken) {
        // noinspection AllyPlainJsInspection
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    };

    this._bffClient.interceptors.request.use(interceptor);
  }
}

export const apiService = new ApiService();
