/* eslint-disable max-len */
export interface IActivity {
  clientName: string;
  clientId: string;
  event: string; // TODO: Event enum
  description: string;
  host: string;
  ipAddress: string;
  performedBy: string;
  timestamp: string;
  total: number;
  userAgent: string;
}

export enum USER_ACTIVITY_FIELDS {
  CLIENT_NAME = 'clientName',
  EVENT = 'event',
  HOST = 'host',
  IP_ADDRESS = 'ipAddress',
  PERFORMED_BY = 'performedBy',
  USER_AGENT = 'userAgent',
  TIMESTAMP = 'timestamp',
}

export enum ACTIVITY_EVENT_TYPE {
  admin_update_launch = 'Auth0 Update Launched',
  api_limit = 'The maximum number of requests to the Authentication or Management APIs in given time has reached',
  cls = 'Passwordless login code/link has been sent',
  cs = 'Passwordless login code has been sent',
  depnote = 'Deprecation Notice',
  du = 'User has been deleted',
  f = 'Failed Login',
  fapi = 'Operation on API failed',
  fc = 'Failed by Connector',
  fce = 'Failed to change user email',
  fco = 'Origin is not in the Allowed Origins list for the specified application',
  fcoa = 'Failed cross-origin authentication',
  fcp = 'Failed Change Password',
  fcph = 'Failed Post Change Password Hook',
  fcpn = 'Failed Change Phone Number',
  fcpr = 'Failed Change Password Request',
  fcpro = 'Failed to provision a AD/LDAP connector',
  fcu = 'Failed to change username',
  fd = 'Failed to generate delegation token',
  fdeac = 'Failed to activate device',
  fdeaz = 'Device authorization request failed',
  fdecc = 'User did not confirm device',
  fdu = 'Failed User Deletion',
  feacft = 'Failed to exchange authorization code for Access Token',
  feccft = 'Failed exchange of Access Token for a Client Credentials Grant',
  fede = 'Failed to exchange Device Code for Access Token',
  fens = 'Failed exchange for Native Social Login',
  feoobft = 'Failed exchange of Password and OOB Challenge for Access Token',
  feotpft = 'Failed exchange of Password and OTP Challenge for Access Token',
  fepft = 'Failed exchange of Password for Access Token',
  fepotpft = 'Failed exchange of Passwordless OTP for Access Token',
  fercft = 'Failed Exchange of Password and MFA Recovery code for Access Token',
  fertft = 'Failed Exchange of Refresh Token for Access Token. This could occur if the refresh token is revoked or expired.',
  ferrt = 'Failed Exchange of Rotating Refresh Token. This could occur when reuse is detected.',
  fi = 'Failed to accept a user invitation. This could happen if the user accepts an invitation using a different email address than provided in the invitation, or due to a system failure while provisioning the invitation.',
  flo = 'User logout failed',
  fn = 'Failed to send email notification',
  fp = 'Failed Login (Incorrect Password)',
  fs = 'Failed Signup',
  fsa = 'Failed Silent Auth',
  fu = 'Failed Login (Invalid Email/Username)',
  fui = 'Failed to import users',
  fv = 'Failed to send verification email',
  fvr = 'Failed to process verification email request',
  gd_auth_failed = 'Multi-factor authentication failed. This could happen due to a wrong code entered for SMS/Voice/Email/TOTP factors, or a system failure.',
  gd_auth_rejected = 'A user rejected a Multi-factor authentication request via push-notification.',
  gd_auth_succeed = 'Multi-factor authentication success.',
  gd_enrollment_complete = 'A first time MFA user has successfully enrolled using one of the factors.',
  gd_otp_rate_limit_exceed = 'A user, during enrollment or authentication, enters an incorrect code more than the maximum allowed number of times. Ex: A user enrolling in SMS enters the 6-digit code wrong more than 10 times in a row.',
  gd_recovery_failed = 'A user enters a wrong recovery code when attempting to authenticate.',
  gd_recovery_rate_limit_exceed = 'A user enters a wrong recovery code too many times.',
  gd_recovery_succeed = 'A user successfully authenticates with a recovery code.',
  gd_send_pn = 'Push notification for MFA sent successfully sent.',
  gd_send_sms = 'SMS for MFA successfully sent.',
  gd_send_sms_failure = 'Attempt to send SMS for MFA failed.',
  gd_send_voice = 'Voice call for MFA successfully made.',
  gd_send_voice_failure = 'Attempt to make Voice call for MFA failed.',
  gd_start_auth = 'Second factor authentication event started for MFA.',
  gd_start_enroll = 'Multi-factor authentication enroll has started.',
  gd_start_enroll_failed = 'Push to start enrollement failed.',
  gd_tenant_update = 'Guardian tenant update',
  gd_unenroll = 'Device used for second factor authentication has been unenrolled.',
  gd_update_device_account = 'Device used for second factor authentication has been updated.',
  limit_delegation = 'Rate limit exceeded to /delegation endpoint',
  limit_mu = 'An IP address is blocked because it attempted too many failed logins without a successful login. Or an IP address is blocked because it attempted too many sign-ups, whether successful or failed.',
  limit_wc = 'An IP address is blocked because it reached the maximum failed login attempts into a single account.',
  limit_sul = 'A user is temporarily prevented from logging in because they reached the maximum logins per time period from the same IP address.',
  mfar = 'A user has been prompted for multi-factor authentication (MFA).',
  mgmt_api_read = 'API GET operation returning secrets completed successfully',
  pla = 'This log is generated before a login and helps in monitoring the behavior of bot detection without having to enable it.',
  pwd_leak = 'Someone behind the IP address ip attempted to login with a leaked password.',
  resource_cleanup = 'Emitted when resources exceeding defined limits were removed.',
  s = 'Successful login event.',
  sapi = 'Successful management API write event.',
  sce = 'Success Change Email',
  scoa = 'Success cross-origin authentication',
  scp = 'Success Change Password',
  scph = 'Success Post Change Password Hook',
  scpn = 'Success Change Phone Number',
  scpr = 'Success Change Password Request',
  scu = 'Success Change Username',
  sd = 'Success Delegation',
  sdu = 'User successfully deleted',
  seacft = 'Successful exchange of authorization code for Access Token',
  seccft = 'Successful exchange of Access Token for a Client Credentials Grant',
  sede = 'Successful exchange of device code for Access Token',
  sens = 'Native Social Login',
  seoobft = 'Successful exchange of Password and OOB Challenge for Access Token',
  seotpft = 'Successful exchange of Password and OTP Challenge for Access Token',
  sepft = 'Successful exchange of Password for Access Token',
  sercft = 'Successful exchange of Password and MFA Recovery code for Access Token',
  sertft = 'Successful exchange of Refresh Token for Access Token',
  si = 'Successfully accepted a user invitation',
  srrt = 'Successfully revoked a Refresh Token',
  slo = 'User successfully logged out',
  ss = 'Success Signup',
  ssa = 'Success Silent Auth',
  sui = 'Successfully imported users',
  sv = 'Successfully consumed email verification link',
  svr = 'Successfully called verification email endpoint. Verification email in queue to send.',
  sys_os_update_end = 'Auth0 OS Update Ended',
  sys_os_update_start = 'Auth0 OS Update Started',
  sys_update_end = 'Auth0 Update Ended',
  sys_update_start = 'Auth0 Update Started',
  ublkdu = 'User block setup by anomaly detection has been released',
  w = 'Warnings During Login',
}
