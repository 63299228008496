import React, { FC, useMemo } from 'react';
import { Paper, TableContainer } from '@mui/material';
import { IFlattenedSubscribedAccess, ISubscribedAccess } from '../../../types/subscribed-access';
import TableErrorComponent from '../../General/TableErrorComponent';
import { Spinner } from '../../General/Spinner';
import subscribedAccessHeadCells from './SubscribedAccessHeadCells';
import {
  isExpandableSubscribedAccess,
  mapSubscribedAccessesHistoryToTableComponents,
  subscribedAccessComparator,
} from '../../../util/subscribedAccessUtils';
import ExpandableCustomTable from '../../General/ExpandableTable/ExpandableCustomTable';
import EntitlementsTable from '../EntitlementsTable/EntitlementsTable';
import { UsePagedCollectionHookResult } from '../../../hooks/usePagedCollection';
import { PaginationInfo } from '../../../types/table';
import { subscribedAccessCellConfig } from './SubscribedAccessRowCellConfig';
import { entitlementHeadCells } from '../EntitlementsTable/EntitlementHeadCells';
import { entitlementRowCellConfig } from '../EntitlementsTable/EntitlementRowCellConfig';

interface SubscribedAccessTableProps {
  collectionHook: UsePagedCollectionHookResult<ISubscribedAccess>;
  handleEditClick?: (row: IFlattenedSubscribedAccess, index: number) => void;
}

// Responsible for coordinating the display of subscribed access history data within a table
const SubscribedAccessTableContainer: FC<SubscribedAccessTableProps> = ({
  collectionHook,
  handleEditClick,
}) => {
  const { collection, hasErrors, isLoading, pageNumber, pageSize, setPageNumber, setPageSize } =
    collectionHook;
  const paginationInfo: PaginationInfo = useMemo(
    () => ({
      pageNumber,
      pageSize,
      setPageNumber,
      setPageSize,
    }),
    [pageNumber, pageSize, setPageNumber, setPageSize]
  );

  const flattenedSubscribedAccess = useMemo(
    () => mapSubscribedAccessesHistoryToTableComponents(collection?.data || []),
    [collection]
  );

  return (
    <TableContainer component={Paper}>
      {!hasErrors && !isLoading && (
        <ExpandableCustomTable<IFlattenedSubscribedAccess>
          rowCellConfig={subscribedAccessCellConfig}
          collection={flattenedSubscribedAccess}
          headerCells={subscribedAccessHeadCells}
          handleEditClick={handleEditClick}
          GenericExpandableTableComponent={EntitlementsTable}
          paginationInfo={paginationInfo}
          comparator={subscribedAccessComparator}
          isExpandable={isExpandableSubscribedAccess}
          expandableTableHeadCells={entitlementHeadCells.filter(
            (config) => config.key !== 'isActive'
          )}
          expendableTableRowConfig={entitlementRowCellConfig.filter(
            (config) => config.propertyKey !== 'isActive'
          )}
        />
      )}
      {hasErrors && (
        <TableErrorComponent message={'An error occurred, data could not be loaded.'} />
      )}
      {!hasErrors && isLoading && <Spinner></Spinner>}
    </TableContainer>
  );
};

export default SubscribedAccessTableContainer;
