import React, { useCallback, useState } from 'react';
import { Table } from '@mui/material';
import { Sorting } from '../../../util/sortingUtils';
import { HeadCell } from '../../../types/table';
import TableHeadWithSorting from './TableHeadWithSorting';
import CustomTableBody from './CustomTableBody';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';
import CustomTablePagination from './CustomTablePagination';
import { useSortingHook } from '../../../hooks/useSortingHook';
import { usePaginationHook } from '../../../hooks/usePagination';
import CustomTableHead from './CustomTableHead';

interface CustomTableProps<T> {
  collection: T[];
  comparator: (a: T, b: T, orderBy: keyof T | undefined) => 1 | -1 | 0;
  headerCells: HeadCell<T>[];
  isHighlighted: (data: T) => boolean;
  handleEditClick?: (row: T, index: number) => void;
  rowCellConfig: TooltipCellConfig<T>[];
  isSortable: boolean;
  isPaginated: boolean;
}

const CustomTable = <T,>({
  collection,
  comparator,
  headerCells,
  isHighlighted,
  handleEditClick,
  rowCellConfig,
  isSortable,
  isPaginated,
}: CustomTableProps<T>) => {
  const [sorting, setSorting] = useState<Sorting<T>>({ orderBy: undefined, order: 'asc' });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const orderComparator = useCallback(
    (a: T, b: T) =>
      ((sorting.order === 'desc' ? 1 : -1) * comparator(a, b, sorting.orderBy)) as 1 | -1 | 0,
    [comparator, sorting.order, sorting.orderBy]
  );
  const sortedCollection = useSortingHook({ collection, comparator: orderComparator });
  const paginatedRows = usePaginationHook({ collection: sortedCollection, page, rowsPerPage });

  return (
    <>
      <Table style={{ borderSpacing: '0' }}>
        {/* Table header */}
        {isSortable ? (
          <TableHeadWithSorting<T>
            headCells={headerCells}
            sorting={sorting}
            onSortingChange={setSorting}
          />
        ) : (
          <CustomTableHead headCells={headerCells} />
        )}

        {/* Table body */}
        <CustomTableBody
          visibleRows={isPaginated ? paginatedRows : sortedCollection}
          handleEditClick={handleEditClick}
          rowCellConfig={rowCellConfig}
          isHighlighted={isHighlighted}
        />
      </Table>
      {isPaginated && (
        <CustomTablePagination
          collection={collection || []}
          page={page}
          rowsPerPage={rowsPerPage}
          setNewPage={setPage}
          setNewRowsPerPage={setRowsPerPage}
        />
      )}
    </>
  );
};

export default CustomTable;
