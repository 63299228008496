import { TablePagination } from '@mui/material';
import React from 'react';

interface PaginationProps<T> {
  collection: T[];
  page: number;
  rowsPerPage: number;
  setNewPage: (page: number) => void;
  setNewRowsPerPage: (rowsPerPage: number) => void;
}

const CustomTablePagination = <T,>({
  collection,
  page,
  rowsPerPage,
  setNewPage,
  setNewRowsPerPage,
}: PaginationProps<T>) => {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRowsPerPage(parseInt(event.target.value, 10));
    setNewPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setNewPage(newPage);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]} // Customize options as needed
      component="div"
      count={collection ? collection.length : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default CustomTablePagination;
