import { AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { loginRequest } from '../authConfig';
import { IAppConfig } from '../types/app-config';
import { CUSSUP_ROLES, IAuthContext } from '../types/auth';

export enum LOCAL_STORAGE {
  ACTIVE_ACCOUNT = 'activeAccount',
}

interface AuthProviderProps {
  config: IAppConfig;
}

export interface PartialAzureToken {
  accessToken: AuthenticationResult['accessToken'];
  expiresOn: AuthenticationResult['expiresOn'];
}

const AuthContext = createContext<IAuthContext>({
  handleLogin: Function,
  handleLogout: Function,
  getApiAccessToken: () => Promise.resolve(undefined),
  roles: [],
  customerSupportMail: '',
  isAuthenticated: false,
});

const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({ ...props }) => {
  const { config } = props;
  const {
    login,
    acquireToken,
    result: activeAuthenticationResult,
  } = useMsalAuthentication(InteractionType.Silent, loginRequest(config));
  const { instance, accounts } = useMsal();

  const activeAccount = useMemo(() => {
    const currentActiveAccount = instance.getActiveAccount();
    if (currentActiveAccount) {
      return currentActiveAccount;
    }
    if (accounts.length > 0) {
      const newActiveAccount = accounts[0];
      instance.setActiveAccount(newActiveAccount);
      localStorage.setItem(LOCAL_STORAGE.ACTIVE_ACCOUNT, newActiveAccount.username);
      return newActiveAccount;
    }
    return null;
  }, [accounts, instance]);
  const isAuthenticated = useMemo(() => activeAccount !== null, [activeAccount]);
  const activeRoles = useMemo(
    () => (activeAccount?.idTokenClaims?.roles ?? []) as CUSSUP_ROLES[],
    [activeAccount]
  );
  const activeCustomerSupportMail = useMemo(
    () => activeAccount?.idTokenClaims?.preferred_username ?? '',
    [activeAccount?.idTokenClaims?.preferred_username]
  );

  const requestAzureToken = useCallback(async () => {
    try {
      const accessTokenResponse = await acquireToken(InteractionType.Silent, {
        authority: `https://login.microsoftonline.com/${config.Oauth.authority}`,
        scopes: config.Oauth.scopes,
      });
      if (accessTokenResponse) {
        return accessTokenResponse.accessToken;
      }
    } catch (e) {
      console.error('Error while trying to get access token silently', e);
    }
  }, [acquireToken, config.Oauth.authority, config.Oauth.scopes]);

  const getApiAccessToken = useCallback(async (): Promise<
    PartialAzureToken['accessToken'] | undefined
  > => {
    if (!activeAuthenticationResult) {
      return await requestAzureToken();
    }
    if (!activeAuthenticationResult.expiresOn) {
      throw new Error('Error while retrieving access token, no expiration date found.');
    }
    const tokenExpirationTime = new Date(activeAuthenticationResult.expiresOn).getTime();
    if (tokenExpirationTime < Date.now()) {
      return await requestAzureToken();
    }
    return activeAuthenticationResult.accessToken;
  }, [activeAuthenticationResult, requestAzureToken]);

  const handleLogin = useCallback(async () => {
    await login(InteractionType.Redirect, loginRequest(config));
  }, [config, login]);

  const handleLogout = useCallback(async () => {
    localStorage.removeItem(LOCAL_STORAGE.ACTIVE_ACCOUNT);
    await instance.logoutRedirect();
  }, [instance]);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        getApiAccessToken,
        roles: activeRoles,
        customerSupportMail: activeCustomerSupportMail,
        isAuthenticated,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
