import React from 'react';
import { TableCell, TableRow, Typography, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface TableErrorComponentProps {
  message: string;
}

const TableErrorComponent: React.FC<TableErrorComponentProps> = ({ message }) => {
  return (
    <TableRow>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center', color: '#d3140d' }}>
          <IconButton style={{ color: '#d3140d' }} size="small">
            <ErrorOutlineIcon />
          </IconButton>
          <Typography>{message}</Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableErrorComponent;
