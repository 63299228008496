export type CountryConfig = {
  countryISO: string;
  requireStreet: boolean;
  resolveDutchAddress: boolean;
  validation: {
    matchPostcodeAndCity: boolean;
    validateHouseNumber: boolean;
    validateCity: boolean;
    validatePostcode: boolean;
    validateStreet: boolean;
  };
  autoComplete: boolean;
  phoneNumberFormat: string;
};

export function getCountryConfig(countryISO: string) {
  return countryConfigMap.get(countryISO) ?? getDefaultCountryConfig(countryISO);
}

const getDefaultCountryConfig = (iso: string): CountryConfig => ({
  countryISO: iso,
  requireStreet: false,
  resolveDutchAddress: false,
  validation: {
    matchPostcodeAndCity: false,
    validateHouseNumber: false,
    validateCity: false,
    validatePostcode: false,
    validateStreet: false,
  },
  autoComplete: false,
  phoneNumberFormat: '+XXXXXXXXXXX',
});

const countryConfigMap = new Map<string, CountryConfig>([
  [
    'LU',
    {
      countryISO: 'LU',
      requireStreet: false,
      resolveDutchAddress: false,
      validation: {
        matchPostcodeAndCity: true,
        validateHouseNumber: true,
        // Unable to retrieve cities for Luxembourg, limitation of address service
        validateCity: false,
        validatePostcode: true,
        validateStreet: true,
      },
      autoComplete: true,
      phoneNumberFormat: '+352XXXXXX',
    },
  ],
  [
    'NL',
    {
      countryISO: 'NL',
      requireStreet: false,
      resolveDutchAddress: false,
      validation: {
        matchPostcodeAndCity: true,
        validateHouseNumber: false,
        validateCity: true,
        validatePostcode: true,
        validateStreet: true,
      },
      autoComplete: true,
      phoneNumberFormat: '+316XXXXXXXX',
    },
  ],
  [
    'BE',
    {
      countryISO: 'BE',
      requireStreet: false,
      resolveDutchAddress: false,
      validation: {
        matchPostcodeAndCity: true,
        validateHouseNumber: true,
        validateCity: true,
        validatePostcode: true,
        validateStreet: true,
      },
      autoComplete: true,
      phoneNumberFormat: '+324XXXXXXXX',
    },
  ],
]);
