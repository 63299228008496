import { IconButton, TableRow } from '@mui/material';
import React from 'react';
import StyledTableCell from '../StyledTableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomRow } from '../Table/CustomRow';
import { TooltipCellConfig } from '../../SubscribedAccess/SubscribedAccessTable/SubscribedAccessRowCellConfig';
import { ModeEdit } from '@mui/icons-material';

interface ExpandableEditableRowProps<T> {
  row: T;
  rowCellConfig: TooltipCellConfig<T>[];
  isExpendable?: (row: T) => boolean;
  isEditable?: (row: T) => boolean;
  index: number;
  expandedRows: number[];
  handleRowClick: (index: number) => void;
  handleEditClick?: (row: T, index: number) => void;
}

export const ExpandableEditableRow = <T,>({
  row,
  rowCellConfig,
  isExpendable,
  isEditable,
  index,
  expandedRows,
  handleRowClick,
  handleEditClick,
}: ExpandableEditableRowProps<T>) => {
  return (
    <TableRow>
      <CustomRow row={row} rowCellConfig={rowCellConfig} />
      <StyledTableCell label={'row_expand'}>
        {isExpendable && isExpendable(row) && (
          <IconButton size="small" onClick={() => handleRowClick(index)}>
            {expandedRows.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </StyledTableCell>
      {(handleEditClick && isEditable && isEditable(row) && (
        <StyledTableCell label={'row_edit'}>
          <IconButton onClick={() => handleEditClick(row, index)}>
            <ModeEdit />
          </IconButton>
        </StyledTableCell>
      )) || <StyledTableCell label={'edit'}>{}</StyledTableCell>}
    </TableRow>
  );
};
