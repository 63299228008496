import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import React, { useCallback } from 'react';
import { ITime } from '../../types/time';
import dayjs from 'dayjs';

function formatDate(date: Date, time: ITime): Date {
  const formattedDate = new Date(date);
  formattedDate.setHours(time.hour);
  formattedDate.setMinutes(time.minute);
  formattedDate.setSeconds(time.second);
  return formattedDate;
}

type CustomDatePickerProps = {
  label: string;
  time: ITime;
  value?: string;
  error?: string;
  onTouched?: () => void;
  onChange: (value: Date | undefined) => void;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  time,
  value,
  error,
  onTouched,
  onChange,
}) => {
  const onDateChange = useCallback(
    (date: string | null) => {
      if (date) {
        const newDate = dayjs(date);
        if (newDate.isValid()) {
          onChange(formatDate(newDate.toDate(), time));
          return;
        }
      }
      onChange(undefined);
    },
    [onChange, time]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value ?? ''}
        inputFormat="DD/MM/YYYY"
        onChange={onDateChange}
        onOpen={onTouched}
        renderInput={(params) => (
          <TextField {...params} onBlur={onTouched} error={!!error} helperText={error} />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
