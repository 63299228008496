import React, { useState } from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { Alert } from './components/General/Alert';
import theme from './themes/theme';

import '@mediahuis/chameleon-reset';
import '@mediahuis/chameleon-theme-wl/lib/fonts/fonts.css';
import '@mediahuis/chameleon-theme-wl/lib/illustrations.js';

import './index.css';
import MediahuisHeader from './components/General/MediahuisHeader';
import SidebarNavigation from './components/Sidebar/SidebarNavigation';
import MainView from './components/General/MainView';
import useFetchExternalInfo from './hooks/useExternalInfo';
import { ErrorFallBack } from './views/Error';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

function App() {
  const [open, setOpen] = useState(true);
  const fetchingExternalConfig = useFetchExternalInfo();
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallBack} resetKeys={[location]}>
      <ThemeProvider theme={theme}>
        <Alert />
        <Box sx={{ display: 'flex' }}>
          <MediahuisHeader />
          <SidebarNavigation open={open} onToggle={() => setOpen(!open)} />
          <MainView loading={fetchingExternalConfig} />
        </Box>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
