import React, { FC, useState } from 'react';
import { Alert, AlertTitle, Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { identityService } from '../../services/identityService';
import { CreateIdentityRequest } from '../../types/identity';
import { GENDER_CODES, GENDER_CODE_MAP, GenderCode } from '../../types/gender';
import * as Yup from 'yup';
import { useErrorHandler } from 'react-error-boundary';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { IApiError } from '../../types/error';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextFieldError } from '../General/TextFieldError';

export const UserCreateGrid: FC = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const handleError = useErrorHandler();
  const validationProperties = useSelector((state: RootState) => state.validationProperties);
  const [newIdentityId, setNewIdentityId] = useState<string | null>(null);

  const userCreateInitialValues = {
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    genderCustom: '',
    birthDate: '',
  };

  const userCreateValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address')
      .required('An email address is required')
      .max(
        validationProperties.maxEmailLength,
        `Email address can have a maximum of ${validationProperties.maxEmailLength} characters`
      ),
    firstName: Yup.string()
      .optional()
      .max(
        validationProperties.maxFirstNameLength,
        `First name can have a maximum of ${validationProperties.maxFirstNameLength} characters`
      )
      .matches(
        new RegExp(validationProperties.unicodeTextRegex, 'u'),
        `Please provide a valid first name`
      ),
    lastName: Yup.string()
      .optional()
      .max(
        validationProperties.maxLastNameLength,
        `Last name can have a maximum of ${validationProperties.maxLastNameLength} characters`
      )
      .matches(
        new RegExp(validationProperties.unicodeTextRegex, 'u'),
        `Please provide a valid last name`
      ),
    gender: Yup.mixed<GenderCode>().oneOf(Object.values(GENDER_CODES)).optional(),
    genderCustom: Yup.string().when('gender', {
      is: 'o',
      then: Yup.string()
        .optional()
        .max(
          validationProperties.maxGenderCustomLength,
          `Custom gender can have a maximum of ${validationProperties.maxGenderCustomLength} characters`
        ),
    }),
    birthDate: Yup.date()
      .default(null)
      .optional()
      .nullable(true)
      .min(new Date(1920, 0, 1))
      .max(new Date(getMaxBirthdateYear(), 0, 1)),
  });

  function getMaxBirthdateYear() {
    return dayjs().get('year') - validationProperties.maxBirthdateYearDifferenceFromToday;
  }

  function convertDate(date: Dayjs) {
    if (!date) return null;
    if (!date.isValid()) throw Error('Not a valid date to convert');
    return date.format('YYYY-MM-DD');
  }

  const createUser = async (values: FormikValues) => {
    setLoading(true);
    setNewIdentityId(null);

    const createUserRequest: CreateIdentityRequest = {
      email: values.email,
      namesPerson: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
      demographicsPerson: {
        dateOfBirth: convertDate(values.birthDate) ?? undefined,
        genderCode: values.gender,
        genderCustom: values.gender === 'o' && values.genderCustom ? values.genderCustom : null,
      },
    };

    identityService
      .createIdentity(createUserRequest)
      .then((partialIdentity) => {
        alert.setSuccessAlert(t('alertMessages.userCreation.onSuccess'));
        setNewIdentityId(partialIdentity.id);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.data.errors.length > 0) {
          e.response.data.errors.forEach((element: IApiError) => {
            alert.setErrorAlert(t(element.message));
          });
        } else {
          alert.setErrorAlert(t('alertMessages.userCreation.onError'));
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={userCreateInitialValues}
      onSubmit={(values, { resetForm }) => createUser(values).then(() => resetForm(), handleError)}
      validationSchema={userCreateValidationSchema}>
      {({ values, setFieldValue, errors, touched, handleChange, isSubmitting, isValid }) => (
        <Form>
          <Grid container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={4} display="flex">
              <Typography variant="MHCaption" my="auto">
                Email address (*)
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                size="small"
                className="white_outlined"
                fullWidth
                label={values.email ? null : 'Email address'}
                value={values.email}
                error={!!errors.email}
                onChange={handleChange}
                helperText={<TextFieldError error={errors.email} absolute />}
                InputLabelProps={{ shrink: false }}
              />
            </Grid>

            <Grid item xs={4} display="flex">
              <Typography variant="MHCaption" my="auto">
                First name
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="firstName"
                name="firstName"
                variant="outlined"
                size="small"
                className="white_outlined"
                fullWidth
                label={values.firstName ? null : 'First name'}
                value={values.firstName}
                error={!!errors.firstName}
                onChange={handleChange}
                helperText={<TextFieldError error={errors.firstName} absolute />}
                InputLabelProps={{ shrink: false }}
              />
            </Grid>

            <Grid item xs={4} display="flex">
              <Typography variant="MHCaption" my="auto">
                Last name
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="lastName"
                name="lastName"
                variant="outlined"
                size="small"
                className="white_outlined"
                fullWidth
                required={false}
                label={values.lastName ? null : 'Last name'}
                value={values.lastName}
                error={!!errors.lastName}
                onChange={handleChange}
                helperText={<TextFieldError error={errors.lastName} absolute />}
                InputLabelProps={{ shrink: false }}
              />
            </Grid>

            <Grid item xs={4} display="flex">
              <Typography variant="MHCaption" my="auto">
                Gender
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="gender"
                name="gender"
                select
                variant="outlined"
                size="small"
                className="white_outlined"
                fullWidth
                required={false}
                label={values.gender ? null : 'Gender'}
                value={values.gender}
                error={!!errors.gender}
                onChange={handleChange}
                helperText={<TextFieldError error={errors.gender} absolute />}
                InputLabelProps={{ shrink: false }}>
                <MenuItem key="no-gender-value" value="">
                  <Typography variant="system" fontStyle="italic">
                    None
                  </Typography>
                </MenuItem>
                {Object.values(GENDER_CODES).map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {GENDER_CODE_MAP[code]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {values.gender === 'o' && (
              <>
                <Grid item xs={4} display="flex"></Grid>
                <Grid item xs={8}>
                  <TextField
                    id="genderCustom"
                    name="genderCustom"
                    variant="outlined"
                    size="small"
                    className="white_outlined"
                    fullWidth
                    required={false}
                    label={values.genderCustom ? null : 'Please specify another gender'}
                    value={values.genderCustom}
                    error={!!errors.genderCustom}
                    onChange={handleChange}
                    helperText={<TextFieldError error={errors.genderCustom} absolute />}
                    InputLabelProps={{ shrink: false }}></TextField>
                </Grid>
              </>
            )}

            <Grid item xs={4} display="flex">
              <Typography variant="MHCaption" my="auto">
                Birthdate
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Birthdate"
                  value={values.birthDate ? values.birthDate : null}
                  onChange={(date: Date | null) => setFieldValue('birthDate', date)}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="white_outlined"
                      fullWidth
                      label={values.birthDate ? null : 'dd/mm/yyyy'}
                      error={!!errors.birthDate}
                      helperText={
                        <TextFieldError
                          error={errors.birthDate}
                          absolute
                          customError={'Please specify a valid date'}
                        />
                      }
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            variant="brandContained"
            color="brandBlack"
            disabled={isSubmitting || !touched || !isValid}
            loading={loading}
            sx={{ mt: 2 }}>
            Save
          </LoadingButton>
          {newIdentityId != null && (
            <Box mt={4}>
              <Alert severity="info">
                <AlertTitle>{t('newIdentityId')}</AlertTitle>
                <Typography component="div" fontWeight="bold">
                  {newIdentityId}
                </Typography>
              </Alert>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};
