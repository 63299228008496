import { useCallback } from 'react';
import { GetCountriesResponse } from '../services/addressService';
import { useAddressService } from './useAddressService';

let countries: GetCountriesResponse | null = null;

export function useFetchCountriesSuggestions(): (searchTerm: string) => Promise<string[]> {
  const { addressService } = useAddressService();
  return useCallback(
    async (searchTerm: string) => {
      if (countries == null) countries = await addressService.autoCompleteCountries({});
      return countries
        .map((value) => value.Name)
        .filter((value) => value.toLowerCase().includes(searchTerm.toLowerCase()));
    },
    [addressService]
  );
}

export function useGetCountryIsoCode(): (name: string) => Promise<string | undefined> {
  const { addressService } = useAddressService();
  return useCallback(
    async (name: string) => {
      if (countries == null) countries = await addressService.autoCompleteCountries({});
      return countries.find((value) => value.Name === name)?.IsoCode;
    },
    [addressService]
  );
}

export function useGetCountryName(): (isoCode: string) => Promise<string | undefined> {
  const { addressService } = useAddressService();
  return useCallback(
    async (isoCode: string) => {
      if (countries == null) countries = await addressService.autoCompleteCountries({});
      return countries.find((value) => value.IsoCode === isoCode)?.Name;
    },
    [addressService]
  );
}
