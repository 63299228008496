import * as React from 'react';
import { FC, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

interface TabPanelProps {
  initialValue: string;
  values: string[];
  setTabValue: React.Dispatch<React.SetStateAction<string>>;
}

const TabPanel: FC<TabPanelProps> = ({ initialValue, values, setTabValue }) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs">
        {values.map((tabValue) => {
          return <Tab value={tabValue} label={tabValue} key={tabValue} />;
        })}
      </Tabs>
    </Box>
  );
};

export default TabPanel;
