import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Identity, FullIdentity } from '../types/identity';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { IPagedResult } from '../types/search';
import { IActivity } from '../types/activity';
import { BrandClient } from '../types/brands';
import { identityService } from '../services/identityService';

const useIdentity = (identityId: Identity['id']) => {
  const handleError = useErrorHandler();
  const clients = useSelector((state: RootState) => state.clients);

  const [identity, setIdentity] = useState<FullIdentity | null>(null);
  const [latestBrandClient, setLatestBrandClient] = useState<BrandClient | null>(null);
  const [loading, setLoading] = useState(false);

  const getIdentity = async () => {
    const getIdentityRequest = async () => {
      await identityService.getIdentity(identityId).then((identity) => {
        setIdentity(identity);
      });
    };
    await handleRequest(getIdentityRequest);
  };

  const patchIdentity = async (identity: Partial<Identity> | null) => {
    const patchIdentityRequest = async () => {
      if (identity) {
        await identityService
          .patchIdentity(identityId, identity)
          .then(() => getIdentity(), handleError);
      }
    };
    await handleRequest(patchIdentityRequest);
  };

  const deleteIdentity = async () => {
    const deleteIdentityRequest = async () =>
      await identityService.deleteIdentity(identityId).then(() => setIdentity(null));
    await handleRequest(deleteIdentityRequest);
  };

  const getLatestBrandClientForIdentity = async (
    collection: IPagedResult<IActivity[]> | undefined
  ) => {
    const getLatestBrandClientFunction = async () =>
      collection?.data.some((activity) => {
        const brandClient = clients.find((client) => client.clientId === activity.clientId) || null;
        setLatestBrandClient(brandClient);
        return brandClient;
      });
    await handleRequest(getLatestBrandClientFunction);
  };

  const invalidateAccessTokens = async (
    successHook?: () => void,
    errorHook?: (e?: Error) => void
  ) => {
    await identityService
      .invalidateAccessTokensForIdentity(identityId)
      .then(successHook, errorHook);
  };

  const handleRequest = async (customFunction: () => Promise<unknown>) => {
    setLoading(true);
    await customFunction().finally(() => setLoading(false));
  };

  return {
    identity,
    getIdentity,
    patchIdentity,
    deleteIdentity,
    getLatestBrandClientForIdentity,
    invalidateAccessTokens,
    latestBrandClientForIdentity: latestBrandClient,
    identityLoading: loading,
  };
};

export type IdentityHook = typeof useIdentity;
export type IdentityHookResult = ReturnType<IdentityHook>;
export default useIdentity;
