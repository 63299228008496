import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import React, { FC, useMemo } from 'react';
import useBrandGroupEntitlements from '../../../hooks/useBrandGroupEntitlements';
import { ITypeAhead } from '../../../types/type-ahead';
import CustomAutoComplete from '../../General/CustomAutoComplete';
import CustomDatePicker from '../../General/CustomDatePicker';
import { Switch } from '../../Switch';
import { IEntitlement } from '../../../types/subscribed-access';

export type EntitlementError = {
  brandCode?: string;
  code?: string;
  validFrom?: string;
  validTo?: string;
};

type EntitlementValues = Partial<IEntitlement>;

type EntitlementFieldProps = {
  formikId: string;
  error?: EntitlementError;
  values: EntitlementValues;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  onRemove?: () => void;
};

export const EntitlementField: FC<EntitlementFieldProps> = ({
  formikId,
  values,
  error,
  setFieldValue,
  onRemove,
}) => {
  const { getEntitlementOptionList, getBrandOptionList, getHumanReadableBrandFromBrandCode } =
    useBrandGroupEntitlements();

  const brandCodeField: ITypeAhead | null = useMemo(() => {
    if (!values.brandCode) {
      return null;
    }
    return {
      label: getHumanReadableBrandFromBrandCode(values.brandCode),
      key: values.brandCode,
    };
  }, [getHumanReadableBrandFromBrandCode, values.brandCode]);

  const codeField: ITypeAhead | null = useMemo(() => {
    if (!values.code) {
      return null;
    }
    return {
      label: values.code,
      key: values.code,
    };
  }, [values.code]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md>
        <CustomAutoComplete
          label="Brand"
          name={`${formikId}.brandCode`}
          error={error?.brandCode !== undefined}
          helperText={error?.brandCode}
          options={getBrandOptionList()}
          setFieldValue={setFieldValue}
          fieldValue={brandCodeField}
          style={{ minWidth: '205px' }}
        />
      </Grid>
      <Grid item xs={12} md>
        <CustomAutoComplete
          label="Code"
          name={`${formikId}.code`}
          error={error?.code !== undefined}
          helperText={error?.code}
          options={getEntitlementOptionList(values.brandCode)}
          setFieldValue={setFieldValue}
          fieldValue={codeField}
          style={{ minWidth: '205px' }}
        />
      </Grid>
      <Grid item xs={12} md>
        <CustomDatePicker
          label={'Valid from'}
          time={{ hour: 0, minute: 0, second: 0 }}
          value={values.validFrom}
          error={error?.validFrom}
          onChange={(date) => setFieldValue(`${formikId}.validFrom`, date?.toISOString())}
        />
      </Grid>
      <Grid item xs={12} md>
        <CustomDatePicker
          label={'Valid to'}
          time={{ hour: 23, minute: 59, second: 59 }}
          value={values.validTo}
          error={error?.validTo}
          onChange={(date) => setFieldValue(`${formikId}.validTo`, date?.toISOString())}
        />
      </Grid>
      <Grid item xs={12} md>
        <Switch
          label="Active"
          checked={values.isActive ?? true}
          onChange={(value) => setFieldValue(`${formikId}.isActive`, value)}
        />
      </Grid>
      <Grid item xs={2} md={1}>
        {onRemove && (
          <IconButton aria-label="delete" onClick={onRemove}>
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
