import type { DeepKeys } from './util-types';

export const isEmpty = <T extends Record<string, unknown>>(value: T): boolean =>
  Object.keys(value).length === 0;

export const filterObject = (
  obj: Record<string, unknown>,
  predicate: (key: string, value: unknown) => boolean
): Record<string, unknown> =>
  Object.fromEntries(Object.entries(obj).filter(([key, value]) => predicate(key, value)));

export const deepFilterObject = (
  obj: Record<string, unknown>,
  predicate: (key: string, value: unknown) => boolean
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      typeof value === 'object' && value !== null
        ? [key, deepFilterObject(value as Record<string, unknown>, predicate)]
        : [key, predicate(key, value) ? value : undefined]
    )
  );

export const mapObjectValues = <T, U>(
  obj: Record<string, T>,
  mapper: (key: string, value: T) => U
): Record<string, U> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapper(key, value)]));

export const deepMapObjectValues = <T extends Record<string, unknown>>(
  obj: T,
  mapper: (key: DeepKeys<T>, value: unknown) => unknown
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      typeof value === 'object' && value !== null
        ? [key, deepMapObjectValues(value as Record<string, unknown>, mapper as any)]
        : [key, mapper(key as DeepKeys<T>, value)]
    )
  );
